@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.wfnx {
  .wfnx-collapse-card {
    background: $white;
    border-radius: 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

    &--closed {
      .wfnx-collapse-card {
        &__header {
          border-bottom: none;
        }

        &__body {
          padding: 0
        }
      }
    }

    &__body {
      padding: 20px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      height: 60px;
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      margin: 0 20px;
      align-items: center;
      border-bottom: 1px solid rgba(51, 51, 51, 0.4);

      &--collapsible {
        color: $accent-0-dark;
      }
    }

    &__title-container {
      display: flex;
      align-items: center;
    }

    &__title-icon {
      margin-right: 15px;
    }

    &__collapse-icon {
      font-size: $font-size-larger;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

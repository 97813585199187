@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf {
  .vdl-button--link.mdf-chat-button,
  .vdl-button--link.mdf-chat-button:focus,
  .vdl-button--link.mdf-chat-button:hover {
    color: $black;
    font-size: $font-size-base;
    text-decoration: none;
    text-transform: none;

    .vdl-button__container {
      display: flex;
      align-items: flex-end;
    }

    .vdl-button__icon {
      color: $accent-1-dark;
      padding-left: 5px;
    }
  }
}

.mdf-chat-button-tooltip {
  width: 260px;
}
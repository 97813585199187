@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.address-row {
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  margin-bottom: 0;
  display: flex;

  .address-item {
    flex-basis: 220px;
    margin-left: 10px;
  }
}

.address-item {
  .autocomplete-address-container {
    .mdf-validated-field {
      flex: 1 1 auto;
    }
  }
}

.address-item-state {
  display: inline-flex;
  width: 50%;
}

.address-item-postalcode {
  display: inline-flex;
  width: 47%;
  margin-left: 3%;
}

.address-item-textbox {
  width: 100%;
}

.address-item-expand-textbox {
  width: calc(100% - 20px);
}

.autocomplete-address {
  &-container {
    display: flex;
    align-items: flex-end;
  }

  &-expand {
    color: $link-color;
    font-size: $font-size-small;
    cursor: pointer;
    position: relative;
    margin-left: 5px;
    margin-bottom: 15px;
    padding: 0px 1px 0px 1px;

    &:focus {
      border: 2px solid;
      border-radius: 50%;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

/* COLOR CLASSES */

.mdf {
  div, span, sdf-box {
    &.font-color-base {
      color: $font-color-base;
    }

    &.link-color {
      color: $link-color;
      fill: $link-color;
    }

    &.link-color-bg {
      background-color: $link-color;
      fill: $link-color;
    }

    // GRAYS (N1 - N4) from lightest to darkest
    &.neutral-lightest {
      color: $neutral-lightest;
      fill: $neutral-lightest;
      stroke: $neutral-lightest;
    }

    &.neutral-lighter {
      color: $neutral-lighter;
      fill: $neutral-lighter;
      stroke: $neutral-lighter;
    }

    &.neutral-light {
      color: $neutral-light;
      fill: $neutral-light;
      stroke: $neutral-light;
    }

    &.neutral {
      color: $neutral;
      fill: $neutral;
      stroke: $neutral;
    }

    &.neutral-mid {
      color: $neutral-mid;
      fill: $neutral-mid;
      stroke: $neutral-mid;
    }

    &.neutral-dark {
      color: $neutral-dark;
      fill: $neutral-dark;
      stroke: $neutral-dark;
    }

    &.neutral-darker {
      color: $neutral-darker;
      fill: $neutral-darker;
      stroke: $neutral-darker;
    }

    &.neutral-disabled {
      color: $neutral-disabled;
      fill: $neutral-disabled;
    }

    &.neutral-lightest-bg {
      background-color: $neutral-lightest;
      fill: $neutral-lightest;
    }

    &.neutral-lighter-bg {
      background-color: $neutral-lighter;
      fill: $neutral-lighter;
    }

    &.neutral-light-bg {
      background-color: $neutral-light;
      fill: $neutral-light;
    }

    &.neutral-bg {
      background-color: $neutral;
      fill: $neutral;
    }

    &.neutral-mid-bg {
      background-color: $neutral-mid;
      fill: $neutral-mid;
    }

    &.neutral-dark-bg {
      background-color: $neutral-dark;
      fill: $neutral-dark;
    }

    &.neutral-darker-bg {
      background-color: $neutral-darker;
      fill: $neutral-darker;
    }

    /* BLUES (B1 - B4) - from lightest to darkest */
    &.accent-0-lightest {
      color: $accent-0-lightest;
      fill: $accent-0-lightest;
      stroke: $accent-0-lightest;
    }

    &.accent-0-lighter {
      color: $accent-0-lighter;
      fill: $accent-0-lighter;
      stroke: $accent-0-lighter;
    }

    &.accent-0-light {
      color: $accent-0-light;
      fill: $accent-0-light;
      stroke: $accent-0-light;
    }

    &.accent-0 {
      color: $accent-0;
      fill: $accent-0;
      stroke: $accent-0;
    }

    &.accent-0-dark {
      color: $accent-0-dark;
      fill: $accent-0-dark;
      stroke: $accent-0-dark;
    }

    &.accent-0-darker {
      color: $accent-0-darker;
      fill: $accent-0-darker;
      stroke: $accent-0-darker;
    }

    &.accent-0-lightest-bg {
      background-color: $accent-0-lightest;
      fill: $accent-0-lightest;
    }

    &.accent-0-lighter-bg {
      background-color: $accent-0-lighter;
      fill: $accent-0-lighter;
    }

    &.accent-0-light-bg {
      background-color: $accent-0-light;
      fill: $accent-0-light;
    }

    &.accent-0-bg {
      background-color: $accent-0;
      fill: $accent-0;
    }

    &.accent-0-dark-bg {
      background-color: $accent-0-dark;
      fill: $accent-0-dark;
    }

    &.accent-0-darker-bg {
      background-color: $accent-0-darker;
      fill: $accent-0-darker;
    }

    /* GREENS (G1 - G4) - from lightest to darkest */
    &.accent-1-lightest {
      color: $accent-1-lightest;
      fill: $accent-1-lightest;
      stroke: $accent-1-lightest;
    }

    &.accent-1-lighter {
      color: $accent-1-lighter;
      fill: $accent-1-lighter;
      stroke: $accent-1-lighter;
    }

    &.accent-1-light {
      color: $accent-1-light;
      fill: $accent-1-light;
      stroke: $accent-1-light;
    }

    &.accent-1 {
      color: $accent-1;
      fill: $accent-1;
      stroke: $accent-1;
    }

    &.accent-1-dark {
      color: $accent-1-dark;
      fill: $accent-1-dark;
      stroke: $accent-1-dark;
    }

    &.accent-1-darker {
      color: $accent-1-darker;
      fill: $accent-1-darker;
      stroke: $accent-1-darker;
    }

    &.accent-1-lightest-bg {
      background-color: $accent-1-lightest;
      fill: $accent-1-lightest;
    }

    &.accent-1-lighter-bg {
      background-color: $accent-1-lighter;
      fill: $accent-1-lighter;
    }

    &.accent-1-light-bg {
      background-color: $accent-1-light;
      fill: $accent-1-light;
    }

    &.accent-1-bg {
      background-color: $accent-1;
      fill: $accent-1;
    }

    &.accent-1-dark-bg {
      background-color: $accent-1-dark;
      fill: $accent-1-dark;
    }

    &.accent-1-darker-bg {
      background-color: $accent-1-darker;
      fill: $accent-1-darker;
    }

    /* ORANGES (O1 - O4) - from lightest to darkest */
    &.accent-2-light {
      color: $accent-2-light;
      fill: $accent-2-light;
      stroke: $accent-2-light;
    }

    &.accent-2 {
      color: $accent-2;
      fill: $accent-2;
      stroke: $accent-2;
    }

    &.accent-2-dark {
      color: $accent-2-dark;
      fill: $accent-2-dark;
      stroke: $accent-2-dark;
    }

    &.accent-2-darker {
      color: $accent-2-darker;
      fill: $accent-2-darker;
      stroke: $accent-2-darker;
    }

    &.accent-2-light-bg {
      background-color: $accent-2-light;
      fill: $accent-2-light;
    }

    &.accent-2-bg {
      background-color: $accent-2;
      fill: $accent-2;
    }

    &.accent-2-dark-bg {
      background-color: $accent-2-dark;
      fill: $accent-2-dark;
    }

    &.accent-2-darker-bg {
      background-color: $accent-2-darker;
      fill: $accent-2-darker;
    }

    /* MAGENTAS (M1 - M4) - from lightest to darkest */
    &.accent-3-light {
      color: $accent-3-light;
      fill: $accent-3-light;
      stroke: $accent-3-light;
    }

    &.accent-3 {
      color: $accent-3;
      fill: $accent-3;
      stroke: $accent-3;
    }

    &.accent-3-dark {
      color: $accent-3-dark;
      fill: $accent-3-dark;
      stroke: $accent-3-dark;
    }

    &.accent-3-darker {
      color: $accent-3-darker;
      fill: $accent-3-darker;
      stroke: $accent-3-darker;
    }

    &.accent-3-light-bg {
      background-color: $accent-3-light;
      fill: $accent-3-light;
    }

    &.accent-3-bg {
      background-color: $accent-3;
      fill: $accent-3;
    }

    &.accent-3-dark-bg {
      background-color: $accent-3-dark;
      fill: $accent-3-dark;
    }

    &.accent-3-darker-bg {
      background-color: $accent-3-darker;
      fill: $accent-3-darker;
    }

    /* PURPLES (P1 - P4) - from lightest to darkest */
    &.accent-4-light {
      color: $accent-4-light;
      fill: $accent-4-light;
      stroke: $accent-4-light;
    }

    &.accent-4 {
      color: $accent-4;
      fill: $accent-4;
      stroke: $accent-4;
    }

    &.accent-4-dark {
      color: $accent-4-dark;
      fill: $accent-4-dark;
      stroke: $accent-4-dark;
    }

    &.accent-4-darker {
      color: $accent-4-darker;
      fill: $accent-4-darker;
      stroke: $accent-4-darker;
    }

    &.accent-4-light-bg {
      background-color: $accent-4-light;
      fill: $accent-4-light;
    }

    &.accent-4-bg {
      background-color: $accent-4;
      fill: $accent-4;
    }

    &.accent-4-dark-bg {
      background-color: $accent-4-dark;
      fill: $accent-4-dark;
    }

    &.accent-4-darker-bg {
      background-color: $accent-4-darker;
      fill: $accent-4-darker;
    }

    /* YELLOWS (Y1 - Y2) - from lightest to darkest */
    &.accent-5-lightest {
      color: $accent-5-lightest;
      fill: $accent-5-lightest;
      stroke: $accent-5-lightest;
    }

    &.accent-5-lighter {
      color: $accent-5-lighter;
      fill: $accent-5-lighter;
      stroke: $accent-5-lighter;
    }

    &.accent-5-light {
      color: $accent-5-light;
      fill: $accent-5-light;
      stroke: $accent-5-light;
    }

    &.accent-5 {
      color: $accent-5;
      fill: $accent-5;
      stroke: $accent-5;
    }

    &.accent-5-dark {
      color: $accent-5-dark;
      fill: $accent-5-dark;
      stroke: $accent-5-dark;
    }

    &.accent-5-darker {
      color: $accent-5-darker;
      fill: $accent-5-darker;
      stroke: $accent-5-darker;
    }

    &.accent-5-lightest-bg {
      background-color: $accent-5-lightest;
      fill: $accent-5-lightest;
    }

    &.accent-5-lighter-bg {
      background-color: $accent-5-lighter;
      fill: $accent-5-lighter;
    }

    &.accent-5-light-bg {
      background-color: $accent-5-light;
      fill: $accent-5-light;
    }

    &.accent-5-bg {
      background-color: $accent-5;
      fill: $accent-5;
    }

    &.accent-5-dark-bg {
      background-color: $accent-5-dark;
      fill: $accent-5-dark;
    }

    &.accent-5-darker-bg {
      background-color: $accent-5-darker;
      fill: $accent-5-darker;
    }

    /* REDS - ADP RED and BADGE COLOR RED */
    /* Deprecated - use accent-6-lighter instead */
    &.accent-6-lightest {
      color: $accent-6-lightest;
      fill: $accent-6-lightest;
      stroke: $accent-6-lightest;
    }

    &.accent-6-lighter {
      color: $accent-6-lighter;
      fill: $accent-6-lighter;
      stroke: $accent-6-lighter;
    }

    &.accent-6-light {
      color: $accent-6-light;
      fill: $accent-6-light;
      stroke: $accent-6-light;
    }

    &.accent-6 {
      color: $accent-6;
      fill: $accent-6;
      stroke: $accent-6;
    }

    &.accent-6-dark {
      color: $accent-6-dark;
      fill: $accent-6-dark;
      stroke: $accent-6-dark;
    }

    /* Deprecated - use accent-6-lighter-bg instead */
    &.accent-6-lightest-bg {
      background-color: $accent-6-lightest;
      fill: $accent-6-lightest;
    }

    &.accent-6-lighter-bg {
      background-color: $accent-6-lighter;
      fill: $accent-6-lighter;
    }

    &.accent-6-light-bg {
      background-color: $accent-6-light;
      fill: $accent-6-light;
    }

    &.accent-6-bg {
      background-color: $accent-6;
      fill: $accent-6;
    }

    &.accent-6-dark-bg {
      background-color: $accent-6-dark;
      fill: $accent-6-dark;
    }

    /* AQUA (A1 - A4) - from lightest to darkest */
    &.accent-7-light {
      color: $accent-7-light;
      fill: $accent-7-light;
      stroke: $accent-7-light;
    }

    &.accent-7 {
      color: $accent-7;
      fill: $accent-7;
      stroke: $accent-7;
    }

    &.accent-7-dark {
      color: $accent-7-dark;
      fill: $accent-7-dark;
      stroke: $accent-7-dark;
    }

    &.accent-7-darker {
      color: $accent-7-darker;
      fill: $accent-7-darker;
      stroke: $accent-7-darker;
    }

    &.accent-7-light-bg {
      background-color: $accent-7-light;
      fill: $accent-7-light;
    }

    &.accent-7-bg {
      background-color: $accent-7;
      fill: $accent-7;
    }

    &.accent-7-dark-bg {
      background-color: $accent-7-dark;
      fill: $accent-7-dark;
    }

    &.accent-7-darker-bg {
      background-color: $accent-7-darker;
      fill: $accent-7-darker;
    }

    &.white {
      color: $white;
      fill: $white;
      stroke: $white
    }

    &.white-bg {
      background-color: $white;
      fill: $white;
    }

    &.black {
      color: $black;
      fill: $black;
      stroke: $black;
    }

    &.black-bg {
      background-color: $black;
      fill: $black;
    }

    &.simple {
      &-font-color-base {
        color: $font-color-base;
      }

      &-link-color {
        color: $link-color;
      }

      &-link-color-bg {
        background-color: $link-color;
      }

      // GRAYS (N1 - N4) from lightest to darkest
      &-neutral-lightest {
        color: $neutral-lightest;
      }

      &-neutral-lighter {
        color: $neutral-lighter;
      }

      &-neutral-light {
        color: $neutral-light;
      }

      &-neutral {
        color: $neutral;
        stroke: none;
      }

      &-neutral-mid {
        color: $neutral-mid;
      }

      &-neutral-dark {
        color: $neutral-dark;
      }

      &-neutral-darker {
        color: $neutral-darker;
      }

      &-neutral-disabled {
        color: $neutral-disabled;
      }

      &-neutral-lightest-bg {
        background-color: $neutral-lightest;
      }

      &-neutral-lighter-bg {
        background-color: $neutral-lighter;
      }

      &-neutral-light-bg {
        background-color: $neutral-light;
      }

      &-neutral-bg {
        background-color: $neutral;
      }

      &-neutral-mid-bg {
        background-color: $neutral-mid;
      }

      &-neutral-dark-bg {
        background-color: $neutral-dark;
      }

      &-neutral-darker-bg {
        background-color: $neutral-darker;
      }

      /* BLUES (B1 - B4) - from lightest to darkest */
      &-accent-0-lightest {
        color: $accent-0-lightest;
      }

      &-accent-0-lighter {
        color: $accent-0-lighter;
      }

      &-accent-0-light {
        color: $accent-0-light;
      }

      &-accent-0 {
        color: $accent-0;
      }

      &-accent-0-dark {
        color: $accent-0-dark;
      }

      &-accent-0-darker {
        color: $accent-0-darker;
      }

      &-accent-0-lightest-bg {
        background-color: $accent-0-lightest;
      }

      &-accent-0-lighter-bg {
        background-color: $accent-0-lighter;
      }

      &-accent-0-light-bg {
        background-color: $accent-0-light;
      }

      &-accent-0-bg {
        background-color: $accent-0;
      }

      &-accent-0-dark-bg {
        background-color: $accent-0-dark;
      }

      &-accent-0-darker-bg {
        background-color: $accent-0-darker;
      }

      /* GREENS (G1 - G4) - from lightest to darkest */
      &-accent-1-lightest {
        color: $accent-1-lightest;
      }

      &-accent-1-lighter {
        color: $accent-1-lighter;
      }

      &-accent-1-light {
        color: $accent-1-light;
      }

      &-accent-1 {
        color: $accent-1;
      }

      &-accent-1-dark {
        color: $accent-1-dark;
      }

      &-accent-1-darker {
        color: $accent-1-darker;
      }

      &-accent-1-lightest-bg {
        background-color: $accent-1-lightest;
      }

      &-accent-1-lighter-bg {
        background-color: $accent-1-lighter;
      }

      &-accent-1-light-bg {
        background-color: $accent-1-light;
      }

      &-accent-1-bg {
        background-color: $accent-1;
      }

      &-accent-1-dark-bg {
        background-color: $accent-1-dark;
      }

      &-accent-1-darker-bg {
        background-color: $accent-1-darker;
      }

      /* ORANGES (O1 - O4) - from lightest to darkest */
      &-accent-2-light {
        color: $accent-2-light;
      }

      &-accent-2 {
        color: $accent-2;
      }

      &-accent-2-dark {
        color: $accent-2-dark;
      }

      &-accent-2-darker {
        color: $accent-2-darker;
      }

      &-accent-2-light-bg {
        background-color: $accent-2-light;
      }

      &-accent-2-bg {
        background-color: $accent-2;
      }

      &-accent-2-dark-bg {
        background-color: $accent-2-dark;
      }

      &-accent-2-darker-bg {
        background-color: $accent-2-darker;
      }

      /* MAGENTAS (M1 - M4) - from lightest to darkest */
      &-accent-3-light {
        color: $accent-3-light;
      }

      &-accent-3 {
        color: $accent-3;
      }

      &-accent-3-dark {
        color: $accent-3-dark;
      }

      &-accent-3-darker {
        color: $accent-3-darker;
      }

      &-accent-3-light-bg {
        background-color: $accent-3-light;
      }

      &-accent-3-bg {
        background-color: $accent-3;
      }

      &-accent-3-dark-bg {
        background-color: $accent-3-dark;
      }

      &-accent-3-darker-bg {
        background-color: $accent-3-darker;
      }

      /* PURPLES (P1 - P4) - from lightest to darkest */
      &-accent-4-light {
        color: $accent-4-light;
      }

      &-accent-4 {
        color: $accent-4;
      }

      &-accent-4-dark {
        color: $accent-4-dark;
      }

      &-accent-4-darker {
        color: $accent-4-darker;
      }

      &-accent-4-light-bg {
        background-color: $accent-4-light;
      }

      &-accent-4-bg {
        background-color: $accent-4;
      }

      &-accent-4-dark-bg {
        background-color: $accent-4-dark;
      }

      &-accent-4-darker-bg {
        background-color: $accent-4-darker;
      }

      /* YELLOWS (Y1 - Y2) - from lightest to darkest */
      &-accent-5-lightest {
        color: $accent-5-lightest;
      }

      &-accent-5-lighter {
        color: $accent-5-lighter;
      }

      &-accent-5-light {
        color: $accent-5-light;
      }

      &-accent-5 {
        color: $accent-5;
      }

      &-accent-5-dark {
        color: $accent-5-dark;
      }

      &-accent-5-darker {
        color: $accent-5-darker;
      }

      &-accent-5-lightest-bg {
        background-color: $accent-5-lightest;
      }

      &-accent-5-lighter-bg {
        background-color: $accent-5-lighter;
      }

      &-accent-5-light-bg {
        background-color: $accent-5-light;
      }

      &-accent-5-bg {
        background-color: $accent-5;
      }

      &-accent-5-dark-bg {
        background-color: $accent-5-dark;
      }

      &-accent-5-darker-bg {
        background-color: $accent-5-darker;
      }

      /* REDS - ADP RED and BADGE COLOR RED */
      /* Deprecated - use accent-6-lighter instead */
      &-accent-6-lightest {
        color: $accent-6-lightest;
      }

      &-accent-6-lighter {
        color: $accent-6-lighter;
      }

      &-accent-6-light {
        color: $accent-6-light;
      }

      &-accent-6 {
        color: $accent-6;
      }

      &-accent-6-dark {
        color: $accent-6-dark;
      }

      /* Deprecated - use accent-6-lighter-bg instead */
      &-accent-6-lightest-bg {
        background-color: $accent-6-lightest;
      }

      &-accent-6-lighter-bg {
        background-color: $accent-6-lighter;
      }

      &-accent-6-light-bg {
        background-color: $accent-6-light;
      }

      &-accent-6-bg {
        background-color: $accent-6;
      }

      &-accent-6-dark-bg {
        background-color: $accent-6-dark;
      }

      /* AQUA (A1 - A4) - from lightest to darkest */
      &-accent-7-light {
        color: $accent-7-light;
      }

      &-accent-7 {
        color: $accent-7;
      }

      &-accent-7-dark {
        color: $accent-7-dark;
      }

      &-accent-7-darker {
        color: $accent-7-darker;
      }

      &-accent-7-light-bg {
        background-color: $accent-7-light;
      }

      &-accent-7-bg {
        background-color: $accent-7;
      }

      &-accent-7-dark-bg {
        background-color: $accent-7-dark;
      }

      &-accent-7-darker-bg {
        background-color: $accent-7-darker;
      }

      &-white {
        color: $white;
      }

      &-white-bg {
        background-color: $white;
      }

      &-black {
        color: $black;
      }

      &-black-bg {
        background-color: $black;
      }
    }
  }
}

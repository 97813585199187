@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

@mixin input-disabled {
  border: 1px solid transparent;
  color: inherit;
  padding: 0;
  background-color: transparent;
  cursor: default;
}

@mixin wfnx-input-box {
  border: 1px solid transparent;
  border-bottom: 1px solid $accent-0;
  background-color: transparent;
  font-weight: $font-weight-light;
  font-size: $font-size-larger;
  transition: border-color 250ms linear;
  height: 40px;

  &:hover {
    border: 1px solid $accent-0;
    transition: initial;
  }

  &:focus {
    border: 1px solid $accent-0;
    box-shadow: none;
  }

  &[disabled] {
    @include input-disabled;
  }
}

@mixin wfnx-toggle-styles($checked) {
  border: 1px solid $accent-0;
  height: 24px;
  width: 24px;

  &:hover {
    box-shadow: 0 0 15px 0 rgba(26, 128, 153, 1);
  }

  @if $checked {
    background-color: $accent-1-light;
    border-color: $accent-1;

    &:hover {
      box-shadow: inset 0 0 15px 0 rgba(81, 220, 81, 1);
    }
  }
}

@mixin wfnx-toggle-disabled {
  border: 1px solid $neutral;
  background-color: $neutral;
  color: $neutral-dark;

  &:hover {
    box-shadow: none;
  }
}

@mixin flex-center-horizontal($align) {
  display: flex;
  justify-content: center;
  align-items: $align;
}

@mixin flex-start-horizontal($align) {
  display: flex;
  justify-content: flex-start;
  align-items: $align;
}

@mixin flex-start-vertical($align) {
  display: flex;
  justify-content: flex-start;
  align-items: $align;
  flex-direction: column;
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

// These are style fixes for styles that Revolution overrides.

.revolution .mdf {
  font-weight: $font-weight-base;
  -webkit-font-smoothing: antialiased;

  // Remove the padding added by Revolution
  h2, h3, h4, h5, h6 {
    padding: 0;
  }

  .h2, .h3, .h4, .h5, .h6 {
    padding: 0;
    margin: 0;
  }

  h1,
  .h1 {
    font-size: $font-size-h1;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
    margin: 15px 0;
    padding: 0 0 5px;
  }

  h2,
  .h2 {
    font-size: $font-size-h2;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
    margin: 15px 0;
  }

  h3,
  .h3 {
    font-size: $font-size-h3;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
    margin: 10px 0;
  }

  h4,
  .h4 {
    font-size: $font-size-h4;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
    margin: 10px 0;
  }

  h5,
  .h5 {
    font-size: $font-size-h5;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
    margin: 10px 0;
  }

  input[type='text'],
  input[type='password'] {
    font-family: $font-family-base;
    font-size: $font-size-base;
    padding: 5px;
  }

  label {
    display: block;
    border: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    color: $neutral-darker;
    margin-top: 0;
    line-height: $line-height-base;
    margin-bottom: 5px;
  }

  label.vdl-checkbox {
    display: block;
    text-align: left;
    font-size: $font-size-base;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .vdl-checkbox label,
  .vdl-radio label {
    color: $link-color;
    display: inline-block;
    line-height: 22px;
  }

  label.mdf-label-error {
    color: $accent-6;
  }

  label.vdl-left-nav__item__label {
    padding: 5px 13px;
    display: block;
    border-radius: 20px;
    // standard VDL settings
    border: 0;
    box-sizing: border-box;
    color: $black;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    line-height: $line-height-base;
    margin: 0;
    text-align: start;
    white-space: normal;
    vertical-align: baseline;
  }

  .vdl-left-nav__item.vdl-left-nav__item--selected > label.vdl-left-nav__item__label {
    color: $white;
  }

  .mdf-leftNav .vdl-left-nav__item.vdl-left-nav__item--selected > label.vdl-left-nav__item__label {
    color: $accent-0-darker;
  }

  .mdf-leftNav label.vdl-left-nav__item__label {
    margin-bottom: 5px;
  }

  .vdl-left-nav--tree .vdl-left-nav__item__label {
    padding-left: 25px;
  }

  .mdf-validated-field > label {
    margin-top: 0;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }

  .vdl-accordion-panel__title > a {
    text-decoration: none !important;
  }

  .vdl-date-time-picker > input[type='text'] {
    padding: 10px;
  }

  label.vdl-radio {
    display: flex;
    text-align: left;
    font-size: $font-size-base;
    line-height: $line-height-base;
    margin-top: 0;
    margin-bottom: 5px;
  }

  label.vdl-toggle-switch {
    margin-top: 0;
    font-size: $font-size-base;
    color: $black;
  }

  textarea.vdl-textarea {
    border: var(--input-border);
    padding: 10px;
    width: 100%;
    margin-bottom: 5px;
  }

  .wfnx-alternateFieldStyle textarea.vdl-textarea {
    border: 1px solid $accent-0;
  }

  .rrui__input select {
    position: absolute;
  }
}

// Make sure the appContainer has a higher z-index than
// the footer so that content inside the appContainer (like
// the wizard's snackbar) will not be obscured by the footer
.revolution .revitAppContainer {
  z-index: 1 !important;
}

.revolution .revitFooter {
  position: relative;
  z-index: 0 !important;

  .revitFooterContainer {
    z-index: 0 !important;
  }
}

.revolution .revitPortlet .dijitTitlePaneContentOuter,
.revolution .revitPortlet.mdf .dijitTitlePaneContentOuter {
  position: static !important;
}

.dijitReset .mdf {
  line-height: $line-height-base;
}

.mdf .dijitReset {
  line-height: $line-height-base;
}

.revolution .fr-popup label {
  margin-top: 0;
}

.mdfView {
  padding: 10px;
  background-color: var(--content-bg-color);

  .mdfNumber, .mdfText, .mdfDate {
    margin-right: 1em;
  }
}

.mdfView .mdfCheckBox {
  margin-right: 1em;
  width: auto;
}

.mdfBox {
  padding: 10px;
}

.mdfInlineBlock {
  display: inline-block;
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

$border: 2px solid $neutral-light;

.mdf {
  .mdf-card {
    min-width: 200px;
    background: $neutral-light;
    border: $border;
    margin-bottom: 0px;

    &:hover {
      border: 2px solid $accent-0;
    }

    &.mdf-card__wrapper__disabled {
      min-height: 200px;
      background: $neutral-light;
      border: $border;

      &:hover {
        border: $border;
      }

      .vdl-card__wrapper {
        background: $neutral-light;
      }
    }

    &.mdf-card__disabled__selected {
      border: 2px solid $neutral;
      background: $neutral-light;
      border: $border;

       &:hover {
        border: $border;
      }
    }

    .mdf-card__wrapper {
      min-height: 200px;
    }

    .vdl-card__wrapper {
      border-width: 0;
      position: relative;

      .check {
        position: absolute;
        right: 2px;
        color: $white;
        top: 1px;
      }

      .check-waypoint {
        position: absolute;
        right: -1px;
        color: $white;
        top: -2px;
      }

      .check__disabled__selected {
        color: $neutral-dark
      }

      .mdf-card-content {
        margin: 5px;
      }
    }

    .triangle {
      width: 0;
      border-top: 30px solid $neutral-light;
      border-left: 30px solid transparent;
      margin-left: auto;

      &:hover {
        border-top: 30px solid $accent-0;
      }
    }

    .triangle__disabled {
      border-top: 30px solid $neutral-lightest;

      &:hover {
        border-top: 30px solid $neutral-lightest;
      }
    }

    .triangle__selected {
      border-top: 30px solid $accent-0-dark;
    }

    .triangle__disabled__selected {
      border-top: 30px solid $neutral;

      &:hover {
      border-top: 30px solid $neutral;
      }
    }
  }

  .mdf-card__selected {
    border: 2px solid $accent-0-dark;
  }

  .mdf-card-atom {
    // Accordions inside a card atom should use the card's background
    .vdl-accordion {
      .vdl-accordion-panel {
        &.vdl-accordion-panel--expanded {
          background-color: inherit;

          .vdl-accordion-collapse--entered {
            background-color: inherit;
          }
        }
      }
    }
  }
}

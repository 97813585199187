@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.vdl-validation-error > .vdl-dropdown-list__input-container,
.vdl-validation-error > .MDFSelectBox__control,
textarea.vdl-validation-error,
.vdl-textbox.vdl-validation-error,
.vdl-validation-error > input {
  border: var(--input-border-invalid) !important;
  color: $color-status-error;

  &:-ms-input-placeholder {
    color: $neutral-dark;
    font-style: italic;
  }
}

input[type=number]:invalid {
  border: var(--input-border-invalid) !important;
  color: $color-status-error !important;
}

.vdl-validation-error > .mdf-mobile-picker__input::before {
  color: $color-status-error !important;
}

.mdf-required-indicator {
   color: $accent-6;
   margin-left: 3px;
}

.mdf .vdl-popover.validate-popover.bottom .arrow {
  border-bottom-color: $color-status-error;
}

.mdf .vdl-popover.validate-popover {
  background: $accent-6-lightest;
  border: 1px solid $color-status-error;
  border-radius: 5px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, .2);
  height: auto;
  max-width: 385px;
}

.mdf .vdl-popover.validate-popover .popover-content {
  color: $color-status-error;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  text-align: center;
}

.mdf {
  .validation-check-list__popover-content {
    padding: 5px;
  }

  .validation-check-list-heading {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: $font-weight-semibold;
  }

  .validation-check-list__rules {
    margin: 0;
    padding: 0;
  }

  .validation-check-list__rule {
    list-style: none;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    margin-bottom: 3px;

    &--success {
      color: $accent-1-dark;
    }

    &--error {
      color: $color-status-error;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  .validation-check-list__rule-icon {
    display: inline-block;
    margin-right: 5px;
  }
}

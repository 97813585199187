@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf-inlineAlert {
  display: flex;

  &--banner {
    flex-direction: column;
  }

  .vdl-status-indicator__content {
    display: flex;
    align-items: center;
  }
}

// override popupDialog Header to match the current UX redlines.
.mdf-inlineAlert-dialog {
  .popup-dialog-header {
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-bottom: 1px solid $neutral-mid;
    margin-bottom: 16px;

    .popup-dialog-title {
      margin-right: 15px;
    }
  }
}
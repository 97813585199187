@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;
@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

.mdf .mdf-custom-wizard,
.mdf-custom-wizard {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 80px;

  &-header {
    color: $link-color;
    font-size: $font-size-medium;

    &-prefix {
      padding-right: 10px;
      font-weight: $font-weight-semibold;

      &::after {
        content: ":";
      }
    }

    &-progress {
      .vdl-progress-bar__bar {
        background-color: $neutral;
      }
      .vdl-progress-bar__percentage {
        height: 5px;
      }
    }
  }

  &-steps {
    margin: 0 5px;
  }

  &-step {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
  }

  &-footer {
    flex: 1;
    display: flex;

    &-navbutton {
      margin-left: 5px;
      margin-right: 5px;

      &.mdf-pull-left {
        margin-left: 0;
        margin-right: auto;

        i {
          margin-right: 3px;
        }
      }

      &.mdf-pull-right {
        margin-left: auto;
        margin-right: 0;

        i {
          margin-left: 3px;
        }
      }
    }
  }

  @media screen and (max-width: $screen-xs) {
    .mdf .mdf-segmented-wizard-header .mdf-segmented-wizard-header-item-text {
      // No varible for 10px which is needed here.
      font-size: 10px;
    }
  }

  /* Override the snackbar position to occupy full width for smaller device */
  @media (min-width: 980px) {
    .mdf-snackbar {
      position: absolute;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

$dashboard-nav-width: 85px;
$dashboard-menu-item-height: 70px;
$dashboard-nav-width-mobile: 275px;

// Side Menu
.vdl-framework-template__sidebar {
  background-color: transparent;
  position: absolute;
  height: 100%;
  overflow: hidden;

  .vdl-sidebar {
    margin-left: 0;
    transition: all 0.15s ease-in;

    .vdl-sidebar-item__container:focus {
      .vdl-sidebar-item__active-indicator {
        color: $accent-0-darker;
      }

      &:hover .vdl-sidebar-item__active-indicator {
        color: $white;
      }
    }
  }
}

// Responsive
@media (max-width: $screen-md-min) {
  .vdl-framework-template__sidebar {
    position: absolute;
    margin: 0;
    overflow: visible;

    .vdl-sidebar {
      display: none;
    }
  }

  .vdl-framework-template--menu-open {
    .vdl-framework-template__container {
      .vdl-framework-template__sidebar {
        .vdl-sidebar {
          display: inherit;
        }
      }

      .vdl-framework-template__page-container {
        margin-left: 275px;
      }
    }
  }
}

// Phones
@media (max-width: $screen-xs-min) {
  .vdl-framework-template__sidebar {
    overflow-x: hidden;
  }
}

// Greater than mobile
@media (min-width: $screen-md) {
  .vdl-framework-template__sidebar {
    width: $dashboard-nav-width;
  }
}

.left-nav-framework__container {
  overflow: hidden;
  background: var(--sidebar-content-bg);
  position: relative;
  padding-right: 0;
  padding-left: 0;
  min-width: 300px;
  min-height: 100%;
}

.left-nav-framework__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.left-nav-framework__page-container {
  transition: all 0.15s ease-in;
  width: 100%;
  min-height: 100vh;
}

.left-nav-framework__top-bar {
  background-color: $white;
  width: 100%;
  height: $dashboard-menu-item-height + 1;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.left-nav-framework__title {
  display: flex;
  align-items: center;
  margin: 0 auto 0 15px;
  padding: 0;

  .hamburger-icon {
    display: none;
  }
}

.left-nav-framework__search {
  .vdl-textbox {
    margin: 0;
  }
}

.left-nav-framework__page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

// Menu Open

.left-nav-framework--menu-open {
  .vdl-sidebar {
    display: inherit;
  }

  .left-nav-framework__page-container {
    margin-left: 275px;
  }
}

.mdf .hamburger-icon,
.hamburger-icon {
  position: relative;
  .vdl-alert-number-indicator {
    bottom: initial;
    top: 0;
    line-height: initial;
  }
}

// Responsive
@media (max-width: $screen-md-min) {
  .left-nav-framework__title {
    .hamburger-icon {
      display: block;
    }
  }
}

// Greater than mobile
@media (min-width: $screen-md) {
  .left-nav-framework__page-container {
    margin-left: $dashboard-nav-width;
    width: calc(100% - 85px);
  }
}

// iPad Width
@media (max-width: $screen-md) and (min-width: $screen-md) {
  .left-nav-framework__page-container {
    margin-left: 0;
  }

  .left-nav-framework--menu-open .left-nav-framework__page-container {
    margin-left: $dashboard-nav-width;
  }
}

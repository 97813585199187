@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf {
  .mdf-list-header {
    display: flex;
    font-size: small;
    padding: 5px 10px 10px 40px;

    &-column {
      // Per MDN: Internet Explorer 11 considers a unitless value in the flex-basis part to be syntactically invalid. Their workaround is to add a unit based value.
      flex: 1 1 0%;
    }

    &-column-name {
      font-weight: $font-weight-semibold;
      color: $neutral-darker;
      text-transform: var(--text-transform);
      font-size: var(--grid-header-font-size);
    }

    .fa {
      margin-left: 10px;
      color: $accent-0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf .newsFeedItem,
.newsFeedItem {
  padding: 5px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 15px;
  margin-left: 15px;
  border-left: 1px solid $neutral;

  &--time {
    color: $neutral-dark;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
  }

  &--avatar {
    margin-right: 5px;
    margin-left: 5px;
  }

  &--text {
    font-size: $font-size-base;
    color: $link-color;
    cursor: pointer;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.wfnx-list-card {
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  margin-bottom: 25px;
  background: $white;
  padding: 5px 0 5px 40px;
  border-right: 10px solid $accent-1-dark;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  &-column {
    flex: 1 1 0%; // Per MDN: Internet Explorer 11 considers a unitless value in the flex-basis part to be syntactically invalid. Their workaround is to add a unit based value
  }
}
@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-filter-wrapper {
  padding: 0 10px;
  display: flex;

  &.mdf-filter-wrapper--tight {
    padding: 0;
  }

  &.vertical--right {
    flex-direction: column;
    align-items: flex-start;
  }

  &.vertical--left {
    flex-direction: column;
    align-items: flex-end;

    .mdf-filtertags {
      flex-direction: row-reverse;
    }
  }

  &.horizontal--right {
    align-items: flex-start;

    .mdf-filtertags {
      // removing margin-top: 20px on filtertags to 0 because not relevant for horizontal position;
      margin-top: 0;
      align-self: center;
    }
  }

  &.horizontal--left {
    // reverse order on filtertags and container content (filtertags box + filter button)
    align-items: flex-start;
    flex-direction: row-reverse;

    .mdf-filtertags {
      flex-direction: row-reverse;
      // removing margin-top: 20px on filtertags to 0 because not relevant for horizontal position;
      margin-top: 0;
      align-self: center;
    }
  }

  &.mdf-filter {
    display: flex;
    flex-direction: column;
    margin: 0 15px 0 15px;

    .mdf-filterColumns {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;

      .mdf-filterColumn {
        display: flex;
        min-width: 240px;
        flex-direction: column;
        padding: 0 15px 0 15px;
      }

      .mdf-filterTitle {
        border-bottom: 1px solid $neutral-mid;
      }

      .mdf-filterItems {
        padding-top: 10px;

        // set a max-width only when the filteritem has selectBox
        .MDFSelectBox__control {
          max-width: 250px;
        }

        .mdf-filterColumn {
          padding: 0;
        }
      }
    }

    .mdf-controlButtons {
      align-items: center;
      border-top: 1px solid $neutral-mid;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: center;
      padding-top: 1rem;
    }
  }

  .filterButton {
    margin: 0;
  }

  .mdf-filtertags {
    display: flex;
    // margin-top positions mdf-filter-tags under the popover when it reopens so mdf-filter-tags are hidden
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    // mdf-filtertags fills the parent container, currently it does not fit the min-size of itself (width of just the filtertags combined)
    width: 100%;

    // gap properties from the Box Alignment module for Flexbox are not available yet, so use margin to create
    // gutters between lines
    > * {
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .mdf-filter-tag {
      width: initial;
      border-radius: 5px;
      font-size: $font-size-base;
      border: 1px solid $neutral;
      display: inline-flex;
      align-items: center;
      margin-left: 10px;
      background-color: $white;
      padding: 15px 10px 15px 10px;
      max-width: 300px;
      height: 30px;
      white-space: nowrap;

      .mdf-tagIcon {
        font-size: $font-size-base;
        margin-left: 10px;
      }

      span:not(.mdf-tagIcon) {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover, &:focus {
        border-color: $accent-0;
        max-width: unset;
        cursor: pointer;

        > span:not(.mdf-tagIcon) {
          color: $accent-0;
        }
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.mdf-popup-dialog-2 {
  .mdf-filter-wrapper {
    padding: 0;

    &.mdf-filter {
      margin: 0;

      .mdf-controlButtons {
        justify-content: flex-end;
        padding-top: 0;
        border-top: none;
      }
    }
  }
}

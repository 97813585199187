@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf.wfnx .vdl-date-time-picker--lg {
  width: 180px;
}

.mdf.wfnx .vdl-date-time-picker--sm,
.mdf.wfnx .vdl-date-time-picker--sm > input {
  width: 130px;
  height: 30px;
  font-size: $font-size-small;

  .vdl-date-time-picker__select__picker--sm,
  // specific for TimePicker button since the MDF does not have control of the TimePicker component.
  .vdl-date-time-picker--sm .vdl-date-time-picker__select__picker {
    font-size: $font-size-base;
  }
}

.mdf.wfnx .vdl-date-time-picker__select__picker--lg,
.mdf.wfnx .vdl-date-time-picker--lg .vdl-date-time-picker__select__picker {
  font-size: $font-size-xlarge;
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

$step-bar-width: 200px;

.wizard-stepbar {
  position: absolute;
  height: 100%;
  margin-left: 0;

  .wizard-step:focus {
    outline: 0;
    border: none;
  }

  .icon-hidden {
    visibility: hidden;
  }

  .wizard-steps-container {
    @include flex-start-vertical(center);
    background-color: $neutral-dark-mid;
    box-shadow: inset 2px 0 5px -2px rgba(0, 0, 0, 0.2);
    align-self: flex-start;
    padding-top: 26px;
    padding-bottom: 1em;
    max-width: $step-bar-width;
    font-weight: bold;
    min-width: 15em;
    height: 100%;

    .wizard-step {
      width: 100%;
      top: 5px;
      min-height: 6em;
      border: none;
      background: none;
      position: relative;
      color: $neutral;
      padding-left: 1em;

      .wizard-step-label-icon-wrapper {
        @include flex-start-horizontal(center);
        position: relative;
        height: 60px;
      }

      .wizard-step-label {
        visibility: visible;
        font-weight: $font-weight-bold;
        margin-left: .5em;
      }

      .wizard-vertical-line, .wizard-vertical-line-viewless {
        border-left: 2px dashed $neutral;
        height: 4em;
        position: absolute;
        left: 43px;
        top: calc(100% - 32px);
        visibility: visible;

        &.vertical-line-jumpable {
          border-color: $accent-0;
          border-left-style: solid;
        }

        &.valid-vertical-line {
          border-color: $accent-0;
          border-left-style: solid;
        }
      }

      &.valid-step {
        color: $white;

        .wizard-vertical-line {
          border-color: $accent-1;
        }

        .wizard-step-icon {
          border-color: $accent-1;
          background-color: $neutral-dark-mid;
          color: $accent-1;
        }

        .wizard-step-icon:hover {
          color: $accent-0-darker;
          background-color: $accent-1;
          .step-checkmark {
            color: $accent-1;
          }
        }

        .step-checkmark {
          width: 20px;
          height: 20px;
          border: 2px solid $accent-1;
          border-radius: 50%;
          background-color: $accent-1;
          position: absolute;
          top: 2px;
          left: 42px;

          &:before {
            font-size: 15px;
            align-items: center;
            display: flex;
            color: $neutral-dark-mid;
          }
        }
      }

      &.jumpable-step {
        color: $white;

        .wizard-step-icon {
          border-color: $accent-0;
          background-color: $neutral-dark-mid;
          color: $accent-0;
        }

        .wizard-step-icon:hover {
          color: $accent-0-darker;
          background-color: $accent-0;

          .step-checkmark {
            color: $accent-0;
          }
        }

        .step-checkmark {
          width: 20px;
          height: 20px;
          border-radius: 2px solid $accent-0;
          background-color: $accent-0;
          position: absolute;
          top: 2px;
          left: 42px;
        }
      }

      .delegate-icon:hover {
        color: $accent-0-darker;
        background-color: $accent-0;;
      }

      /* current step */
      &.current-step {
        cursor: default;

        .wizard-step-arrow {
          right: -30px;
          top: 0;
          border-top: 30px solid transparent;
          border-bottom: 30px solid transparent;
          position: absolute;
          border-left: 30px solid $neutral-lighter;
        }

        .wizard-views-in-step-wrapper {
          position: relative;
          width: calc(100% + 14px);
          height: auto;
          margin-left: -14px;
          visibility: visible;
          background-color: $neutral-lighter;

          .wizard-view-in-step {
            color: $black;
            font-weight: $font-weight-base;
            font-size: $font-size-h6;
            margin: 5px 0;
            text-align: left;
            padding-left: .5em;

            &:hover {
              cursor: pointer;
            }

            &:first-child {
              margin: 0;
            }

            &:last-child {
              margin-bottom: 10px;
            }

            span {
              width: 1em;
              margin: 0 .5em;
            }

            &.current-view-in-step {
              color: $accent-3-dark;
              font-size: $font-size-h6;
              font-weight: $font-weight-bold;
            }

            &.disabled-view-in-step {
              color: $neutral;
            }
          }
        }

        .wizard-step-label-icon-wrapper {
          background-color: $neutral-lighter;
          visibility: visible;
          color: $accent-3-dark;
          left: -14px;
          padding-left: 14px;
          width: calc(100% + 14px);
        }

        .wizard-vertical-line {
          left: 43px;
        }

        .wizard-vertical-line-viewless {
          left: 43px;
          top: calc(100% - 32px);
        }

        .wizard-step-icon {
          border-color: rgba(0, 0, 0, 0);
          background-color: $neutral-lighter;
          color: $accent-3-dark;
        }

        .delegate-icon {
          border-color: $accent-0;
          background-color: $neutral-mid;
          color: $accent-0;
          left: 56px;
        }

        .delegate-icon:hover {
          color: $accent-0-darker ;
          background-color: $accent-0;
          position: absolute;
          left: 56px;
        }

        .delegated-icon {
          left: 56px;
        }
      }

      .wizard-step-icon {
        color: $neutral;
        visibility: visible;
        font-size: 28px;
        width: 52px;
        height: 52px;
        border: 2px solid $neutral;
        border-radius: 50%;
        margin-left: 4px;
        background-color: $neutral-dark-mid;
        text-align: center;
        line-height: 190%;
        flex-shrink: 0;
      }

      .wizard-step-label {
        visibility: visible;
        margin-left: 15px;
      }

      .delegate-icon {
        font-size: $icon-size-large;
        border-radius: 50%;
        border-color: $accent-0;
        background-color: $neutral-dark-mid;
        color: $accent-0;
        position: absolute;
        top: 2px;
        left: 42px;
        padding: 4px;
        visibility: visible;
      }

      .delegate-icon:hover {
        color: $accent-0-darker ;
        background-color: $accent-0;
      }

      .delegated-icon {
        height: 20px;
        width: 20px;
        font-size: $font-size-base;
        border-radius: 50%;
        border-color: $accent-3-dark ;
        background-color: $accent-3-dark ;
        color: $white;
        position: absolute;
        top: 2px;
        left: 42px;
        padding: 4px;
        visibility: visible;
      }

      .delegate-icon-disabled {
        font-size: $font-size-small;
        border-radius: 50%;
        border-color: $black;
        background-color: $neutral-dark-mid;;
        color: $black;
        position: absolute;
        top: 2px;
        left: 42px;
        padding: 0.3em;
        visibility: visible;
      }
    }

    // Called when collapsing
    &.wizard-collapsed {
      display: none;
    }

    &.wizard-expand {
      display: block;
    }
  }

  .collapse-control {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 210px;
    width: 30px;
    height: 30px;
    background-color: $accent-5;
    border-radius: 0 0 10px;

    i {
      font-size: $font-size-medium;
      color: $white;
    }

    &--open {
      left: 210px;
    }

    &--closed {
      left: 0;
    }
  }
}

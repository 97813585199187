@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf .mdf-verso-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .mdf-verso-view-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    margin-bottom: 10px;
  }

  .mdf-verso-view-body {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mdf-verso-view-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    box-shadow: 0 -2px 4px 1px rgba(0, 0, 0, 0.07);
    background-color: $white;

    // Need this for IE11 to center align flex items when flex-container having min-height instead of height.
    &::after {
      content: '';
      min-height: inherit;
      font-size: 0;
    }
  }
}
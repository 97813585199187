@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;
@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

.vdl-date-range-desktop-popup {
  &.vdl-popup__content {
    width: 300px;
  }
}

.vdl-date-range-desktop-calender {
  display: flex;
  flex-wrap: wrap;
}

.vdl-date-range-picker-footer {
  padding: 5px;
  border-top: 1px solid $neutral-light;
  background-color: $neutral-lightest;
  display: flex;

  .vdl-button--link {
    margin: 0;
  }

  &-left {
    flex: 1;
    text-align: left;
  }

  .vdl-date-time-picker__select__picker {
    text-transform: var(--text-transform);

    &--lg {
      font-size: $font-size-medium;
    }

    &--sm {
      font-size: $font-size-small;
    }
  }
}

.vdl-date-range-picker-calendar {
  .calendar-header {
    cursor: default;

    .calendar-header-currentDate-selector {
      pointer-events: none;
    }
  }

  .calendar-grid-item {
    margin: 0;
  }

  .calendar-grid-item__selected {
    background-color: $accent-0-light;
    border: 1px solid $accent-0-dark;
    box-shadow: none;
    color: $font-color-base;

    &:hover {
      background-color: $accent-0-lightest;
    }
  }

  .calendar-grid-item__range__selected {
    background-color: $accent-0-lighter
  }
}

.vdl-date-range-picker-calendar-first {
  .calendar-header {
    .calendar-header-navbutton:last-child {
      display: none;
    }
  }
}

.vdl-date-range-picker-calendar-second {
  .calendar-header {
    .calendar-header-navbutton:first-child {
      display: none;
    }
  }
 }

@media screen and (min-width: $screen-md) {
  .vdl-date-range-desktop-popup {
    &.vdl-popup__content {
      width: 480px;
    }
  }

  .vdl-date-range-desktop-calender {
    flex-wrap: nowrap;
  }

  .vdl-date-range-picker-footer {
    width: 480px;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/z-index' as *;

.Select--multi {
  .Select-input {
    input {
      margin: 0;
      padding-left: 0;
    }
  }
}

.mdf .MDFSelectBox,
.MDFSelectBox {
  &__control {
    border: var(--input-border);
    border-radius: var(--input-border-radius) !important;
    min-height: var(--form-input-height) !important;
    margin-bottom: 5px;

    &--is-focused {
      border: 2px solid $input-border-focus;
      outline: 0;
      box-shadow: var(--dropdown-list-focus-box-shadow);
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    &--is-disabled {
      border: var(--input-border-disabled);
      background-color: var(--dropdown-list-disabled-background);
      pointer-events: auto;
      cursor: not-allowed;

      .MDFSelectBox__dropdown-indicator {
        color: var(--dropdown-list-disabled-color);
      }

      .MDFSelectBox__multi-value {
        pointer-events: none;
        background-color: $neutral;

        &__label,
        &__remove {
          color: $neutral-dark;
        }
      }
    }
  }

  &__placeholder {
    color: $neutral-dark
  }

  &__clear-indicator {
    padding-right: 0;

    >svg {
      width: 16px;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    color: $link-color;
    padding: 7px 8px;
  }

  &__menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
    width: auto !important;
    min-width: 100%;
    z-index: $zindex-modal !important;
  }

  &__menu-list {
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__option:empty {
    height: 35px;
  }

  &:hover {
    border-color: var(--dropdown-list-hover);
  }
}

// The application consuming MDFSelectbox is expected to pass this class vdl-dropdown-list-nowrap to wrap options
.mdf .vdl-dropdown-list-nowrap .MDFSelectBox__menu,
.MDFSelectBox__menu,
.vdl-dropdown-list-nowrap .MDFSelectBox__menu {
  .MDFSelectBox__menu-list {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
  }

  .MDFSelectBox__option {
    white-space: nowrap;
    border: 2px solid transparent;

    &--is-focused {
      border: 2px solid var(--list-option-focus-border);
      background-color: var(--sdf-context-menu-item-style-base-fill-color, transparent);
    }

    &--is-selected {
      border-color: var(--list-option-focus-border);
      background-color: var(--sdf-context-menu-item-style-active-fill-color, #e8effa);
      color: var(--list-option-color);
    }

    &:hover {
      background-color: var(--list-option-hover-background);
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

// Placed here so files below can use the variables.
@import '_variables.scss';
@import '_layout.scss';
@import 'wfnXStyles/index';
@import '_address.scss';
@import '_agGrid.scss';
@import '_alert.scss';
@import '_autocompleteaddress.scss';
@import '_avatar.scss';
@import '_badge.scss';
@import '_button.scss';
@import '_busyIndicator.scss';
@import '_calendar.scss';
@import '_card.scss';
@import '_carousel.scss';
@import '_chatButton.scss';
@import '_checkboxSet.scss';
@import '_ruleset.scss';
@import '_customwizard.scss';
@import '_dashboard.scss';
@import '_dataRing.scss';
@import '_daterangepicker.scss';
@import '_datetimepicker.scss';
@import '_dropdownlist.scss';
@import '_dropdownMenu.scss';
@import 'dualmultiselect/_dualmultiselect.scss';
@import '_editor.scss';
@import '_fileupload.scss';
@import '_grid.scss';
@import '_helpicon.scss';
@import '_journeyMap.scss';
@import '_layoutBox.scss';
@import '_localePicker.scss';
@import 'modalDialog';
@import '_mdfActionMenu.scss';
@import '_mdfBackdrop.scss';
@import '_mdfCircleIcon.scss';
@import '_mdfDrag.scss';
@import '_mdffileupload.scss';
@import '_mdfInfiniteList.scss';
@import '_inlineAlert.scss';
@import '_mdfFilter.scss';
@import '_mdfListCard.scss';
@import '_mdfListHeader.scss';
@import '_mdfMaskedContent';
@import '_mdfNotes';
@import '_mdfPopupDialog.scss';
@import '_mdfTable.scss';
@import '_mdfWizard.scss';
@import 'newsFeed/_newsFeedItem.scss';
@import 'newsFeed/_newsFeed.scss';
@import '_numberSpinner.scss';
@import '_paginator.scss';
@import 'phone/react-responsive-ui.css';
@import 'phone/_phone.scss';
@import '_progressTracker.scss';
@import '_obfuscation.scss';
@import '_overlaypopover.scss';
@import '_radioButtonSet.scss';
@import '_resizeHandler.scss';
@import '_revolutionFixes.scss';
@import '_search.scss';
@import '_section.scss';
@import '_segmentedwizard.scss';
@import '_segmentFiller.scss';
@import '_select.scss';
@import '_separator.scss';
@import '_sidePanel.scss';
@import '_sidebar.scss';
@import '_slidein.scss';
@import '_smallCard.scss';
@import '_snackbar.scss';
@import '_spreadSheet.scss';
@import '_statusIcon.scss';
@import '_stepnavigation.scss';
@import '_taxid.scss';
@import '_textbox.scss';
@import '_togglecontent.scss';
@import '_timeframe.scss';
@import '_timeinput.scss';
@import '_toggle.scss';
@import '_toolBar.scss';
@import '_tree.scss';
@import '_treeList.scss';
@import '_wfnMockShell';
@import '_wizard.scss';
@import '_validation.scss';
@import '_versoView.scss';
@import '_video.scss';
@import '_view.scss';
@import 'froalaEditor.scss';
// This needs to be at the end for the cascade to work.
@import '_colors.scss';

label.mdf-label-error {
  color: $accent-6;
}

.gu-mirror {
  background-color: $accent-0-lighter;
  border-bottom: 1px solid $neutral-lighter;
  border-top: 1px solid $neutral-lighter;
  cursor: pointer;
}

.mdf-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Styles to match the synergy form labels
.one-ux label.mdf-form-control-label {
  font-weight: $font-weight-mediumbold;
  font-size: $font-size-small;
  color: $neutral-darkest;
  &:-error {
    color: $accent-6;
  }
}

.mdf {
  sdf-icon,
  sdf-icon.hydrated {
    // Eliminates the 'stroke' part of the MDF color styles as this causes an outline
    // on the sdf-icon when one of our color styles is used.
    stroke: initial;
  }
}

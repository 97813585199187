@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;
@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

@media screen and (max-width: $screen-md) {
  .mdf .vdl-slide-in-title {
    font-size: $font-size-medium;
  }
}

@media screen and (max-width: $screen-xs) {
  .mdf .vdl-slide-in-title {
    margin: 0;
    font-size: $font-size-large;
  }
}

.mdf .slidein-help-focus {
  font-size: $font-size-h2;
}

.mdf .vdl-modal-open,
.mdf.vdl-modal-open {
  --sdf-focus-pane-z-index: 1050;
  overflow-y: scroll;
  position: relative;

  &.mdf-mobile-modal-fix .vdl-slide-in {
    position: absolute;
  }
}

.one-ux .vdl-modal-open,
.one-ux.vdl-modal-open {
  --sdf-focus-pane-z-index: 1050;
}

.mdf .vdl-slide-in-content {
  overflow-y: hidden;
  height: 100%;
  min-height: 100%;
  width: 100vw;
  top: 0;
  -webkit-overflow-scrolling: touch; // Smooths scrolling when there is a fixed snackbar in IOS

  @media screen and (min-width: $screen-xs) {
    width: 100% !important;
  }

  .vdl-modal-open-free-focus {
    height: inherit;
    width: inherit;
  }

  // handles when snackbar is passed as a prop to MDFSlideIn (snackBar), if so this node will be a direct descendant
  >.mdf-snackbar {
    position: relative;
    width: 100%;
    // remove margin-bottom because mdf-snackbar is set relative and does not overlap bottom content like position:fixed would do
    margin-bottom: 0;
  }

  .mdf-wizard-footer-container {
    width: 100%;

    .mdf-snackbar {
      position: relative;
      width: 100%;
    }
  }
}

.mdf-slide-in-body-with-snackbar {
  margin-bottom: 56px;
}

// To force the Snackbar to stick to the bottom of slide ins
// Support if snackBar is added as an individual component within the application.
.vdl-slide-in-body {
  // handles a snackbar passed to SlideIn.Body
  .mdf-snackbar {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    -webkit-overflow-scrolling: touch; // Smooths scrolling when there is a fixed snackbar in IOS
  }

  &+.mdf-wizard-footer-container {
    flex: 0 0 auto;
    width: 100%;
    background-color: $white;

    // Make sure wizard snackbar works in the slidein like it works inside the sidepanel.
    .mdf-snackbar {
      position: relative;
    }
  }
}

.vdl-slide-in-footer {
  // places snackbar passed relative to the slideIn, otherwise snackbar would fill whole document view
  .mdf-snackbar {
    position: relative;
  }
}

// Overrides for when the MDFSlideIn contains a MDFVersoView.
.mdf {
  .mdf-verso-view-mods {
    .vdl-slide-in-body {
      margin: 0;
      padding: 0;
      flex: 1 1 auto; // Occupy left over space in slide-in-content after considering header and footer.
      overflow: auto;
    }

    .vdl-slide-in-body > div {
      height: 100%;
    }

    &.mdf-slide-in-body {
      height: 100%;
    }
  }
}

// Override for SlideIn component
.mdf {
  .vdl-slide-in-title {
    h2 {
      font-size: $font-size-h2;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;
@use '@synerg/vdl-css-framework/scss/variables/z-index' as *;

.mdf-mobile-dropdownlist__input-container {
  padding: 0 !important;

  &::after {
    content: '\f078';
    font: normal normal normal $icon-size-medium FontAwesome;
    right: 11px;
    top: 10px;
    font-weight: $font-weight-thin;
    position: absolute;
    pointer-events: none;
  }

  &.mdf-mobile-dropdownlist--disabled {
    background-color: $neutral;

    select {
      background-color: $neutral
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    background-color: $white;
    border: 0;
    padding-left: 10px;
    /* To hide the dropdown icon in IE */
    &::-ms-expand {
      display: none;
    }
  }
}

.mdf .mdfDropdownbox,
.mdfDropdownbox {
  max-width: 200px;
  font-size: 20px;
}

.mdf .vdl-dropdown-list,
.vdl-dropdown-list {
  margin-bottom: 5px;
  overflow: auto;
  max-height: 200px;

  &.vdl-dropdown-list-nowrap {
    .vdl-popup__content {
      position: relative;
      display: inline-block;
      min-width: calc(100% - 10px);

      > div {
        display: inline-block;
        width: 100%;
      }

      .vdl-list {
        white-space: nowrap;
      }
    }
  }
}

// Remove these when the ADP CSS Framework team fixes their side.
.mdf .vdl-dropdown-button__icon,
.vdl-dropdown-button__icon {
  font-size: $font-size-small !important;
}

// React virtualized overrides
.mdf .Select,
.Select {
  &.is-focused > .Select-control {
    border: 2px solid $input-border-focus;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .Select-control {
    border-radius: 0;
  }
}

.mdf .Select-menu-outer,
.Select-menu-outer {
  z-index: $zindex-modal;
}

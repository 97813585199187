@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

@mixin popover-style-default($fill, $borderColor, $background) {
  border: solid 1px $borderColor;
  background: $background;
  fill: $fill;

  // overwrite the popover-content font size as per the new extended ux design
  .popover-content {
    font-size: $font-size-h5;
  }

  &.bottom .arrow {
    border-bottom-color: $borderColor;

    &:after {
      border-bottom-color: $background;
    }
  }

  &.top .arrow {
    border-top-color: $borderColor;

    &:after {
      border-top-color: $background;
    }
  }

  &.left .arrow {
    border-left-color: $borderColor;

    &:after {
      border-left-color: $background;
    }
  }

  &.right .arrow {
    border-right-color: $borderColor;

    &:after {
      border-right-color: $background;
    }
  }
}

.mdf-overlay-popover {
  &.vdl-popover {
    &--success {
      @include popover-style-default($accent-1, $accent-1-dark, $accent-1-lightest);
    }

    &--warning {
      @include popover-style-default($accent-5, $accent-5, $accent-5-lightest);
    }

    &--error {
      @include popover-style-default($accent-6, $accent-6, $accent-6-lightest);

      .popover-title {
        color: $accent-6-dark;
      }
    }

    &--info {
      @include popover-style-default($accent-0, $accent-0-dark, $accent-0-lightest);
    }

    &--default {
      @include popover-style-default($white, $neutral, $white);
    }
  }
}

.mdf-popover-maxWidth {
  max-width: 276px;
}

.mdf-simple-help:focus-visible {
  outline: 0;
  box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px calc(2px + 1px) #476bc3;
}

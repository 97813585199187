@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf {

  .mdf-table {
    display: flex;
    align-items: center;

    table {
      width: 100%;
      font-size: var(--grid-header-font-size);

      tr {
        height: 40px;

        th {
          border-top: 1px solid $neutral;
          border-bottom: 1px solid $neutral;
          border-left: none;
          border-right: none;
          &:focus {
            border: 2px solid $input-border-focus;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          }
        }

        td {
          padding: 5px;
          border-bottom: 1px solid $neutral;
          border-left: none;
          border-right: none;
          &:focus {
            border: 2px solid $input-border-focus;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          }
        }
      }

      tbody {
        tr:hover {
          background-color: $accent-0-light;
        }
      }

      thead {
        tr {
          background-color: $neutral-lightest;

          th {
            text-transform: var(--text-transform);
            text-align: center;
            border-bottom: 1px solid $neutral;
            &:focus {
              border: 2px solid $input-border-focus;
              outline: 0;
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
              transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            }
          }
        }
      }

      tfoot {
        tr:hover {
          background-color: $accent-0-light;
        }
      }
    }
  }
}

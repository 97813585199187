@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;
@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

.mdf .dateTimePicker {
  .mdf-mobile-picker__input {
    -webkit-appearance: none;
    color: transparent !important;
    position: relative;
    border-radius: 0;
    width: 100%;
    height: 34px;

    &::before {
      position: absolute;
      content: attr(data-date);
      display: inline-block;
      color: black;
    }

    &::-webkit-datetime-edit,
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator,
    &::-webkit-clear-button {
      display: none;
    }
  }

  .vdl-popup--dropup {
    margin-bottom: 0;
  }
}

.vdl-date-time-picker {
  margin-bottom: 5px;

  .vdl-date-time-picker__select__picker {
    border: 2px solid transparent;

    &:focus {
      border: 2px solid $input-border-focus;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      margin-top: 2px;
      border-width: 1px;
    }
  }
}

.vdl-date-picker-footer {
  padding: 5px;
  border-top: 1px solid $neutral-light;
  display: flex;
  justify-content: center;

  .vdl-button--link {
    margin: 0;
  }
  .vdl-date-time-picker__select__picker {
    text-transform: var(--text-transform);

    &--lg {
      font-size: $font-size-medium;
    }

    &--sm {
      font-size: $font-size-small;
    }
  }
}

// Added to make styles similar to oneUX synergy web components.
.one-ux {
  .vdl-date-time-picker {
    .vdl-date-time-picker__select {
      bottom: 0;
    }

    .vdl-date-time-picker__select__picker {
      font-size: $font-size-base;
    }

    .vdl-date-time-picker__input {
      &:-ms-input-placeholder{
        font-style: normal;
      }

      &::placeholder {
        font-style: normal;
      }
    }
  }
}

// Fix a style conflict from the Synerg Angular stylesheets that cause duplicate
// calendar icons to appear in our date picker. Both ADP Analytics and BBQ add back
// the Font Awesome icon we removed and ADP Analytics changes the color. Need to
// use the same selectors that Synerg Angular uses ([synerg-theme=one]).
[synerg-theme=one] .mdf .vdl-date-time-picker.dateTimePicker .vdl-date-time-picker__select .vdl-date-time-picker__select__picker:before {
  content: none;
  color: var(--link-color);
}

@media screen and (max-width: $screen-xs) {
  .mdf .vdl-date-time-picker input {
    border: var(--input-border);;
    border-radius: var(--input-border-radius);;
    height: var(--form-input-height);
    background-color: $input-bg-color;
  }

  .mdf .vdl-date-time-picker-disabled {
    background-color: $neutral-disabled;
    color: var(--input-color-disabled);
    cursor: not-allowed;
  }
}

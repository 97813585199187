@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf-editor-wrapper .rdw-option-wrapper {
  height: 32px;
  min-width: 37px;
}

.mdf-editor-pane {
  border: 1px solid #F1F1F1;
  padding: 5px;
  height: 300px !important;
  background-color: white;
}

.rdw-custom-option {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.DraftStyleDefault-block--atomic {
  margin: 0;
}

.mdf-editor-field-name {
  font-size: 14px;
}

.mdf-editor-section-name {
  font-size: 12px;
}

.mdf-placeholder-section {
  float: right;
  display: block;
  margin-bottom: 6px;
  margin-left: 6px;
}

.mdf-editor-section {
  display: inline-block
}

.mdf-placeholder-label {
  padding-right: 20px;
  font-size: 13px;
  margin-top: 10px;
}

.mdf-placeholder-dropdown {
  width: 250px;
}

.mdf-placeholder {
  display: inline-block;
  background-color: $accent-5-light;
  cursor: pointer;
  &-existing {
    background-color: $neutral-light;
    cursor: pointer;
    display: inline-block;
  }
}

.placeholder-big {
  // overriding the height of modal dialog in case of calendar or select box placeholder type. Because calendar is getting hidden behind the popup
  .vdl-modal-content {
    height: 430px;
  }
}

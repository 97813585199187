@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-wizard {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 80px;

  &-header {
    color: $link-color;
    font-size: $font-size-medium;

    &-prefix {
      padding-right: 10px;
      font-weight: $font-weight-semibold;

      &::after {
        content: ":";
      }
    }

    &-progress {
      .vdl-progress-bar__bar {
        background-color: $neutral;
      }
      .vdl-progress-bar__percentage {
        height: 5px;
      }
    }
  }

  &-step {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  &-footer {
    flex: 1;
    display: flex;

    &-navbutton {
      font-size: $font-size-medium;
      flex: 1;
      border-radius: 0;
    }
  }
  /* Override the snackbar position to occupy full width for smaller device */
  @media (min-width: 980px) {
    .mdf-snackbar {
      position: absolute;
    }
  }
}

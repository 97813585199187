@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

.mdf-taxId {
  display: flex;

  &.column {
    flex-direction: column;
    gap: 4px;
  }

  &.row {
    flex-direction: row;

    .MDFSelectBox__control {
      min-width: 164px;
    }

    .mdf-taxId-cell{
      margin-right: 10px;
    }

    .mdf-taxId-applied-cell {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .render-taxId {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &-cell {
    flex: 1 1 auto;
    display: flex;

    .mdf-validated-field {
      flex: 1 1 auto;

      label {
        white-space: nowrap;
      }
    }

    &.nationalidentifier {
      display: block;
    }
  }

  &-applied-cell {
    align-self: flex-end;
    padding-bottom: 2px;
    white-space: nowrap;

    .vdl-checkbox > label {
      white-space: nowrap;
    }
  }

  &-notSpecified-cell {
    padding-top: 10px;
  }
}

@media (max-width: $screen-sm-max) {
  .mdf-taxId {
    .render-taxId {
      flex-direction: column;
      gap: 8px;
      align-items: stretch;
    }

    &-applied-cell {
      align-self: normal;
    }
  }
}

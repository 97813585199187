@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf {

  .mdf-masked-content {
    display: flex;
    align-items: center;
    min-width: 170px;
    border: 1px solid $neutral-mid;
    height: 35px;
    background-color: $white;
    padding: 5px;
    
    span {
      flex-grow: 1;
    }

    i {
      color: $link-color;
      cursor: pointer;
    }
  }
  
  .mdf-masked-content-readonly {
    display: flex;
    align-items: center;
    min-width: 170px;
    height: 35px;
    padding: 5px;
    
    span {
      flex-grow: 1;
    }

    i {
      color: $link-color;
      cursor: pointer;
    }
  }
}
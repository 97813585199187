@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.wfnx {
  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-justify {
    text-align: justify;
  }

  .no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .h1 {
    font-size: $font-size-h1;
    font-weight: $font-weight-h1;
  }

  .h2 {
    font-size: $font-size-h2;
    font-weight: $font-weight-h2;
  }

  .h3 {
    font-size: $font-size-h3;
    font-weight: $font-weight-h3;
  }

  .h4 {
    font-size: $font-size-h4;
    font-weight: $font-weight-h4;
  }

  .h5 {
    font-size: $font-size-h5;
    font-weight: $font-weight-h5;
  }

  .h6 {
    font-size: $font-size-h6;
    font-weight: $font-weight-h6;
    text-transform: uppercase;
  }

  .body {
    color: $black;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
  }

  .link {
    font-size: $font-size-h5;
    font-weight: $font-weight-base;
    text-transform: uppercase;
    color: $link-color;
  }

  .font-weight-light {
    font-weight: $font-weight-light;
  }
}
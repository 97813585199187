@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.portal-open {
  // needed so other overlays (ie dropdown, popover) can fill view over sidePanel portal and register click events properly
  position: absolute;

  .portal-backdrop-enter {
    z-index: 1040;
    opacity: 0;
  }

  .portal-backdrop-enter-active {
    z-index: 1040;
    opacity: 1;
    transition: opacity 300ms;
  }

  .portal-backdrop-exit {
    opacity: 1;
  }

  .portal-backdrop-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .portal-backdrop-exit-done {
    z-index: 1040;
    opacity: 0;
  }

  // entered transition properties
  .slide-enter {
    transform: translateX(100%);
    z-index: 1050;
    height: 100%;
    top: 0;
  }

  .none-ani-enter {
    transform: translateX(100%);
    z-index: 1050;
    height: 100%;
    top: 0;
  }

  //  entered transition properties
  .slide-enter.slide-enter-active {
    pointer-events: none;
    z-index: 1050;
    opacity: 1;
    transform: translateX(0);
    // use "transform 1000ms cubic-bezier(0.1, 1.275, 0.7, 0.885)" if you want easeIn bouncy effect;
    transition: transform 1000ms;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
  }

  .slide-inner-enter.slide-enter-active {
    pointer-events: none;
    z-index: 1051 !important;
    opacity: 1;
    transform: translateX(0);
    transition: transform 1000ms;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
  }

  .none-ani-enter.none-ani-enter-active {
    z-index: 1050;
    opacity: 1;
    transform: translateX(100%);
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
  }

  .slide-enter-done {
    z-index: 1050;
    position: absolute;
    top: 0;
    right: 0;
  }

  .none-ani-enter-done {
    z-index: 1050;
    position: absolute;
    top: 0;
    right: 0;
  }

  // exit transition properties
  .slide-exit {
    transform: translateX(0);
  }

  .slide-exit.slide-exit-active {
    pointer-events: none;
    z-index: 1050;
    opacity: 1;
    transform: translateX(100%);
    transition: transform 1000ms;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
  }

  .side-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;

    // default the max-width to 1024px on vdl and one-ux.
    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }

    &.auto {
      // Confluence SlideIn/SidePanel standards state 30% as a min width
      min-width: 30%;
    }

    &.sm {
      width: 25%;
    }

    &.md {
      width: 50%;
    }

    &.lg {
      width: 75%;
    }

    &.xl {
      width: 100%;
    }

    &.inner-side-panel-padding {
      position: relative;
      padding-right: 15px;
      right: -15px;
    }

    @media screen and (max-width: 480px) {
      width: 100% !important;
    }
  }

  .side-panel-leading {
    flex: 1;
    margin: 10px 0;
  }

  .side-panel-content {
    &.slide-inner {
      z-index: 1051;
    }

    position: fixed;
    height: 100%;
    width: 100%;

    .portal-cover {
      display: flex;
      width: 100%;
      position: fixed;
      height: 100%;
    }

    // using fixed so busy-indicator is not affected by scroll
    // unsetting inherited Left & Right positions from vdl-busy-indicator because the busy indicator needs to positioned center realtive to side-panel-body width
    .side-panel-busy-indicator {
      position: fixed;
      height: 100%;
      align-self: center;
      left: unset;
      right: unset;
    }
  }

  .side-panel-title {
    min-width: 0;
    display: flex;
    overflow: hidden;
    padding: 5px;

    h2 {
      margin: 0;
      font-weight: $font-weight-semibold;
      color: $black;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and (max-width: 650px) {
        font-size: $font-size-h4;
        font-weight: $font-weight-semibold;
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .side-panel-header {
    display: flex;
    flex: 0 0 auto;
    background-color: var(--slide-in-title-bg);
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 5px;

    /*
     * Allows header title to be centered in middle with flexbox even though only 2 elements ~
     * A better solution would be to use CSS grid - grid-template-columns when IE no longer
     * needs to be supported.
     */
    &::after {
      content: '';
      flex: 1
    }

    .vdl-button {
      margin: 0 15px 0 15px;
    }
  }

  .side-panel-body {
    /*
     * This flex property in combination with side-panel-header - flex: 0 0 auto &
     * side-panel-header - flex: 0 0 auto... allows heater/footer to be 'fixed' while
     * normal document flow is maintained
    */
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    background-color: $modal-content-bg;
    padding: 0 20px;

    .mdf-side-panel-label-error {
      padding: 10px 0;
      color: $accent-6;
    }

    & + .mdf-wizard-footer-container {
      flex: 0 0 auto;
      width: 100%;
      background-color: $white;

      // Make sure wizard snackbar works in the sidepanel like it works inside the slidein.
      .mdf-snackbar {
        position: relative;
      }
    }
  }

  .side-panel-header-title {
    display: inline-flex;
    min-width: 0;
  }

  .side-panel-help-icon {
    align-self: center;
  }

  .side-panel-footer {
    flex: 0 0 auto;
    background-color: $white;
    display: flex;
    justify-content: center;

    // places snackbar passed relative to the sidePanel, otherwise snackbar would fill whole document view
    .mdf-snackbar {
      position: relative;
    }
  }

  .portal-background {
    background: rgba(0, 0, 0, 0.50);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1040;

    &.inner-side-panel-backdrop {
      z-index: 1050;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

.mdf .vdl-modal-content {
  min-height: auto !important;
}

.mdf .vdl-mdf-modal-content {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $screen-xs) {
  .mdf .vdl-modal-dialog.md .vdl-modal-content {
    width: 350px;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

@import './statusIcon';

.mdf {
  .mdf-card-atom {
    background-color: transparent;
    border-radius: 5px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    margin: 0;

    &.mdf-card--blank {
      border: 0;
    }

    &.mdf-card--dash {
      border: 2px dashed $neutral-mid;
    }

    &.mdf-card--flat {
      border: 1px solid $neutral-mid;
    }

    &.mdf-card--shadow {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid $neutral-mid;
    }

    &.inline {
      display: inline-block;
    }
  }

  .small-card {
    border-radius: 5px;
    background-color: $white;
    margin: 0;
    display: flex;
    min-height: 130px;
    flex-direction: column;
    box-shadow: none;

    // Applied only to the cardBottom when applyLineBorder is set to true
    &.mdf-applyBorderLine > div:nth-child(3).mdf-cardLineBorder,
    &.mdf-applyBorderLine > div:nth-child(2).mdf-cardLineBorder {
      border-top: 1px solid $neutral-mid;
    }

    &--shadow {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid $neutral-mid;
    }

    &--flat {
      border: 1px solid $neutral-mid;
    }

    &--dash {
      border: 2px dashed $neutral-mid;
      background-color: transparent;
    }

    &--blank {
      border: 0;
    }

    .small-card-top {
      position: relative;
      color: $black;
      // fix both margin top and margin-left to 15px
      display: flex;
      align-items: center;
      margin: 15px 15px 0 15px;

      &--columnLayout {
        .small-card-title--icon {
          margin: 0 10px 0 0;
          color: $accent-0;
        }
      }

      .subTitleStyle {
        display: flex;
      }

      .small-card-subTitle {
        display: flex;
        color: $neutral-dark;
        font-size: $font-size-h5;
        font-weight: $font-weight-base;
      }

      &--left {
        justify-content: left;
      }

      &--center {
        justify-content: center;
      }

      .small-card-title {
        // fit both title and subtitle
        display: flex;
        // Fix overflowing title in firefox.
        flex: 1 0 auto;
        // the card width can differ and could not fix it to specific width. Reduce only the actionCorner space
        width: calc(100% - 50px);
        align-items: center;
        // Match the line height to the icon height to align things vertically
        line-height: 24px;

        .header {
          width: calc(100% - 25px);

          .small-card-title--alignItem {
            align-items: initial;
          }

          .small-card-title--selectable {
            color: $link-color;
          }

          .small-card-title--titleSubTitle {
            flex-direction: column;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--wrapTitle {
              white-space: normal;
            }
          }

          .small-card-title--small {
            font-size: $font-size-base;
          }

          .small-card-title--medium {
            font-size: $font-size-medium;
          }

          .small-card-title--large {
            font-size: $font-size-larger;
          }

          .small-card-title--semibold {
            font-weight: $font-weight-semibold;
          }

          .small-card-title--light {
            font-weight: $font-weight-light;
          }
        }
      }

      .small-card-action {
        cursor: pointer;

        &.actionIcon {
          color: $link-color;
          font-size: $icon-size-medium;
        }

        .vdl-button--link, .vdl-button {
          margin: 0;
          padding: 0;
        }
      }

      &--selectable {
        .small-card-action {
          cursor: pointer;
        }

        .small-card-title .header {
          width: 100%;

          h2 {
            font-weight: $font-weight-base;
          }
        }

        .mdf-actionInfo {
          order: -1;
        }

        // Only when title is available
        &.mdf-cardTop-flexDirection {
          flex-direction: row-reverse;
        }

        .small-card-title {
          margin-left: 0;
        }
      }
    }

    // small-card-subtitle is not visible in mobile env
    .small-card-middle.small-card-mobile {
      flex: 1 1 0%;
    }

    .small-card-middle {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;

      &.mdf-cardLineBorder {
        margin-top: 5px;
      }

      &--low {
        flex-direction: column-reverse;
      }

      &--icon {
        border: 2px solid $black;
        background: $white;
        color: $black;
      }

      &--status {
        border: 2px solid $white;

        // $borderColor, $bgColor, $iconColor
        &--notStarted {
          @include mixin-status-style($neutral-dark, $white, $neutral-dark);
        }

        &--inProgress, &--info {
          @include mixin-status-style($accent-0-dark, $white, $accent-0-dark);
        }

        &--inReview, &--submitted {
          @include mixin-status-style($accent-0, $white, $accent-0);
        }

        &--approved, &--accepted, &--completed, &--success {
          @include mixin-status-style($accent-1-dark, $white, $accent-1-dark);
        }

        &--inComplete, &--warning {
          @include mixin-status-style($accent-5, $white, $accent-5);
        }

        &--archived {
          @include mixin-status-style($neutral-dark, $white, $neutral-dark);
        }

        &--error {
          @include mixin-status-style($accent-6, $white, $accent-6);
        }
      }

      .small-card-circle {
        // this circle should always be on the top
        z-index: 1;
        // Need this for IE to center align the cirlce
        right: 0;
        left: 0;

        // After setting the right and left to 0 for IE need to justify center with margin-left and margin-right set to 0 to center align the icon.
        .mdf-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;

          .vdl-avatar__initials {
            -ms-flex: 1 0 auto;
          }
        }

        //overWrite the panorama style per UX
        .vdl-avatar--md {
          width: 60px;
          height: 60px;
        }
      }

      .small-card-content {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 40px;
        // update margin left to all three
        // since padding is been applied on layoutitems
        padding-left: 5px;

        &.mdf-cardBackground {
          margin: 0;
        }
      }
    }

    .small-card-bottom {
      height: 50px;
      // always allocate minimum 50px of height to the bottom card.
      min-height: 50px;
      // Approved by UX: truncate the bottom text if it bigger than 50px of height
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      &.mdf-cardBackground {
        margin: 0;
      }
    }

    label:not(.vdl-radio label, .vdl-checkbox label),
    .revolution .mdf label:not(.vdl-radio label, .vdl-checkbox label),
    .mdf-validated-field > label:not(.vdl-radio label, .vdl-checkbox label) {
      font-size: $font-size-h6;
      color: $neutral-darker;
      margin-bottom: 0;

      &.mdf-label-error {
        color: $accent-6;
      }
    }

    .no-margin {
      margin: 0;
    }
  }

  .small-card2 {
    // Applied only to the cardBottom when applyLineBorder is set to true
    &.mdf-applyBorderLine > div:nth-child(3).mdf-cardLineBorder,
    &.mdf-applyBorderLine > div:nth-child(2).mdf-cardLineBorder {
      border-top: 1px solid $neutral-mid;
    }

    .small-card-top {
      position: relative;
      color: $black;
      // fix both margin top and margin-left to 1rem
      display: flex;
      align-items: center;
      margin: 1rem 1rem 0 1rem;

      &--columnLayout {
        .small-card-title--icon {
          margin: 0 8px 0 0;
          color: $accent-0;
        }
      }

      .subTitleStyle {
        display: flex;
      }

      .small-card-subTitle {
        display: flex;
        color: $neutral-dark;
        font-size: $font-size-h5;
        font-weight: $font-weight-base;
      }

      &--left {
        justify-content: left;
      }

      &--center {
        justify-content: center;
      }

      .small-card-title {
        // fit both title and subtitle
        display: flex;
        // Fix overflowing title in firefox.
        flex: 1 0 auto;
        // the card width can differ and could not fix it to specific width. Reduce only the actionCorner space
        width: calc(100% - 50px);
        align-items: center;
        // Match the line height to the icon height to align things vertically
        line-height: 24px;

        .header {
          width: calc(100% - 25px);

          .small-card-title--alignItem {
            align-items: initial;
          }

          .small-card-title--selectable {
            color: $link-color;
          }

          .small-card-title--titleSubTitle {
            flex-direction: column;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--wrapTitle {
              white-space: normal;
            }
          }

          .small-card-title--small {
            font-size: $font-size-base;
          }

          .small-card-title--medium {
            font-size: $font-size-medium;
          }

          .small-card-title--large {
            font-size: $font-size-larger;
          }

          .small-card-title--semibold {
            font-weight: $font-weight-semibold;
          }

          .small-card-title--light {
            font-weight: $font-weight-light;
          }
        }
      }

      .small-card-action {
        cursor: pointer;

        &.actionIcon {
          color: $link-color;
          font-size: $icon-size-medium;
        }

        .vdl-button--link, .vdl-button {
          margin: 0;
          padding: 0;
        }
      }

      &--selectable {
        .small-card-action {
          cursor: pointer;
        }

        .small-card-title .header {
          width: 100%;

          h2 {
            font-weight: $font-weight-base;
          }
        }

        .mdf-actionInfo {
          order: -1;
        }

        // Only when title is available
        &.mdf-cardTop-flexDirection {
          flex-direction: row-reverse;
        }

        .small-card-title {
          margin-left: 0;
        }
      }
    }

    // small-card-subtitle is not visible in mobile env
    .small-card-middle.small-card-mobile {
      flex: 1 1 0%;
    }

    .small-card-middle {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;

      &.mdf-cardLineBorder {
        margin-top: 5px;
      }

      &--low {
        flex-direction: column-reverse;
      }

      &--icon {
        border: 2px solid $black;
        background: $white;
        color: $black;
      }

      &--status {
        border: 2px solid $white;

        // $borderColor, $bgColor, $iconColor
        &--notStarted {
          @include mixin-status-style($neutral-dark, $white, $neutral-dark);
        }

        &--inProgress, &--info {
          @include mixin-status-style($accent-0-dark, $white, $accent-0-dark);
        }

        &--inReview, &--submitted {
          @include mixin-status-style($accent-0, $white, $accent-0);
        }

        &--approved, &--accepted, &--completed, &--success {
          @include mixin-status-style($accent-1-dark, $white, $accent-1-dark);
        }

        &--inComplete, &--warning {
          @include mixin-status-style($accent-5, $white, $accent-5);
        }

        &--archived {
          @include mixin-status-style($neutral-dark, $white, $neutral-dark);
        }

        &--error {
          @include mixin-status-style($accent-6, $white, $accent-6);
        }
      }

      .small-card-circle {
        // this circle should always be on the top
        z-index: 1;
        // Need this for IE to center align the cirlce
        right: 0;
        left: 0;

        // After setting the right and left to 0 for IE need to justify center with margin-left and margin-right set to 0 to center align the icon.
        .mdf-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;

          .vdl-avatar__initials {
            -ms-flex: 1 0 auto;
          }
        }

        //overWrite the panorama style per UX
        .vdl-avatar--md {
          width: 60px;
          height: 60px;
        }
      }

      .small-card-content {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 40px;
        // update margin left to all three
        // since padding is been applied on layoutitems
        padding-left: 0.5rem;

        &.mdf-cardBackground {
          margin: 0;
        }
      }
    }

    .small-card-bottom {
      height: 50px;
      // always allocate minimum 50px of height to the bottom card.
      min-height: 50px;
      // Approved by UX: truncate the bottom text if it bigger than 50px of height
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      &.mdf-cardBackground {
        margin: 0;
      }
    }

    label:not(.vdl-radio label, .vdl-checkbox label),
    .revolution .mdf label:not(.vdl-radio label, .vdl-checkbox label),
    .mdf-validated-field > label:not(.vdl-radio label, .vdl-checkbox label) {
      font-size: $font-size-h6;
      color: $neutral-darker;
      margin-bottom: 0;

      &.mdf-label-error {
        color: $accent-6;
      }
    }

    .no-margin {
      margin: 0;
    }
  }

  .gutterWidth-0 {
    padding: 0;
  }

  .gutterWidth-5 {
    padding: 5px;
  }

  .gutterWidth-10 {
    padding: 10px;
  }

  .gutterWidth-15 {
    padding: 15px;
  }

  .gutterWidth-20 {
    padding: 20px;
  }
}

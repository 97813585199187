@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.uploadRoot {
  background-color: $white;
}

.dropTargetStyle {
  background-color: $white;
  border: 2px dashed $neutral;
}

.dropTargetActiveStyle {
  background-color: $neutral-lightest;
  border: 3px dashed $accent-0;
}

.targetZone {
  text-align: center;
  cursor: pointer;
}

.dropZoneLabel {
  color: $neutral-darker;
  font-size: $font-size-medium;
  padding-top: 10px;
}

.placeHolderStyle {
  color: $neutral-darker;
  font-size: $font-size-small;
}

.fileIconClass {
  font-size: $icon-size-medium;
  color: $neutral-dark;
}

.uploadButtonStyle {
  width: 100%;
  margin-top: 10px;
  background-color: $neutral-light;
  border: 1px solid $neutral;
  color: $link-color;
  align-self: center;
}

sdf-button.uploadLabelStyle2,
.mdf sdf-button.uploadLabelStyle2 {
  margin: 0;
}

.uploadLabelStyle {
  text-transform: var(--button-text-transform);
  padding: 2px;
  font-weight: $font-weight-semibold;
  color: $link-color;
  text-decoration: underline;
  font-family: $font-family-base;
  cursor: pointer;
  font-size: $font-size-small;

  &.vdl-button--link {
    margin: 0;
  }
}

.dropZoneDisabled {
  padding-top: 10px;
  cursor: not-allowed;
  color: $neutral-dark;

  .fileSizeLabel {
    color: $neutral-dark;
  }

  &.vdl-button--link {
    color: $neutral-dark;
  }
}

.removeIcon {
  text-transform: var(--text-transform);
  border: none;
  background: none;
  font-size: $icon-size-medium;
  color: $link-color;
}

.uploadProgress {
  line-height: 0.5;

  .vdl-progress-bar__bar {
    border: 1px solid $neutral-light;
    margin: 0;
  }

  .vdl-progress-bar__percentage {
    height: 2px;
    background-color: $accent-1-dark;
  }

  &.uploadErrorProgress {
    .vdl-progress-bar__percentage {
      background-color: $accent-6;
    }
  }
}

.progressLabel {
  font-style: italic;
  color: $neutral-dark;
  text-align: left;
  flex-grow: 8;
  font-size: $font-size-small; // ~12px;

  &.progressLabelError {
    color: $accent-6
  }
}

.progressLabelIcon:before {
  content: "\f058";
  font-style: normal;
  font-family: "FontAwesome";
  display: inline-block;
  font-size: $icon-size-medium;
  padding: 2px;
  color: $accent-1-dark;
}

.fileSizeLabel {
  padding: 10px;
  color: $neutral-darker;
  font-size: $font-size-small;
}

.fileset {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.hideDiv {
  display: none;
}

.fileDetails {
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
}

.fileName {
  flex-grow: 8;
  color: $neutral-darker;
  font-size: $font-size-base;
}

.fileSize {
  float: right;
  flex-grow: 2;
  align-self: flex-end;
}

.removeButton {
  font-size: $icon-size-medium;
  color: $link-color;
  align-self: flex-end
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: 10px;
  width: 100%;
  height: 2px;
}

.mdf-mobile-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $white;
  border: 2px dashed $neutral-mid;
  padding: 20px;

  .mdf-mobile-file-upload-button-container {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .mdf-mobile-file-upload-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .mdf-mobile-file-upload-failed {
    color: $accent-6;
  }
}

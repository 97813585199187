@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.rtePlaceholderDialog .vdl-mdf-modal-content {
  margin-top: 20px;
  min-height: 100px
}

.rtePlaceholderDialog.rtePlaceholderDialog-tall .vdl-mdf-modal-content {
  min-height: 305px;
}

.insertPlaceholderContainer {
  display: flex;
  padding: 5px 0 14px;

  button {
    margin-left: 10px;
  }
}

.mdf .fr-box {
  .fr-counter, .fr-placeholder {
     color: $neutral-dark;
  }
}

.mdf .fr-view blockquote {
  border-color: inherit;
  color: inherit;
}

.fr-popup .fr-action-buttons button.fr-command,
.fr-trim-button {
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  text-shadow: none;
  text-transform: var(--button-text-transform);
  background-image: none;
  border: 0;
  border-radius: var(--button-border-radius);
  box-shadow: none;
  height: var(--button-height);
  min-height: var(--button-min-height);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--button-padding);
  color: var(--white);
  background-color: var(--button-primary-background);

  &[disabled] {
    background-color: var(--button-primary-disabled-background);
    color: var(--button-primary-disabled-color);
    -webkit-text-fill-color: var(--button-primary-disabled-color);
  }

  &:focus,
  &:active {
    &:not([disabled]) {
      box-shadow: var(--button-focus);
    }
  }

  &:hover {
    &:not([disabled]) {
      color: var(--white);
      background-color: var(--button-primary-hover);
    }
  }
}

.fr-desktop .fr-command:active,
.fr-desktop .fr-command:hover:not(.fr-table-cell),
.fr-desktop .fr-command:focus:not(.fr-table-cell),
.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell),
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active {
  background: var(--neutral-dark-mid);
}

.fr-toolbar .fr-command.fr-btn.fr-disabled {
  cursor: not-allowed;
}

.fr-desktop .fr-command.fr-disabled:hover {
  background: transparent;
}

.fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
  fill: var(--button-primary-background);
}

.fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  border: 2px solid var(--list-option-focus-border);
  background-color: var(--accent-0-lighter);

  &:hover {
    background: var(--neutral-dark-mid);
  }
}

.fr-popup .fr-table-size .fr-select-table-size>span.hover>span {
  border: 1px solid var(--list-option-focus-border);
  background-color: var(--accent-0-dark);
}

.fr-plugins-cancel {
  color: var(--button-secondary-text-color);
  background-color: var(--button-secondary-background);
  border: 2px solid var(--button-secondary-border-color);

  &[disabled] {
    background-color: var(--button-secondary-disabled-background);
    color: var(--button-secondary-disabled-color);
    -webkit-text-fill-color: var(--button-secondary-disabled-color);
    border: var(--button-disabled-border);
    box-shadow: var(--button-secondary-disabled-boxShadow);
  }

  &:focus,
  &:active {
    &:not([disabled]) {
      box-shadow: var(--button-focus);
    }
  }

  &:hover {
    &:not([disabled]) {
      background-color: var(--button-secondary-hover-background);
      border: 2px solid var(--button-secondary-hover-border);
      color: var(--button-secondary-hover-color);
    }
  }
}

.fr-popup .fr-input-line input[type='text'] {
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
  font-family: var(--font-family-base);
  font-weight: 400;
  font-size: var(--font-size-base);
  box-shadow: none;
  border-radius: var(--input-border-radius);
  outline: 0 none;
  padding: 10px;
  appearance: none;
  background-color: var(--white);
  border: var(--input-border);
  height: var(--form-input-height);
  box-sizing: border-box;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:-ms-input-placeholder {
    color: var(--neutral-dark);
    font-style: italic;
  }

  &::placeholder {
    color: var(--neutral-dark);
    font-style: italic;
  }

  &:focus {
    outline: 0;
    border: var(--input-border);
    box-shadow: var(--input-focus);

    &::placeholder {
      color: var(--neutral-dark);
    }
  }

  &:hover {
    border: var(--input-border-hover);
  }

  &[disabled] {
    background-color: var(--neutral-disabled);
    border: var(--input-border-disabled);
    color: var(--neutral-darker);
    cursor: not-allowed;

    &::placeholder {
      color: var(--neutral-dark);
    }
  }
}

.fr-popup .fr-input-line input+label,
.fr-popup .fr-input-line input.fr-not-empty+label {
  color: var(--neutral-dark);
}

.fr-popup .fr-checkbox input+span {
  border-radius: var(--checkbox-border-radius);
}

.fr-popup .fr-checkbox input:not(:checked):focus+span,
.fr-popup .fr-checkbox input:not(:checked)+span {
  border: var(--input-border);
}

.fr-popup .fr-checkbox input:checked:not([disabled])+span {
  background: var(--checkbox-background-color);
  border: 2px solid $input-border-focus;
}

.fr-popup .fr-checkbox input[type=checkbox]:focus+span {
  box-shadow: var(--checkbox-box-shadow);
}

.mdf .fr-popup .fr-color-set>span .fr-selected-color {
  line-height: 20px;
  top: 6px;
  bottom: auto;
  right: auto;
  left: 6px;
  background: var(--black);
  width: 20px;
  border-radius: 50%;
}


.mdf {
  .mdf-backdrop {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    z-index: 10000;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf .mdf-leftNav {
  width: 240px;
  background-color: $white;
  padding-top: 20px;

  .vdl-left-nav__item:first-child, .vdl-left-nav__item {
    border: 0;
    background-color: transparent;
    &--collapsed {
      // add
      color: transparent;
    }
  }

  .vdl-left-nav__item__label:focus-visible {
    .vdl-left-nav__item__expand-icon {
      fill: $white;
    }
  }

  .vdl-left-nav__item__expand-icon {
    fill: $link-color;
    font-size: $font-size-h4;
    cursor: pointer;
  }

  &--labelIcon {
    padding: 2px;
  }

  // Target only header label with parent that has children.
  .vdl-left-nav__item {
    &.vdl-left-nav__item--has-children.vdl-left-nav__item--disabled > label {
      color: $neutral-dark-mid; //aligned with Waypoint gray-500 and text-disabled
      cursor: not-allowed;
    }

    &.vdl-left-nav__item--has-children > label {
      font-size: $font-size-h4;
      font-weight: $font-weight-semibold;
      color: $black;
      text-transform: capitalize;
    }
  }

   .vdl-left-nav__item > .vdl-left-nav__item__label {
     min-height: 40px;
     display: flex;
     align-items: center;
     border-radius: 0;
     border-left: 5px solid $white;
     font-size: $font-size-base;
     font-weight: $font-weight-base;
   }

   .vdl-left-nav__item {
     &--selected {
       .vdl-left-nav__item__label {
         background-color: $neutral-lighter;
         border-left: 5px solid $white;
         color: $accent-0-darker;
         // calculate width subracting the arrow size.
         width: calc(100% - 15px);

         &--isVisited {
           border-left: 5px solid $accent-0-darker;
         }
       }

       .selectItemArrow {
         &:before, &:after {
           height: 50%;
           right: -15px;
           position: absolute;
           left: 100%;
           content: '';
         }

         &:before {
           top: 0;
           background: linear-gradient(to right top, $accent-0-dark 50%, transparent 50%);
         }

         &:after {
           top: 50%;
           background: linear-gradient(to right bottom, $accent-0-dark 50%, transparent 50%);
         }
       }
     }
   }

  .vdl-left-nav__item__scrollbox {
    padding-top: 5px;

    // item labels.
    .vdl-left-nav__item__label {
      font-size: $font-size-h5;
      font-weight: $font-weight-base;
      color: $neutral-darker;
      text-transform: var(--text-transform);
      border-left: 5px solid $white;

      &--isVisited {
        border-left: 5px solid $accent-0-dark;
      }
    }

    .vdl-left-nav__item--disabled {
      .vdl-left-nav__item__label{
        color: $neutral-dark-mid; //aligned with Waypoint gray-500 and text-disabled
        cursor: not-allowed;

        &--isVisited {
          border-left: 5px solid $neutral-dark;
        }
      }
    }
  }
}

.mdf .mdf-leftNavContainer {
  display: flex;
  position: relative;

  .collapse-control {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: $white;
    border-bottom-right-radius: 10px;
    text-align: center;
    padding-top: 5px;
    cursor: pointer;

    i {
      color: $link-color;
      font-size: $font-size-h4;
    }

    &--open {
      left: 241px;
    }

    &--closed {
      left: 0;
    }

    &:focus {
      border: 2px solid $input-border-focus;
      outline: 0;
      z-index: 1;
      border-radius: 8px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  // Called when collapsing
  &.nav-collapsed {
    display: none;
  }

  &.nav-expand {
    display: block;
  }
}

// AG Grid style overrides to fix various issues both on the AG Grid side and the Waypoint side
.mdf.one-ux .ag-theme-adp-32,
.mdf .ag-theme-adp-32,
.vdl .mdf .ag-theme-adp-32,
.mdf.one-ux .ag-theme-adp-32-1,
.mdf .ag-theme-adp-32-1,
.vdl .mdf .ag-theme-adp-32-1 {
  // New accessibility container added in v31.1.0 by AgGrid
  .ag-aria-description-container {
    z-index: 9999;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
  }

  .ag-cell {
    // Stop using line-height to center text in the AgGrid.
    // Let normal line-height and top/bottom padding do it.
    line-height: var(--line-height-base);
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    &.ag-cell-not-inline-editing.ag-cell-auto-height {
      // The Waypoint team sets the line height to the base row height so that
      // cell values are vertically centered. This doesn't work for auto-height cells.
      line-height: var(--line-height-base);

      // Added overflow hidden to remove the misalignment of pinned right column header in Windows.
      overflow: hidden;

      // Makes the ag-cell-wrapper height 0 so that it won't push custom renderers out of
      // the cell if the column definition sets autoHeight to true. Also, add padding to
      // match the top of the custom renderer with the top of the text in a "normal" cell.
      .ag-cell-wrapper {
        height: unset;

        .wfn-cell-padding {
          padding-top: 0.625rem;
        }
      }
    }

    // Make sure the cell editor fills the cell vertically.
    &.ag-cell-normal-height {
      height: 100%;
    }

    .ag-cell-wrapper {
      // AgGrid 27.1 wraps custom content with a span with this style. Have to force the width
      // otherwise the content won't fill the cell.
      .ag-cell-value {
        width: 100%;
      }

      // Fix the selection checkbox positioning given the above line-height and top-padding.
      > :not(.ag-cell-value):not(.ag-group-value) {
        align-items: flex-start;
        padding-top: 0.125rem;
      }
    }

    // Add a border to show a cell status of 'error' while the cell is not in edit mode.
    &.ag-cell-not-inline-editing {
      &.wfn-cell-status-error {
        outline: var(--input-border-invalid);
        border-radius: var(--input-border-radius);
        outline-offset: -4px;
      }
    }
  }

  // Makes sure the cells are tall enough when the editors are in use.
  // This overrides the default of 42px, which is not tall enough for Waypoint editors.
  .ag-cell-inline-editing {
    background-color: var(--white);
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ag-cell-normal-height {
    height: 100%;
  }

  // Centers the group headings over column groups
  .ag-header-group-cell-label {
    justify-content: center;
  }

  .ag-row-selected {
    background-color: var(--ag-selected-row-background-color, var(--theme-hover-color, #d8e5f9));
  }

  // Added to overwrite inline styles that cause an issue with floating filter
  .ag-floating-filter {
    display: inline-flex;
  }

  .ag-header-cell {
    &:focus::after {
      background-color: transparent;
      border: 1px solid;
      border-color: var(--ag-input-focus-border-color);
      content: "";
      display: block;
      height: calc(100% - 8px);
      left: 4px;
      pointer-events: none;
      position: absolute;
      top: 4px;
      width: calc(100% - 8px);
    }
  }

  // Overwrite Waypoint team style (based on AgGrid 28)
  .ag-header-cell-text {
    white-space: inherit;
  }

  // New class added to the column header outermost div to allow wrapping (based on AgGrid 28)
  .ag-header-cell-wrap-text {
    .ag-header-cell-comp-wrapper {
      white-space: normal;
    }
  }

  .ag-cell-label-container {
    // Overwrite Waypoint team's style (based on AgGrid 28)
    height: initial;

    // Adds padding to the header cell container (based on AgGrid 28)
    padding: 5px 0 5px 0;
  }

  // Makes the header cell width 100% so that overflow becomes ellipsis (based on AgGrid 28)
  .ag-header-cell-comp-wrapper {
    width: 100%;
    overflow: hidden;
  }

  // Stops words from breaking when wrapText is enabled in column definition.
  .ag-cell-wrap-text {
    word-break: normal;
  }

  // Removes minimum height of 150px for AgGrid
  &.ag-disable-min-height {
    .ag-center-cols-viewport {
      min-height: unset;
    }
  }

  // Change minimum height to 72px based on waypoint UX Design.
  .ag-center-cols-viewport {
    min-height: 72px;
  }

  &.ag-wfn-focused:focus {
    outline: 0;
    box-shadow: var(--input-focus);
  }
}

.mdf .ag-theme-adp-32,
.vdl .mdf .ag-theme-adp-32,
.mdf .ag-theme-adp-32-1,
.vdl .mdf .ag-theme-adp-32-1 {
  // Makes sure the cells are tall enough when the editors are in use.
  // This overrides the default of 42px, which is not tall enough for Waypoint editors.
  .ag-cell-inline-editing {
    background-color: var(--white);
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Undo the default row striping until the design system catches up
.mdf.one-ux .ag-theme-adp-32.no-stripes,
.mdf .ag-theme-adp-32.no-stripes,
.vdl .mdf .ag-theme-adp-32.no-stripes,
.mdf.one-ux .ag-theme-adp-32-1.no-stripes,
.mdf .ag-theme-adp-32-1.no-stripes,
.vdl .mdf .ag-theme-adp-32-1.no-stripes {
.ag-row {
    background-color: var(--white);
  }

  .ag-row-selected {
    background-color: var(--ag-selected-row-background-color, #e8effa);
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf-time-frame {
  @keyframes mdf-time-frame-slide-in-top {
    0% {
      transform: translate(0, -100%)
    }
  }

  @keyframes  mdf-time-frame-slide-out-bottom {
    100% {
      transform: translate(0, 110%);
    }
  }

  @keyframes  mdf-time-frame-slide-out-right {
    100% {
      transform: translate(500%, 0);
    }
  }

  &-slide-in-top {
    animation:  mdf-time-frame-slide-in-top 1s ease-in;
    animation-fill-mode: forwards;
  }

  &-slide-out-bottom {
    animation:  mdf-time-frame-slide-out-bottom 0s ease-out;
    animation-fill-mode: forwards;
  }

  &-slide-out-right {
    animation:  mdf-time-frame-slide-out-right 1s;
    animation-fill-mode: forwards;
  }

  &__frame-wrapper--frame-animating {
    overflow: hidden;
  }

  &__main-content-container {
    margin-left: 40px;
    background: $white;
    padding: 0 10px 15px 40px;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    border-top: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__frame-timeline-container {
    display: flex;
    align-items: center;
    height: 60px;
    position: relative;
    left: 17px;
  }

  &__timeline-wrapper {
    display: flex;
    flex-grow: 1;
    height: inherit;
    overflow: hidden;
    align-items: center;

    &--timeline-open {
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
  }

  &__dates-container {
    transform: translate(-100%);
    transition: all 1s;
    z-index: 0;

    &--slide-in-left {
      transform: translate(0);
    }
  }

  &__open-timeline-link {
    margin-left: 5px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 40px;
    color: $accent-0-dark;
    font-weight: 600;
    font-size: 13px;

    .fa-chevron-right {
      margin-left: 2px;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__timeline-line {
    height: 0;
    display: flex;
    justify-content: flex-end;
    border: 1px dashed $accent-0-dark;
    margin-right: 35px;
    position: relative;
    top: 14px;
    z-index: -1;
  }

  &__dates {
    display: flex;
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $accent-0-dark;
    border-bottom: 2px solid transparent;
    margin-left: 30px;

    .mdf-time-frame__timeline-point {
      height: 25px;
      width: 25px;
      border: 2px solid;
      background: white;
      border-radius: 50%;
    }

    &--point:hover {
      cursor: pointer;
      border-bottom: 2px solid $accent-0-dark;

      .mdf-time-frame__timeline-point {
        border-width: 3px;
      }
    }

    &--temp {
      pointer-events: none;

      .mdf-time-frame__timeline-point {
        border-style: dotted;
      }
    }

    &--selected {
      font-weight: bold;
      pointer-events: none;
      cursor: default;

      .mdf-time-frame__timeline-point {
        border-width: 3px;
        background-color: $accent-0-dark;
        border-color: white;
      }
    }

    .mdf-time-frame__point-label {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__header-wrapper {
    display: flex;
    height: 40px;
    background: $white;
    border-top-left-radius: 30px;
    border-top-right-radius: 5px;
    margin-right: 10px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  }

  &__header {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
  }

  &__header-icon {
    background: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid $accent-0-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $accent-0-dark;
    font-size: 40px;
    z-index: 1;
  }

  &__circles-connector {
    width: 0;
    height: 20px;
    border-left: 4px solid $accent-0-dark;
    position: absolute;
    margin-top: 40px;
    left: 18px;
    top: 0;
  }

  &__calendar-container {
    display: flex;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    align-self: flex-start;
    border: 2px solid $accent-0-dark;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $accent-0-dark;

    &--timeline-open {
      cursor: pointer;

      &:hover {
        background-color: white;
      }
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.wfnx {
  .wfnx-popup-dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3000;
  }

  .wfnx-popup-dialog-left {
    position: absolute;
    top: 50px;
    right: 110px;
    width: fit-content;
    height: fit-content;
    margin: 20px;
    z-index: 3001;

    .popup-dialog-content {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
      padding: 15px;
      background-color: $white;
    }

    .popup-dialog-arrow {
      position: absolute;
      top: -15px;
      right: 20px;
      width: 15px;
      height: 15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid $neutral-lightest;
    }

    .popup-dialog-header {
      margin-bottom: 20px;

      .popup-dialog-title {
        font-size: $font-size-h2;
        font-weight: $font-weight-light;
        display: inline-block;
        width: 90%;
      }

      .popup-dialog-close {
        font-size: $font-size-h2;
        display: inline-block;
        width: 10%;
        text-align: right;

        i {
          cursor: pointer;
        }
      }
    }
  }

  .wfnx-popup-dialog {
    position: absolute;
    top: 20px;
    left: 110px;
    width: fit-content;
    height: fit-content;
    margin: 20px;
    z-index: 3001;

    .popup-dialog-content {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
      padding: 15px;
      background-color: $white;
    }

    .popup-dialog-arrow {
      position: absolute;
      top: -15px;
      left: 20px;
      width: 15px;
      height: 15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid $neutral-lightest;
    }

    .popup-dialog-header {
      margin-bottom: 20px;

      .popup-dialog-title {
        font-size: $font-size-h2;
        font-weight: $font-weight-light;
        display: inline-block;
        width: 90%;
      }

      .popup-dialog-close {
        font-size: $font-size-h2;
        display: inline-block;
        width: 10%;
        text-align: right;

        i {
          cursor: pointer;
        }
      }
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.wfnx .mdf-snackbar {
  height: 60px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  background-color: $neutral-darker;
  box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.25);
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-Phone {
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: top;

  .PhoneInput {
    display: flex;

    ::placeholder {
      color: $neutral-dark;
      font-style: italic;
    }

    .PhoneInputCountrySelectArrow {
      display: block;
      content: '';
      width: 0.3em;
      height: 0.3em;
      margin-left: 0.35em;
      border-style: solid;
      border-color: inherit;
      border-top-width: 0;
      border-bottom-width: 1px;
      border-left-width: 0;
      border-right-width: 1px;
      transform: rotate(45deg);
      opacity: 0.45;
    }

    // Style flag component when it is within the phone component.
    .PhoneInputCountryIcon {
      width: 1.24em;
      height: 0.93em;
      box-sizing: content-box;
      border: 1px solid rgba(0, 0, 0, 0.5);
      display: flex;
    }
  }

  &.ext .vdl-textbox {
    width: 76px;
  }

  &.dial {
    width: 220px;
    margin-right: 5px;
  }

  &-readOnly {
    display: flex;
    align-items: center;
  }

  &-icon {
    color: $neutral-dark;
    font-size: 2em;
  }

  &-input {
    color: $accent-0-dark;
    font-size: 1.45em;
    padding-left: 10px;
    padding-bottom: 5px;
  }

  & input.PhoneInputInput {
    display: inline-block;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    box-shadow: none;
    padding: 6px 10px;
    background-color: $white;
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    height: var(--form-input-height);
    width: 100%;
    box-sizing: border-box;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  & input[disabled].PhoneInputInput {
    border: var(--input-border-disabled);
    background-color: $neutral-disabled;
    color: $neutral-dark;
    cursor: not-allowed;
  }

  &.vdl-validation-error input.PhoneInputInput {
    border: var(--input-border-invalid);
    color: $color-status-error;
  }

  // OverWrite this style to remove the border-bottom
  & button.rrui__select__button {
    transition: border 0.1s;
    border: none;

    // need to match the curent style after phone version change
    background-color: transparent;

    // Because Chrome adds `text-align: center` for all buttons
    text-align: left;
    padding-left: 2px;
  }

  input:focus {
    border: 2px solid $input-border-focus;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($input-border-focus, 0.6);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  // focus outline for country dropdown
  .rrui__input-field:focus,
  .rrui__select__button:focus,
  .rrui__select__native:focus + .rrui__select__button,
  .rrui__select__native:focus {
    border: 2px solid $input-border-focus;
    border-radius: var(--input-border-radius);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($input-border-focus, 0.6);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .rrui__select {
    position: relative;
    user-select: none;
    left: -2px;
  }

  .rrui__select {
    .rrui__options-list {
      ul {
        margin-bottom: 0;
      }
    }
  }
}

// When Flag component in not used within phone component this style will be applied.
.react-phone-number-input__icon {
  box-sizing: content-box !important;
  width: 1.24em;
  height: 0.93em;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
}

.react-phone-number-input__icon-image {
  flex: 0 0 100%;
  display: block;
  // for Icon to work in IE
  max-height: 100%;
  max-width: 100%;
}

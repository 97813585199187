@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

@import '_smallCard.scss';

.mdf {
  .mdf-section-style {
    display: flex;
    flex-direction: column;

    .mdf-section-header-panel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 auto;

      &.mdf-cardLineBorder {
        border-bottom: 1px solid $neutral-mid;
      }
    }

    .mdf-section-content, .mdf-section-header {
      display: flex;
      width: 100%;
    }

    .section-collapsible-style {
      margin-right: 10px;

      &:hover, &:focus {
        outline: 2px solid $input-border-focus;
        background-color: $outline-bg-color;
        color: var(--link-color);
      }
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

@use 'sass:math';

$status-ring-sm-width: 40px;
$status-ring-smd-width: 48px;
$status-ring-md-width: 70px;
$status-ring-lg-width: 100px;
$status-ring-xl-width: 125px;

$status-badge-sm-width: 15px;
$status-badge-smd-width: 15px;
$status-badge-md-width: 22px;
$status-badge-lg-width: 25px;
$status-badge-xl-width: 30px;

$status-ring-border-thin: 2px;
$status-ring-border-base: 3px;

@mixin circle {
  border-radius: 50%;
  overflow: hidden;
}

@mixin status-badge-props($color) {
  background-color: $white;
  position: relative;
  z-index: 1;
  color: $color;
}

@mixin status-ring-props {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

@mixin status-container-constraints($size) {
  @if $size == sm {
    max-width: $status-ring-sm-width;
    max-height: $status-ring-sm-width;
  }
  @else if $size == smd {
    max-width: $status-ring-smd-width;
    max-height: $status-ring-smd-width;
  }
  @else if $size == md {
    max-width: $status-ring-md-width;
    max-height: $status-ring-md-width;
  }
  @else if $size == lg {
    max-width: $status-ring-lg-width;
    max-height: $status-ring-lg-width;
  }
  @else {
    max-width: $status-ring-xl-width;
    max-height: $status-ring-xl-width;
  }
}

@mixin status-ring-specs($size, $color) {
  @if $size == sm {
    width: $status-ring-sm-width;
    height: $status-ring-sm-width;
    border: $status-ring-border-thin solid $color;
    top: -34px;
  }
  @else if $size == smd {
    width: $status-ring-smd-width;
    height: $status-ring-smd-width;
    border: $status-ring-border-base solid $color;
    top: -5px;
  }
  @else if $size == md {
    width: $status-ring-md-width;
    height: $status-ring-md-width;
    border: $status-ring-border-base solid $color;
    top: -5px;
  }
  @else if $size == lg {
    width: $status-ring-lg-width;
    height: $status-ring-lg-width;
    border: $status-ring-border-base solid $color;
    top: -1px;
  }
  @else {
    width: $status-ring-xl-width;
    height: $status-ring-xl-width;
    border: $status-ring-border-base solid $color;
  }
}

@mixin status-badge($size) {
  display: inline-block;

  /* badge sm is not shown */
  @if $size == smd {
    border-radius: math.div($status-badge-smd-width, 2);
  }
  @else if $size == md {
    border-radius: math.div($status-badge-md-width, 2);
  }
  @else if $size == lg {
    border-radius: math.div($status-badge-lg-width, 2);
  }
  @else {
    border-radius: math.div($status-badge-xl-width, 2);
  }
}

@mixin set-truncate-max-width($size) {
  @if $size == smd {
    max-width: $status-ring-smd-width;
  }
  @else if $size == md {
    max-width: $status-ring-md-width;
  }
  @else if $size == lg {
    max-width: $status-ring-lg-width;
  }
  @else {
    max-width: $status-ring-xl-width;
  }
}

@mixin status-badge-specs($size, $color, $expand) {
  @if $size == sm {
    @if $expand == '' {
      width: $status-badge-sm-width;
    }
    @else {
      width: auto;
    }

    height: $status-badge-sm-width;
    border: 2px solid $color;
    top: -17px;
    left: 26px;
  }

  @if $size == smd {
    @if $expand == '' {
      width: $status-badge-smd-width;
    }
    @else {
      width: auto;
    }

    height: $status-badge-smd-width;
    border: 2px solid $color;
    top: -17px;
    left: 26px;
  }
  @else if $size == md {
    @if $expand == '' {
      width: $status-badge-md-width;
      left: 48px;
    }
    @else {
      width: auto;
      left: 30px;
    }

    height: $status-badge-md-width;
    border: 2px solid $color;
    top: -23px;
  }

  @else if $size == lg {
    @if $expand == '' {
      width: $status-badge-lg-width;
      height: $status-badge-lg-width;
      left: 71px;
    }
    @else {
      width: auto;
      height: auto;
      left: 50px;
    }

    border: 2px solid $color;
    top: -28px;
  }

  @else {
    @if $expand == '' {
      width: $status-badge-xl-width;
      left: 90px;
    }
    @else {
      width: auto;
      left: 60px;
    }

    height: $status-badge-xl-width;
    border: 2.5px solid $color;
    top: -34px;
  }
}

@mixin status-badge-font-size($size) {
  @if $size == sm {
    > * {
      font-size: 6px;
    }
  }
  @else if $size == smd {
    > * {
      font-size: 6px;
    }
  }
  @else if $size == md {
    > * {
      font-size: 10px;
    }
  }
  @else if $size == lg {
    > * {
      font-size: 11px;
    }
  }
  @else {
    > * {
      font-size: 14px;
    }
  }
}

@mixin status-badge-content($size, $color) {
  @include status-badge($size);
  @include status-badge-props($color);

  .status-badge-content {
    @include status-badge-font-size($size);

    font-weight: $font-weight-semibold;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    > .truncate-text {
      @include set-truncate-max-width($size);

      width: inherit;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @if $size == sm {
    display: none;
  }
}

@mixin vdl-status-ring($color: $primary, $textColor: $black, $size: sm, $id: primary) {
  .vdl-status-ring--#{$size}-#{$id} {
    border: 2px solid transparent;

    &:focus {
      border: 2px solid $input-border-focus;
      border-radius: 50%;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    @if $size == xl {
      &:focus {
        max-width: 250px;
        max-height: 130px;
      }
    }

    @if $size == lg {
      &:focus {
        max-width: 120px;
        max-height: 104px;
     }
    }

    @if $size == md {
      &:focus {
        max-width: 150px;
        max-height: 73px;
      }
    }

    @if $size == smd {
      &:focus {
        max-width: 60px;
        max-height: 52px;
      }
    }

    @if $size == sm {
      &:focus {
        max-width: 50px;
        max-height: 45px;
      }
    }

    @include status-container-constraints($size);

    > .status-ring-container {
      > .status-ring {
        @include circle;
        @include status-ring-props;
        @include status-ring-specs ($size, $color);

        > * {
          width: 100%;
          height: 100%;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 125px;
      }

      .font-size-xl {
        font-size: 48px;
      }

      .font-size-lg {
        font-size: 32px;
      }

      .text-size-lg {
        font-size: 28px;
      }

      .expanded-rings {
        > *:last-child {
          margin-left: 70px;
        }
      }
    }

    /* standard badge class (for text with 4 3 chars or less) */
    > .status-badge {
      @include status-badge-specs ($size, $color, '');
      @include status-badge-content($size, $color);
    }

    /* a variation of the badge class that allows for stretching */
    > .status-badge-flexible {
      padding: 0 4px;

      @include status-badge-specs($size, $color, 'expand');
      @include status-badge-content($size, $color);
    }

    .vdl-status-ring-bg-color {
      background-color: $color;
    }

    .vdl-status-badge-bg-color {
      background-color: $color;

      > .status-badge-content {
        color: $textColor;
      }
    }

    /* a variation of the badge class specifically for icon content
     * (font-awesome fonts don't center well in circles - this uses a stack/concentric
     * method to center badges that have icon content)
     */
    .vdl-badge-circle-stack {
      @if $size == sm {
        display: none !important;
      }
      @else if $size == smd {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        top: -23px;
        left: 29px;
      }
      @else if $size == md {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 28px;
        top: -27px;
        left: 45px;
      }
      @else if $size == lg {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 30px;
        left: 70px;
        top: -32px;
      }
      @else {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 36px;
        left: 85px;
        top: -35px;
      }

      position: relative;
      display: inline-block;
      vertical-align: middle;

      > .vdl-badge-circle-background {
        color: $color;
        text-align: center;
        line-height: inherit;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;

        @if $size == sm {
          display: none !important;
        }

        @else if $size == smd {
          font-size: 24px;
        }

        @else if $size == md {
          font-size: 28px;
        }

        @else if $size == lg {
          font-size: 30px;
        }

        @else {
          font-size: 36px;
        }
      }

      > .vdl-badge-circle-foreground {
        color: $white;
        text-align: center;
        line-height: inherit;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;

        @if $size == sm {
          display: none;
        }

        @else if $size == smd {
          font-size: 20px;
        }

        @else if $size == md {
          font-size: 22px;
        }

        @else if $size == lg {
          font-size: 24px;
        }

        @else {
          font-size: 30px;
        }
      }

      > .vdl-badge-foreground-filled {
        color: $color;
      }

      > .vdl-badge-icon {
        line-height: inherit;
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        color: $color;

        @if $size == sm {
          display: none !important;
        }
        @else if $size == smd {
          font-size: 10px;
        }
        @else if $size == md {
          font-size: 10px;
        }
        @else if $size == lg {
          font-size: 14px;
        }
        @else {
          font-size: 16px;
        }
      }

      > .vdl-badge-font-color {
        color: $textColor;
      }
    }
  }
}

.status-ring .bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  > * {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}

$CORE_COLOR_MAP: (
  'neutral': (
    'lightest': var(--neutral-lightest),
    'lighter': var(--neutral-lighter),
    'light': var(--neutral-light),
    'mid': var(--neutral-mid),
    'base': var(--neutral),
    'dark': var(--neutral-dark),
    'darker': var(--neutral-darker)
  ),
  'accent-0': (
    'lightest': var(--accent-0-lightest),
    'lighter': var(--accent-0-lighter),
    'light': var(--accent-0-light),
    'base': var(--accent-0),
    'dark': var(--accent-0-dark),
    'darker': var(--accent-0-darker)
  ),
  'accent-1': (
    'lightest': var(--accent-1-lightest),
    'lighter': var(--accent-1-lighter),
    'light': var(--accent-1-light),
    'base': var(--accent-1),
    'dark': var(--accent-1-dark),
    'darker': var(--accent-1-darker)
  ),
  'accent-2': (
    'light': var(--accent-2-light),
    'base': var(--accent-2),
    'dark': var(--accent-2-dark),
    'darker': var(--accent-2-darker)
  ),
  'accent-3': (
    'light': var(--accent-3-light),
    'base': var(--accent-3),
    'dark': var(--accent-3-dark),
    'darker': var(--accent-3-darker)
  ),
  'accent-4': (
    'light': var(--accent-4-light),
    'base': var(--accent-4),
    'dark': var(--accent-4-dark),
    'darker': var(--accent-4-darker)
  ),
  'accent-5': (
    'lightest': var(--accent-5-lightest),
    'lighter': var(--accent-5-lighter),
    'light': var(--accent-5-light),
    'base': var(--accent-5),
    'dark': var(--accent-5-dark),
    'darker': var(--accent-5-darker)
  ),
  'accent-6': (
    'lightest': var(--accent-6-lightest),
    'lighter': var(--accent-6-lighter),
    'light': var(--accent-6-light),
    'base': var(--accent-6),
    'dark': var(--accent-6-dark)
  ),
  'accent-7': (
    'light': var(--accent-7-light),
    'base': var(--accent-7),
    'dark': var(--accent-7-dark),
    'darker': var(--accent-7-darker)
  )
) !default;

$CORE_TEXT_MAP: (
  'neutral': (
    'lightest': var(--black),
    'lighter': var(--black),
    'light': var(--black),
    'mid': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  ),
  'accent-0': (
    'lightest': var(--black),
    'lighter': var(--black),
    'light': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  ),
  'accent-1': (
    'lightest': var(--black),
    'lighter': var(--black),
    'light': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  ),
  'accent-2': (
    'light': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  ),
  'accent-3': (
    'light': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  ),
  'accent-4': (
    'light': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  ),
  'accent-5': (
    'lightest': var(--black),
    'lighter': var(--black),
    'light': var(--black),
    'base': var(--black),
    'dark': var(--black),
    'darker': var(--white)
  ),
  'accent-6': (
    'lightest': var(--black),
    'lighter': var(--black),
    'light': var(--black),
    'base': var(--white),
    'dark': var(--white)
  ),
  'accent-7': (
    'light': var(--black),
    'base': var(--black),
    'dark': var(--white),
    'darker': var(--white)
  )
) !default;

@function core-get-color-spectrum-base($color-spectrum-map, $color-variant-key-base: 'base') {
  $base-color: map-get($color-spectrum-map, $color-variant-key-base);

  @if $base-color {
    @if (type-of($base-color) != 'color') {
      @error ('CORE-GET-COLOR-SPECTRUM-BASE() ERROR 4: Base color definition for each color-spectrum must be of CSS color type! Base color definition provided was: #{$base-color}');
    }
    @else {
      // @debug 'CORE-GET-COLOR-SPECTRUM-BASE OUTPUT: $base-color: #{$base-color}';
      @return $base-color;
    }
  }
  @else {
    @error ('CORE-GET-COLOR-SPECTRUM-BASE() ERROR 4.1: Base color definition is required for each color spectrum! No base color definition found for: #{inspect($color-spectrum-map)}');
  }
}

@function core-color($color-name: 'neutral', $color-variant: 'base') {
  $CORE_COLOR_DEFAULT_VARIANT_MAP: (
    'lightest',
    'lighter',
    'light',
    'mid',
    'base',
    'dark',
    'darker'
  ) !default;

  $color: var(--neutral);
  $color-spectrum-map: map-get($CORE_COLOR_MAP, $color-name);

  @if $color-spectrum-map {
    $color: map-get($color-spectrum-map, $color-variant);
  }

  @return $color;
}

@function text-color($color-name: 'neutral', $color-variant: 'base') {
  $CORE_COLOR_DEFAULT_VARIANT_MAP: (
    'lightest',
    'lighter',
    'light',
    'mid',
    'base',
    'dark',
    'darker'
  ) !default;

  $color: var(--black);
  $color-spectrum-map: map-get($CORE_TEXT_MAP, $color-name);

  @if $color-spectrum-map {
    $color: map-get($color-spectrum-map, $color-variant);
  }

  @return $color;
}

@each $colorMapName, $mapColors in $CORE_COLOR_MAP {
  @include vdl-status-ring(core-color($colorMapName), text-color($colorMapName), sm, $colorMapName);
  @include vdl-status-ring(core-color($colorMapName), text-color($colorMapName), smd, $colorMapName);
  @include vdl-status-ring(core-color($colorMapName), text-color($colorMapName), md, $colorMapName);
  @include vdl-status-ring(core-color($colorMapName), text-color($colorMapName), lg, $colorMapName);
  @include vdl-status-ring(core-color($colorMapName), text-color($colorMapName), xl, $colorMapName);

  @each $mapColorVariation, $colorValue in $mapColors {
    @include vdl-status-ring($colorValue, text-color($colorMapName, $mapColorVariation), sm, $colorMapName + '-' + $mapColorVariation);
    @include vdl-status-ring($colorValue, text-color($colorMapName, $mapColorVariation), smd, $colorMapName + '-' + $mapColorVariation);
    @include vdl-status-ring($colorValue, text-color($colorMapName, $mapColorVariation), md, $colorMapName + '-' + $mapColorVariation);
    @include vdl-status-ring($colorValue, text-color($colorMapName, $mapColorVariation), lg, $colorMapName + '-' + $mapColorVariation);
    @include vdl-status-ring($colorValue, text-color($colorMapName, $mapColorVariation), xl, $colorMapName + '-' + $mapColorVariation);
  }
}

@include vdl-status-ring(var(--white), var(--black), sm, "white");
@include vdl-status-ring(var(--white), var(--black), smd, "white");
@include vdl-status-ring(var(--white), var(--black), md, "white");
@include vdl-status-ring(var(--white), var(--black), lg, "white");
@include vdl-status-ring(var(--white), var(--black), xl, "white");
@include vdl-status-ring(var(--black), var(--white), sm, "black");
@include vdl-status-ring(var(--black), var(--white), smd, "black");
@include vdl-status-ring(var(--black), var(--white), md, "black");
@include vdl-status-ring(var(--black), var(--white), lg, "black");
@include vdl-status-ring(var(--black), var(--white), xl, "black");

// Wanted to use 'initial', but IE11 doesn't support it. Using 'auto' instead.
.mdf .vdl-button--primary,
.mdf .vdl-button--secondary,
.mdf .vdl-button.vdl-button--primary,
.mdf .vdl-button.vdl-button--secondary,
.vdl-button--primary,
.vdl-button--secondary,
.vdl-button.vdl-button--primary,
.vdl-button.vdl-button--secondary {
  min-width: auto;
}

// Firefox doesn't pass down the value of text-decoration
.mdf .vdl-button .vdl-button__container,
.vdl-button .vdl-button__container {
  text-decoration: inherit;
}

.vdl-button:not(.vdl-button--icon) {
  .vdl-button__icon {
    padding-right: 3px;
  }
}

// If a link button is in a label, remove the margin and padding so that it doesn't cause the label to increase in size vertically.
.mdf {
  label > .vdl-button.vdl-button--link,
  label span.mdf-simple-help-icon-container > .vdl-button.vdl-button--link {
    padding: 0 0 0 5px;
    margin: 0;
  }

  // Provide margin to buttons that are next to each other in a row
  // but only when directly in a div so that we limit the scope of the selector
  div:not([class*="flex"]) {
    > .vdl-button {
      + sdf-button {
        margin-left: 1rem;
      }
    }

    > sdf-button {
      + .vdl-button {
        margin-left: 1rem;
      }
    }

    > sdf-button {
      + sdf-button {
        margin-left: 1rem;
      }
    }
  }

  // Provide margin that mimics the the old VDL Button link style
  // that still has the padding/margin to make the "link" button
  // have the same external dimensions as a contained button.
  .mdf-button-compatibility {
    margin: 9px 15px;
  }
}

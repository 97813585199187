@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.wfnx {
  .wfnx-small-card {
    display: inline-block;
    position: relative;
    width: 260px;
    border-radius: 15px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
    background-color: $white;
    margin: 10px;
    padding-top: 15px;

    &-items {
      margin-top: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.vdl-segment-filler {
  &__container {
    display: grid;
    grid-gap: 0.25rem;
    grid-auto-flow: column;
    grid-template-rows: 0.5rem;
    background-color: transparent;

    .vdl-segment-filler__filled {
      border-radius: 0.5rem;
      border: 1px solid $primary;
      background-color: $primary;
    }

    .vdl-segment-filler__empty {
      border-radius: 0.5rem;
      border: 1px solid $neutral-darker;
      background-color: transparent;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/form' as *;

.mdf-searchbox {
  &-container {
    position: relative;
    display: flex;
    align-items: stretch;

    // overwrite mdf fa class.
    .fa-search {
      display: flex;
    }

    >input[data-id] {
      padding-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
     }
  }

  &-input {
    margin-bottom: 0;

    & input {
      border-width: 0;
      background-color: $neutral-lightest;

      &::placeholder {
        font-style: normal;
      }
    }
  }

  &-close {
    padding: 4px;
    position: absolute;
    right: 0.95em;
    font-size: 1.8em;
    color:gray !important;
    line-height: var(--line-height-computed);
    width: 28px;
    text-align: center;

    &-icon:before {
        content: '\00d7';
    }

    &--disabled {
      color: $neutral-dark;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  // When changing alignment of searchicon test it with text the x icon might get misaligned.
  &-searchicon {
    color: $accent-0-dark;
    font-size: 1.25em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    height: $form-input-height;
    width: 28px;
    border: 2px solid transparent;
    cursor: pointer;

    &:focus {
      border: 2px solid $input-border-focus;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    &--disabled {
      color: $neutral-dark;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.mdf-infinitesearch {
  &-list{
    div {
      margin-left: 0;
    }
  }

  &-container {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  &-list {
    flex: 1
  }
}

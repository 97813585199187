@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf {
  .rule {
    &-card {
      margin-bottom: 20px;
    }

    &-container {
      margin-left: -5px;

      .rule-row-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px
      }

      @media screen and (max-width:530px) {
        .rule-row-label {
          padding-bottom: 3px;
        }
      }
    }

    &-titleheader {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      margin-right: 25px;
      margin-bottom: 20px;
      border-bottom: 1px solid $neutral-mid;

      &-row {
        display: flex;
        align-items: center;
        justify-content: center;

        &-medium {
          font-size: var(--font-size-medium);
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-right: 20px;
        }

        &-subtitle {
          color: $neutral-darker;
          font-weight: $font-weight-semibold;
          padding-left: 10px;
          padding-right: 10px;
        }

        &-operator {
          width: 80px;
        }

        &-end {
          display: flex;
          flex: 1 1 0%;
          justify-content: flex-end;
        }

        &-divider {
          border-right: 1px solid $neutral-mid;
          margin: 10px 0 10px 0;
        }
      }
    }

    &-header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 5px;
      height: 40px;
      align-items: center;


      &-field {
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 15px;
        font-weight: $font-weight-semibold;
        color: $neutral-darker;
        text-transform: uppercase;
        font-size: $font-size-small;
        padding-left: 10px;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &-alert {
      margin-left: 20px;
      margin-right: 25px;
    }

    @media screen and (max-width: 530px) {
      &-row {
        flex-direction: column;

        @media only screen and (max-width: 530px) {
          &-field {
            min-width: 100%;
          }

          @media screen and (max-width: 530px) {

            .removebtn,
            .join-operator-label {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    &-row {
      display: flex;
      margin-left: 20px;
      margin-right: 25px;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 20px;
      }

      &-field {
        flex: 0 0 27%;
        max-width: 27%;
        padding-right: 15px;

        &:last-child {
          padding-right: 0;
        }

        .join-operator-label {
          flex: 0 0 5%;
          max-width: 5%;
        }

        .operator-label {
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 600;
          color: $neutral;
        }
      }

      .operator {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .join-operator {
        flex: 0 0 10%;
        max-width: 10%;
        padding-right: 0;
      }

      .removebtn,
      .join-operator-label {
        display: flex;
        flex: 0 0 5%;
        max-width: 5%;
        margin-bottom: 10px;
        align-items: center;

        >button {
          padding: 5px;
        }
      }
    }

    &-seperator {
      margin-left: auto;

      &-icon {
        color: $link-color;
        padding-right: 10px;
      }
    }

    &-bottom {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    &-operator {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 15px 0;
      text-transform: var(--text-transform);

      &-button {
        .vdl-list__option {
          text-transform: var(--text-transform);
        }
      }
    }

    &-group {
      margin-bottom: 20px;

      &-titleheader {
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        padding-right: 25px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &-row {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $neutral-mid;

          &-medium {
            padding-right: 20px;
            font-size: var(--font-size-medium);
            font-weight: 700;
            margin-top: 10px;
            margin-bottom: 10px;
          }

          &-subtitle {
            color: $neutral-darker;
            font-weight: $font-weight-semibold;
            padding-left: 10px;
            padding-right: 10px;
          }

          &-operator {
            width: 80px;
          }

          &-end {
            display: flex;
            flex: 1 1 0%;
            justify-content: flex-end;
            border-bottom: 1px solid $neutral-mid;
          }

          &-divider {
            border-right: 1px solid $neutral-mid;
            margin: 10px 0 10px 0;
          }
        }

        .vdl-button--link {
          &:hover,
          &:focus,
          &:active {
            &:not([disabled]) {
              box-shadow: inset 2px 2px var(--white), inset -2px -2px var(--white);
              background-color: var(--link-color);
            }
          }

          .vdl-button__container {
            color: $white;
          }

          &[disabled] {
            .vdl-button__container {
              color: $neutral-disabled;
            }
          }
        }
      }

      &-container {
        padding: 15px 15px 0 15px;
      }
    }

    &-summary {
      &-header {
        border-bottom: 1px solid $neutral-mid;
      }

      &-panel {
        margin-left: 20px;
        margin-right: 20px;
      }

      &-main {
        display: flex;
      }

      &-title {
        display: flex;
        flex: 0 0 15%;
        align-self: flex-start;

        &-medium {
          font-size: var(--font-size-medium);
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &-value {
        flex: 0 0 85%;

        & > div {
          display: flex;
        }
      }

      &-text {
        display: flex;
        margin-top: 11px;
        margin-right: 50px;
      }

      &-operator {
        display: flex;
        text-transform: var(--text-transform);
        margin-top: 2px;
      }

      &-divider {
        display: flex;
        text-transform: var(--text-transform);

        &-medium {
          font-size: var(--font-size-medium);
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        &-line {
          display: flex;
          align-items: center;
          width: 100%;

          &-border {
            border-top: 1px solid $white;
            margin-left: 10px;
            width: 100%;
          }
        }
      }

      &-empty {
        display: flex;
        padding-left: 50px;
      }

      &-group {
        &-value {
          border-left: 1px solid $neutral-mid;
          margin-left: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}

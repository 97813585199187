@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

@import 'slick/slick.css';
@import 'slick/slickTheme.scss';

.mdf-carousel {
  padding: 10px;
}

/* added the following to give the background color of the arrows as primary color for visibility, the default which can be found in the slick-theme.css was white */
.slick-prev:before,
.slick-next:before {
  color: $link-color;
}

.carousel-img {
  object-fit: cover;
  // Polyfill for MS Browsers below:
  font-family: 'object-fit: cover;';
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf .newsFeed,
.newsfeed {
  padding: 5px;
  height: auto;
  width: 400px;

  &--title {
    font-size: 24px;
    margin-bottom: 5px;
  }

  &--headerWrapper {
    display: flex;
  }

  &--blockHeader {
    color: $neutral-dark;
    font-size: $font-size-medium;
    font-weight: bold;
    line-height: 30px;
    padding-left: 10px;
  }

  &--subHeader {
    color: $neutral;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    padding-left: 25px;
    border-left: 1px solid $neutral;
    margin-left: 15px;
  }

  &--header {
    display: flex;
    padding: 10px 0px 10px 10px;
    justify-content: space-between;
  }

  &--refresh {
    display: flex;
    justify-content: center;

    &-button {
      position: absolute;
      z-index: 1;
      margin-top: -15px;
      flex: 0 1 auto;
      align-self: auto;
    }
  }

  &--icon {
    position: relative;
    top: 4px;
    left: 8px;
    color: $white;
  }

  &--icon-waypoint {
    position: relative;
    top: 4px;
    left: 4px;
    color: $white;
  }

  &--circle {
    border-radius: 15px;
    border: 1px solid $accent-5 ;
    width: 30px;
    height: 30px;
    background-color: $accent-5 ;
    svg {
      font-size: $icon-size-large;
      left: 6px;
    }
  }

  &--statusCircle {
    border: 2px solid $neutral;
  }

  .fa-iconSize {
    font-size: 30px;
  }

}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf {
  .mdf-drag-item {
    width: 100%;
    cursor: pointer;

     &--selected {
      @extend .mdf-drag-item;
     }

     &-borderStyle {
      border: 1px solid $accent-1;
     }
  }

  .mdf-drop-target {
    width: 100%;
    height: 100%;
  }
}


.mdf-drag-imageStyle {
  width: 16em;
  height: 3em;
  background-color: $accent-0-dark;
  color: $white;
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  top: 0;
  right: 0;
  left: -17em;
  bottom: 0;
}


@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf-tool-bar {
  button.vdl-button--link {
    border: 0;
    border-radius: 0;
    flex-basis: auto;
    text-decoration: none;
    padding: 0;
    background-color: transparent;
    color: $link-color;

    &:focus {
      text-decoration: none;
      color: $link-color;
    }

    &[aria-selected='true'] {
      background: none;
      color: $link-color;

      &:hover {
        color: $link-active-color;
      }
    }

    &[disabled] {
      background-color: var(--button-link-disabled-background);
      color: var(--button-link-disabled-color);
      box-shadow: var(--button-link-disabled-boxShadow);
    }
  }

  .vdl-button-group__container {
    > * {
      &:hover:not([disabled]) {
        outline: none;
        outline-offset: unset;
      }

      &:focus:not([disabled]) {
        box-shadow: inset 2px 2px $input-border-focus, inset -2px -2px $input-border-focus;
      }

      &[aria-selected='true'] {
        &:hover {
          box-shadow: inset 2px 2px $input-border-focus, inset -2px -2px $input-border-focus;
        }
      }
    }
  }
}

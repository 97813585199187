@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/spacing' as *;

@mixin status-color($color, $bgColor) {
  color: $color;
  background-color: $bgColor;
}

.vdl-badge {
  padding-left: var(--badge-padding-left);
  padding-right: var(--badge-padding-right);
  padding-top: var(--badge-padding-top);
  padding-bottom: var(--badge-padding-bottom);
  font-size: 0.75em;
  // spacing out consecutive badges
  margin-right: 0.75em;

  &.rounded {
    border-radius: $sm-spacer;
  }

  &--default {
    @include status-color(var(--badge-color), var(--badge-background));
  }

  &--success {
    @include status-color($white, $color-status-success);
  }

  &--info {
    @include status-color($white, $accent-0-dark);
  }

  &--warning {
    @include status-color($black, $accent-5);
  }

  &--error {
    @include status-color($white, $accent-6);
  }

  &--pending {
    @include status-color($black, $accent-5);
  }

  &--start {
    @include status-color($white, $accent-0-dark);
  }

  &--attention {
    @include status-color($black, $accent-5);
  }

  &--tip {
    @include status-color($white, $accent-0-dark);
  }

  &--neutral {
    @include status-color($white, $neutral-dark);
  }
}

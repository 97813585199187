@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-popup-dialog-container.vdl-overlay-container {
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  padding: 15px;
  border: 0;
  margin: 7px 10px;
  max-width: calc(100vw - 20px);

  &.mdf-popup-dialog-2 {
    margin: 0;
    padding: 1rem;
  }

  &.vdl-popover {
    .arrow {
      width: 15px;
      height: 15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }
  }

  &.vdl-popover[x-placement^=bottom] {
    .arrow {
      top: -13px;
      border-bottom: 15px solid var(--white);
    }
  }

  &.vdl-popover[x-placement^=top] {
    .arrow {
      bottom: -13px;
      border-top: 15px solid var(--white);
    }
  }

  .popup-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .popup-dialog-title {
      font-size: $font-size-h2;
      white-space: normal;
      margin: 0.5rem 0;
    }

    .popup-dialog-close {
      font-size: $font-size-h2;
      line-height: $font-size-h2;
      margin-left: auto;

      button {
        background: none;
        border: 2px solid transparent;
        height: 36px;
        width: 36px;
        padding: 0;

        &:focus {
          border: 2px solid $input-border-focus;
          outline: 0;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
          transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }
      }
    }
  }
}

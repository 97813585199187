.vdl-alert--animate,
.mdf .vdl-alert--animate {
  animation-name: none !important;
}

.vdl-alert--contentPane {
  margin-top: 10px;
}

// Icon colors from Waypoint that are not provided in sdf-utilities
.icon-alert-error {
  color: var(--sdf-context-alert-inline-style-primary-error-icon-color, #c53422)
}

.icon-alert-info {
  color: var(--sdf-context-alert-inline-style-primary-info-icon-color, #476bc3)
}

.icon-alert-neutral {
  color: var(--sdf-context-alert-inline-style-primary-neutral-icon-color, #544e4a)
}

.icon-alert-success {
  color: var(--sdf-context-alert-inline-style-primary-success-icon-color, #407b30)
}

.icon-alert-warning {
  color: var(--sdf-context-alert-inline-style-primary-warning-icon-color, #b65700)
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf-tree-list {
  .mdf-tree-list__tree-item {
    align-items: center;
    background-color: $neutral-lightest;
    border-bottom: 1px solid $neutral-mid;
    display: flex;
    line-height: 30px;
    padding: 3px 5px;

    &:hover {
      background-color: $accent-0-lightest;
    }

    .level0 {
      flex: 0 0 0;
    }

    .level1 {
      flex: 0 0 12px;
    }

    .level2 {
      flex: 0 0 24px;
    }

    .level3 {
      flex: 0 0 36px;
    }

    .level4 {
      flex: 0 0 48px;
    }

    .level5 {
      flex: 0 0 60px;
    }

    .level6 {
      flex: 0 0 72px;
    }

    .level7 {
      flex: 0 0 84px;
    }

    .level8 {
      flex: 0 0 96px;
    }

    .mdf-tree-list__expand-icon {
      color: $link-color;
      cursor: pointer;
      flex: 0 0 12px;
    }
  }

  .mdf-tree-list__tree-content {
    align-items: flex-start;
    display: flex;
    padding: 3px 5px;

    .level0 {
      flex: 0 0 12px;
    }

    .level1 {
      flex: 0 0 24px;
    }

    .level2 {
      flex: 0 0 36px;
    }

    .level3 {
      flex: 0 0 48px;
    }

    .level4 {
      flex: 0 0 60px;
    }

    .level5 {
      flex: 0 0 72px;
    }

    .level6 {
      flex: 0 0 84px;
    }

    .level7 {
      flex: 0 0 96px;
    }

    .level8 {
      flex: 0 0 108px;
    }

    .mdf-tree-list__expand-icon {
      color: $link-color;
      cursor: pointer;
      flex: 0 0 12px;
    }
  }

  > div:first-child {
    border-top: 2px solid $neutral-mid;
  }

  > div:last-child {
    border-bottom: 2px solid $neutral-mid;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.progress-tracker-wrapper {
  display: flex;
  align-items: center;
  height: 80px;
  background-color: $white;
}

.pt-title-container {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 23px 50px;
  border-right: 1px solid;
  border-color: $neutral-mid;
}

.pt-steps-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.pt-container {
  width: 150px;
  height: 50px;
  display: flex;
  flex-direction: column;
}

.pt-step-container {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.pt-step-title {
  position: relative;
  bottom: -5px;
  text-align: center;
  margin: 0 auto;
}

@mixin pt-border-color($accent) {
  border: 2px solid $accent;
  color: $accent;
}

.pt-step-navigation__step-indicator {
  &.accent-0 {
    @include pt-border-color($accent-0);
  }
}

.pt-step-navigation__step-indicator {
  border: 2px solid $neutral-dark ;
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 40px;
  font-size: 18px;
  font-weight: 400;
  color: $neutral-dark ;
  line-height: 25px;
  text-align: center;

  &:focus {
    box-shadow: $input-border-focus 0 0 0 2px inset;
  }

  &.active {
    background-color: $accent-0-lighter;
    box-shadow: $input-border-focus 0 0 0 2px inset;
    @include pt-border-color($accent-0-darker);
  }

  // disabled step
  &.neutral {
    border-color: $neutral;
    box-shadow: none;
  }
}

.pt-step-navigation__step-line {
  border-top: 2px solid;
  width: calc(50% - 15px);
  position: absolute;
  top: 15px;
  z-index: 0;
  transform: translate(0, -30%);
}

.pt-step-navigation__step-line.before {
  left: 0;
}

.pt-step-navigation__step-line.after {
  right: 0;
}

.pt-step-navigation__step-line-complete-container {
  position: absolute;
  margin-left: 19px;
  width: 10px;
  top: 40px;
  bottom: 0;
}

.pt-step-navigation__step-line-complete.active-accent-5 {
  border-left: 2px solid $accent-5;
}

.mdf .fa.pt-step-arrow {
  font-weight: bold;
  position: absolute;
  right: -2px;
  top: 6px;
  font-size: 18px;
}

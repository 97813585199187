@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

// Changed to match with the One-UX grid row Height and fix a bug with resizeObserver on MDFGrid.
$row-height: 48px;

.mdf {
  .mdf-spreadsheet, .mdf-grid-layout {
    position: relative;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    outline: none;
    flex-direction: column;

    &-header.vdl-row.mdf-flex-nowrap {
      min-height: $row-height;
      position: sticky;
    }

    &-row.selected, &-row.selected > &-cell {
      background-color: $accent-0-lighter;
    }

    &-row.row-highlight, &-row.row-highlight > .mdf-grid-layout-cell, &-row.row-highlight > .mdf-spreadsheet-cell {
      background-color: $accent-0-lightest;
    }

    >.cell-highlight {
      background-color: $accent-0-lighter;
    }

    &-cell:focus, &-compound-cell-header:focus {
      border: 2px solid $input-border-focus;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    .no-data:focus {
      background-color: $white;
      border: 2px solid $input-border-focus;
      outline: 0;
    }

    &-cell.editable:hover:not([data-edit="on"]) {
      border: 2px solid $input-border-focus;
    }

    // Adding this transparant border to editable cells so that it wont push the content when the user hover over a cell and components adds a blue border around to indicate editable cells
    &-cell.editable {
      border: 1px solid transparent;
    }

    &-cell.vdl-col-xs {
      padding: 0 5px;
    }

    >.no-data {
      position: relative;
      min-height: 40px;
      padding: 5px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      margin: 0 -15px;
      justify-content: center;
      align-items: center;
      background-color: $accent-0-lighter;
    }

    div {
      >.locked-header {
        display: inline-flex;
        position: sticky;
        top: 0;
        left: 0;
        overflow: hidden;
        border-bottom: 1px solid $neutral;
        min-height: $row-height;
        color: $neutral-darker;
        background-color: $neutral-lightest;

        >.mdf-grid-layout-row, >.mdf-spreadsheet-row {
          display: flex;
          flex-grow: 1;
          min-height: $row-height;

          >.mdf-grid-layout-cell.virtual-cell, >.mdf-spreadsheet-cell.virtual-cell {
            max-width: 30px;
            min-width: 30px;
            text-align: center;
            padding: 0;
          }

          >.mdf-grid-layout-cell, >.mdf-spreadsheet-cell  {
            display: inline-flex;
            min-height: $row-height;
            cursor: pointer;
            align-items: center;
            text-align: left;
            flex: 0 0 auto;
            flex-grow: 1;

            >.cell-content {
              overflow: hidden;
              white-space:normal;
            }

            >.cell-resize:hover {
              background-color: $accent-5;
              cursor: col-resize;
            }
          }

          .mdf-grid-layout-cell.sort-enabled, .mdf-spreadsheet-cell.sort-enabled {
            >.cell-content {
              color: $link-color;
              max-width: calc(100% - 20px);
            }

            .cell-content + span.fa {
              color: $link-color;
              align-items: center;
              display: inline-flex;
              height: 100%;
              line-height: inherit;
              margin-left: 5px;
            }

            span.fa:before {
              padding-right: 2px;
            }
          }
        }
      }
    }

    div.grid-body, div.spreadsheet-body {
      display: flex;
      margin: 0 -15px;
    }

    >.grid-body >.locked-body, >.spreadsheet-body >.locked-body {
      position: relative;
      display: inline-flex;
      left: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      >div {
        >.mdf-grid-layout-row, >.mdf-spreadsheet-row {
          display: flex;
          border-bottom: 1px solid $neutral-mid;
          cursor: pointer;
          min-height: $row-height;

          >.mdf-grid-layout-cell, >.mdf-spreadsheet-cell {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            flex: 0 0 auto;
            flex-grow: 1;

            >.cell-content {
              overflow: hidden;
              width: 100%;
            }
          }
        }
      }
    }

    >.grid-body >.locked-body::-webkit-scrollbar, >.spreadsheet-body >.locked-body::-webkit-scrollbar {
      width: 0 !important
    }

    >.spreadsheet-footer {
      display: flex;
      margin: 0 -15px;
      position: relative;
    }

    >.spreadsheet-footer >.locked-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      >.mdf-spreadsheet-row {
        display: flex;
        flex-grow: 1;
        border-bottom: 1px solid $neutral-mid;
        cursor: pointer;
        min-height: $row-height;

        >.mdf-spreadsheet-cell {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          flex: 0 0 auto;
          flex-grow: 1;

          >.cell-content {
            overflow: hidden;
            width: 100%;
          }
        }
      }
    }

    div {
      >.unlocked-header {
        position: sticky;
        display: inline-flex;
        top: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
        border-bottom: 1px solid $neutral;
        min-height: $row-height;
        color: $neutral-darker;
        background-color: $neutral-lightest;

        >.mdf-grid-layout-row, >.mdf-spreadsheet-row {
          display: flex;
          flex-grow: 1;
          min-height: $row-height;

          >.mdf-grid-layout-cell.virtual-cell, >.mdf-spreadsheet-cell.virtual-cell {
            max-width: 30px;
            min-width: 30px;
            text-align: center;
            padding: 0;
          }

          >.mdf-grid-layout-cell, >.mdf-spreadsheet-cell {
            display: inline-flex;
            min-height: $row-height;
            cursor: pointer;
            align-items: center;
            text-align: left;
            flex: 0 0 auto;
            flex-grow: 1;

            >.cell-content {
              overflow: hidden;
              white-space:normal;
            }

            >.cell-resize:hover {
              background-color: $accent-5;
              cursor: col-resize;
            }
          }

          .mdf-grid-layout-cell.sort-enabled, .mdf-spreadsheet-cell.sort-enabled {
            >.cell-content {
              color: $link-color;
              max-width: calc(100% - 20px);
            }

            .cell-content + span.fa {
              align-items: center;
              cursor: pointer;
              color: $link-color;
              display: inline-flex;
              margin-left: 5px;
            }

            span.fa:before {
              padding-right: 2px;
            }
          }

          >.mdf-grid-layout-compound-cell-header, >.mdf-spreadsheet-compound-cell-header {
            display: inline-flex;
            vertical-align: top;
            min-height: $row-height;
            cursor: pointer;
            position: relative;
            flex: 0 0 auto;
            flex-grow: 1;

            >.cell-resize:hover {
              background-color: $accent-5;
              cursor: col-resize;
            }

            >.cell-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              min-height: $row-height;
              text-align: center;
              flex-grow: 1;

              >.compound-title {
                display: inline-flex;
                height: 50%;
                overflow: hidden;
              }

              >.compound-title.sort-enabled {
                color: $link-color;
                max-width: calc(100% - 20px);

                >span.fa {
                  align-items: center;
                  cursor: pointer;
                  color: $link-color;
                  display: inline-flex;
                  height: fit-content;
                  line-height: inherit;
                  margin-left: 5px;
                }

                span.fa:before {
                  padding-right: 2px;
                }
              }

              >.compound-subtitle {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 20px;

                >div {
                  align-items: center;
                  justify-content: center;
                  font-size: smaller;
                  width: 50%;
                  overflow: hidden;

                  >span.vdl-invisible.ariaInvisibleText {
                    display: flex;
                    line-height: 0;
                    height: 0;
                    width: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .unlocked-header::-webkit-scrollbar { /* WebKit */
      width: 0;
    }

    >.grid-body .scroll-container, >.spreadsheet-body .scroll-container {
      overflow-y: auto;
      overflow-x: auto;
      position: relative;
      height: 100%;

       >.unlocked-body {
         top: 0;
       }
    }

    .grid-body .scroll-container.noVerticalScroll, .spreadsheet-body .scroll-container.noVerticalScroll {
      overflow-y: hidden;
    }

    .grid-body .scroll-container >.unlocked-body, .spreadsheet-body .scroll-container >.unlocked-body {
      position: relative;
      overflow-y: hidden;
      overflow-x: hidden;

      >div {
        >.mdf-grid-layout-row, >.mdf-spreadsheet-row {
          display: flex;
          flex-grow: 1;
          border-bottom: 1px solid $neutral-mid;
          cursor: pointer;
          min-height: $row-height;

          >.mdf-grid-layout-cell, >.mdf-spreadsheet-cell {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            flex: 0 0 auto;
            flex-grow: 1;

            >.cell-content {
              overflow: hidden;
              width: 100%;

              i.fa-chevron-down, i.fa-chevron-right {
                text-align: center;
              }
            }

            >.compound-cell-content {
              display: inline-flex;
              align-items: center;
              width: 50%;
              height: 100%;

              .left, .right {
                display: inline-block;
                text-overflow:ellipsis;
                text-align: center;
                overflow: hidden;
                width: 100%;
              }
            }
          }

          >.mdf-grid-layout-cell.virtual-cell, >.mdf-spreadsheet-cell.virtual-cell {
            max-width: 30px;
            min-width: 30px;
            text-align: center;
            padding: 0;
          }

          >.cell.editable {
            >.cell-content {
              width: 100%;
            }
          }
        }

        >.mdf-grid-layout-row.subheader, >.mdf-spreadsheet-row.subheader {
          >.mdf-grid-layout-cell, >.mdf-spreadsheet-cell {
            width: 100%;
          }
        }
      }
    }

    >.spreadsheet-footer >.unlocked-footer {
      position: absolute;
      bottom: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      scrollbar-width: none;  /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      >.mdf-spreadsheet-row {
        display: flex;
        flex-grow: 1;
        border-bottom: 1px solid $neutral-mid;
        cursor: pointer;
        min-height: $row-height;

        >.mdf-spreadsheet-cell {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          flex: 0 0 auto;
          flex-grow: 1;

          >.cell-content {
            overflow: hidden;
            width: 100%;
          }

          >.compound-cell-content {
            display: inline-flex;
            height: 100%;
            width: 50%;
            align-items: center;

            .left, .right {
              text-align: center;
              width: 100%;
              text-overflow:ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  // Adding border stylings for spreadsheet
  .mdf-spreadsheet {
    border: 1px solid $neutral-mid;
    border-bottom: 1px solid $neutral-mid;

    >div >.locked-header, >div >.unlocked-header {
      >.mdf-spreadsheet-row > .mdf-spreadsheet-cell , >.mdf-spreadsheet-row >.mdf-spreadsheet-compound-cell-header {
        >.cell-content {
          font-weight: bold;
        }

        >.cell-resize {
          width: 6px;
          right: -3px;
        }
      }
    }

    >div >.locked-header, >div >.unlocked-header, >.spreadsheet-body >.locked-body, >.spreadsheet-body >.scroll-container >.unlocked-body {
      div >.mdf-spreadsheet-row > .mdf-spreadsheet-cell.editable:hover:not([data-edit="on"]), >.mdf-spreadsheet-row >.mdf-spreadsheet-cell.editable:hover:not([data-edit="on"]) {
        border-right: 1px solid $input-border-focus;
      }
    }

    >div >.locked-header, >div >.unlocked-header, >.spreadsheet-body >.locked-body, >.spreadsheet-body >.scroll-container >.unlocked-body, >.spreadsheet-footer >.locked-footer, >.spreadsheet-footer >.unlocked-footer {
      div >.mdf-spreadsheet-row > .mdf-spreadsheet-cell, >.mdf-spreadsheet-row >.mdf-spreadsheet-cell, >.mdf-spreadsheet-row >.mdf-spreadsheet-compound-cell-header {
        border-right: 1px solid $neutral-mid;
      }

      >.mdf-spreadsheet-row >.mdf-spreadsheet-compound-cell-header >.cell-content >.compound-subtitle >div:last-child, div >.mdf-spreadsheet-row >.mdf-spreadsheet-cell >.compound-cell-content:last-child, >.mdf-spreadsheet-row >.mdf-spreadsheet-cell >.compound-cell-content:last-child {
        border-left: 1px solid $neutral-mid;
      }

      >.mdf-spreadsheet-row >.mdf-spreadsheet-compound-cell-header >.cell-content >.compound-subtitle {
        border-top: 1px solid $neutral-mid;
      }
    }

    >.spreadsheet-body {
      >.locked-body, >.scroll-container >.unlocked-body, >.spreadsheet-footer >.locked-footer, >.spreadsheet-footer >.unlocked-footer {
        div >.mdf-spreadsheet-row.selected >.mdf-spreadsheet-cell:focus, div >.mdf-spreadsheet-row.row-highlight >.mdf-spreadsheet-cell:focus {
          border-right-color: $input-border-focus;
        }
      }
    }

    >.spreadsheet-footer {
      >.locked-footer, >.unlocked-footer {
        border-top: 1px solid $neutral-mid;

        div >.mdf-spreadsheet-row, div >.mdf-spreadsheet-row {
          align-items: center;

          .mdf-spreadsheet-cell {
            align-self: stretch;
          }
        }
      }
    }
  }

  .mdf-grid-layout {
    border-top:1px solid $neutral;

   .mdf-grid-layout-sticky-header {
      z-index: 1;
    }

    .mdf-grid-layout-header.mdf-grid-layout-fixed-header {
      position: fixed;
    }

    div {
      >.locked-header, >.unlocked-header {
        >.mdf-grid-layout-row {
          >.mdf-grid-layout-cell , >.mdf-grid-layout-compound-cell-header {
            >.cell-content, >.cell-content >.compound-title, >.cell-content >.compound-subtitle {
              font-weight: $font-weight-semibold;
              color: $neutral-darker;
              text-transform: var(--text-transform);
              font-size: var(--grid-header-font-size);
            }

            >.cell-resize {
              display: inline-flex;
              align-items: center;
              width: 6px;
              right: 0;
              top: 0;
            }

            >.cell-resize:before {
              content: "\007C\007C";
              cursor: col-resize;
            }
          }
        }
      }
    }
  }
}

.mdf {
  .mdf-spreadsheet, .mdf-grid-layout {
    >.spreadsheet-body, >.grid-body {
      >.locked-body, >.scroll-container .unlocked-body {
        >div {
          .mdf-spreadsheet-row, .mdf-grid-layout-row {
            align-self: stretch;

            >.mdf-spreadsheet-cell, >.mdf-grid-layout-cell {
              align-self: stretch;
              >.cell-content, >.compound-cell-content {
                .vdl-progress-bar {
                  >.vdl-progress-bar__bar {
                    margin: 5px 0;
                  }
                }

                .vdl-date-time-picker {
                  margin: 0;
                }

                .vdl-textbox {
                  margin: 0;
                }

                .vdl-checkbox, .vdl-radio, .vdl-toggle-switch {
                  margin: 0;
                }

                .mdf-dropdown-container {
                  width: 100%;
                  .mdf-dropdown-menu {
                    width: inherit;

                    input.mdf-dropdown-menu-value {
                      width: 100%;
                    }
                  }
                }

                .MDFSelectBox__control {
                  margin: 0;
                }

                // text-overflow ellipsis from cell-content is not being inherited to button or link as they have their own style to display it completely. This code is needed to stop the link content being overflown to next columns / cells
                .vdl-button.vdl-button--link, .vdl-button.vdl-button--primary, .vdl-button.vdl-button--secondary {
                  span.vdl-button__container {
                    overflow: hidden;
                    width: 100%;
                    display: inline-block;
                  }
                }

                .vdl-button.vdl-button--link {
                  padding: 0;
                  height: 24px;
                }
              }
            }

            >div[data-edit="on"].mdf-spreadsheet-cell, >div[data-edit="on"].mdf-grid-layout-cell {
              >.cell-content, >.compound-cell-content .left, >.compound-cell-content .right {
                overflow: visible;
              }
            }
          }
        }
      }
    }
  }
  .caption {
    line-height: 0;
    margin: 0;
  }
}

// Styles for cloned row element, which is in effect while the user drags and holds the row
div.mdf-grid-layout-row.gu-mirror, div.mdf-spreadsheet-row.gu-mirror {
  display: inline-flex;
  min-height: $row-height;
  justify-content: flex-start;
  align-items: center;

  .mdf-grid-layout-cell, .mdf-spreadsheet-cell {
    display: inline-flex;

    >.compound-cell-content {
      display: flex;
      align-items: center;
    }
  }
}

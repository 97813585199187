@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

$multi-button-height: 30px;
$multi-button-width: 60px;

.wfnx-multi-buttonGroup-wrapper {
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  display: flex;

  align-items: center;

  > * {
    text-transform: var(--text-transform);
    background-color: white;
    color: $black;
    border: 1px solid $accent-0;
    width: $multi-button-width;
    height: $multi-button-height;

    &:hover {
      color: $accent-0;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
    }

    &:last-child {
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
    }

    &[aria-disabled=true] {
      background-color: $neutral-disabled;
      color: $neutral-dark;
      cursor: not-allowed;
      border: 1px solid $neutral-dark;

      &[aria-selected=true] {
        background-color: $neutral-dark;
        color: $neutral-disabled;
      }
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
      box-shadow: none;
    }

    &[aria-selected=true] {
      background-color: $accent-0-darker;
      border: 1px solid $black;
      color: $white;
    }

  }
}






@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.more-label {
  .vdl-button--link.vdl-button--variant-text {
    font-weight: $font-weight-bold;
    color: $accent-0-darker;
    padding: 2px;
  }
}

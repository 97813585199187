@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf .ms-container {
  display:flex;
  width: 100%;
}

// To remove the bullets for the list tags while dragging.
.gu-unselectable {
  .mdf ~ .vdl-dual-select__list-group-item {
    list-style-type: none;
    padding: 10px 15px;
  }
}

.mdf .vdl-dual-select .vdl-dual-select__button-overlay .move-all-right,
.mdf .vdl-dual-select .vdl-dual-select__button-overlay .move-all-left {
  font-size: 11.2px;
}

.mdf .vdl-dual-select__list-holder .vdl-dual-select__list-group {
  height: 100%;
  margin: 0;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  outline: none;

  &.selected.show-sequence {
    counter-reset: li-count;

    & > li {
      counter-increment: li-count;

      & > span::before{
        content: counter(li-count) '. ';
      }
    }
  }
}

input.search-input {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 4px;
  height: 30px;
  background-color: $white;
  border: 1px solid $neutral-mid;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
}

.mdf .vdl-dual-select .vdl-dual-select__list-holder.vdl-dual-select_validation_error {
  border: 1px solid $accent-6;
}

.inValid__text {
  color: $accent-6;
}

// Accessibility feature - focus style for keyboard navigation. Style placeholder pending UX design.
.mdf .vdl-dual-select .vdl-dual-select__list-holder:focus-within {
  border: 2px solid $input-border-focus;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.mdf .vdl-dual-select .vdl-dual-select__button-overlay .move-all-right,
.mdf .vdl-dual-select .vdl-dual-select__button-overlay .move-right,
.mdf .vdl-dual-select .vdl-dual-select__button-overlay .move-all-left,
.mdf .vdl-dual-select .vdl-dual-select__button-overlay .move-left {
  &:focus {
    background-color: $accent-0-darker;
  }
}

.mdf .vdl-dual-select .vdl-dual-select__list-group-item:focus {
  background-color: $accent-0-lighter;
}

// Accessibility feature - hide button instructions
.mdf .vdl-invisible {
  margin: 0;
  line-height: 0;
}

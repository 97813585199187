.mdf {
  .small-card,
  .small-card2 {
    &.small-card--list-card {
      min-height: 50px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;

      &-column {
        flex: 1 1 0%; // Per MDN: Internet Explorer 11 considers a unitless value in the flex-basis part to be syntactically invalid. Their workaround is to add a unit based value
      }

      .small-card-middle {
        .small-card-content {
          display: flex;
          align-items: center;
          min-height: 0;
          padding: 5px 10px 10px 40px;

          .small-card--list-card-column {
            flex: 1 1 0%;
          }
        }
      }
    }
  }
}

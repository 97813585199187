/*!
 * Styles derived from:
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.mdf-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .mdf-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .mdf-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .mdf-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .mdf-container {
    max-width: 1140px;
  }
}

.mdf-container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mdf-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.mdf-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.mdf-no-gutters > .mdf-col,
.mdf-no-gutters > [class*="mdf-col-"] {
  padding-right: 0;
  padding-left: 0;
}

.mdf-col-1, .mdf-col-2, .mdf-col-3, .mdf-col-4, .mdf-col-5, .mdf-col-6, .mdf-col-7, .mdf-col-8, .mdf-col-9, .mdf-col-10, .mdf-col-11, .mdf-col-12, .mdf-col,
.mdf-col-auto, .mdf-col-sm-1, .mdf-col-sm-2, .mdf-col-sm-3, .mdf-col-sm-4, .mdf-col-sm-5, .mdf-col-sm-6, .mdf-col-sm-7, .mdf-col-sm-8, .mdf-col-sm-9, .mdf-col-sm-10, .mdf-col-sm-11, .mdf-col-sm-12, .mdf-col-sm,
.mdf-col-sm-auto, .mdf-col-md-1, .mdf-col-md-2, .mdf-col-md-3, .mdf-col-md-4, .mdf-col-md-5, .mdf-col-md-6, .mdf-col-md-7, .mdf-col-md-8, .mdf-col-md-9, .mdf-col-md-10, .mdf-col-md-11, .mdf-col-md-12, .mdf-col-md,
.mdf-col-md-auto, .mdf-col-lg-1, .mdf-col-lg-2, .mdf-col-lg-3, .mdf-col-lg-4, .mdf-col-lg-5, .mdf-col-lg-6, .mdf-col-lg-7, .mdf-col-lg-8, .mdf-col-lg-9, .mdf-col-lg-10, .mdf-col-lg-11, .mdf-col-lg-12, .mdf-col-lg,
.mdf-col-lg-auto, .mdf-col-xl-1, .mdf-col-xl-2, .mdf-col-xl-3, .mdf-col-xl-4, .mdf-col-xl-5, .mdf-col-xl-6, .mdf-col-xl-7, .mdf-col-xl-8, .mdf-col-xl-9, .mdf-col-xl-10, .mdf-col-xl-11, .mdf-col-xl-12, .mdf-col-xl,
.mdf-col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.mdf-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.mdf-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.mdf-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.mdf-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.mdf-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.mdf-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.mdf-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.mdf-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.mdf-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.mdf-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.mdf-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.mdf-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.mdf-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.mdf-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.mdf-order-first {
  order: -1;
}

.mdf-order-last {
  order: 13;
}

.mdf-order-0 {
  order: 0;
}

.mdf-order-1 {
  order: 1;
}

.mdf-order-2 {
  order: 2;
}

.mdf-order-3 {
  order: 3;
}

.mdf-order-4 {
  order: 4;
}

.mdf-order-5 {
  order: 5;
}

.mdf-order-6 {
  order: 6;
}

.mdf-order-7 {
  order: 7;
}

.mdf-order-8 {
  order: 8;
}

.mdf-order-9 {
  order: 9;
}

.mdf-order-10 {
  order: 10;
}

.mdf-order-11 {
  order: 11;
}

.mdf-order-12 {
  order: 12;
}

.mdf-offset-1 {
  margin-left: 8.333333%;
}

.mdf-offset-2 {
  margin-left: 16.666667%;
}

.mdf-offset-3 {
  margin-left: 25%;
}

.mdf-offset-4 {
  margin-left: 33.333333%;
}

.mdf-offset-5 {
  margin-left: 41.666667%;
}

.mdf-offset-6 {
  margin-left: 50%;
}

.mdf-offset-7 {
  margin-left: 58.333333%;
}

.mdf-offset-8 {
  margin-left: 66.666667%;
}

.mdf-offset-9 {
  margin-left: 75%;
}

.mdf-offset-10 {
  margin-left: 83.333333%;
}

.mdf-offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .mdf-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .mdf-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .mdf-col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mdf-col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mdf-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mdf-col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mdf-col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mdf-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdf-col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mdf-col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mdf-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mdf-col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mdf-col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mdf-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mdf-order-sm-first {
    order: -1;
  }
  .mdf-order-sm-last {
    order: 13;
  }
  .mdf-order-sm-0 {
    order: 0;
  }
  .mdf-order-sm-1 {
    order: 1;
  }
  .mdf-order-sm-2 {
    order: 2;
  }
  .mdf-order-sm-3 {
    order: 3;
  }
  .mdf-order-sm-4 {
    order: 4;
  }
  .mdf-order-sm-5 {
    order: 5;
  }
  .mdf-order-sm-6 {
    order: 6;
  }
  .mdf-order-sm-7 {
    order: 7;
  }
  .mdf-order-sm-8 {
    order: 8;
  }
  .mdf-order-sm-9 {
    order: 9;
  }
  .mdf-order-sm-10 {
    order: 10;
  }
  .mdf-order-sm-11 {
    order: 11;
  }
  .mdf-order-sm-12 {
    order: 12;
  }
  .mdf-offset-sm-0 {
    margin-left: 0;
  }
  .mdf-offset-sm-1 {
    margin-left: 8.333333%;
  }
  .mdf-offset-sm-2 {
    margin-left: 16.666667%;
  }
  .mdf-offset-sm-3 {
    margin-left: 25%;
  }
  .mdf-offset-sm-4 {
    margin-left: 33.333333%;
  }
  .mdf-offset-sm-5 {
    margin-left: 41.666667%;
  }
  .mdf-offset-sm-6 {
    margin-left: 50%;
  }
  .mdf-offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .mdf-offset-sm-9 {
    margin-left: 75%;
  }
  .mdf-offset-sm-10 {
    margin-left: 83.333333%;
  }
  .mdf-offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .mdf-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .mdf-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .mdf-col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mdf-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mdf-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mdf-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mdf-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mdf-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdf-col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mdf-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mdf-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mdf-col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mdf-col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mdf-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mdf-order-md-first {
    order: -1;
  }
  .mdf-order-md-last {
    order: 13;
  }
  .mdf-order-md-0 {
    order: 0;
  }
  .mdf-order-md-1 {
    order: 1;
  }
  .mdf-order-md-2 {
    order: 2;
  }
  .mdf-order-md-3 {
    order: 3;
  }
  .mdf-order-md-4 {
    order: 4;
  }
  .mdf-order-md-5 {
    order: 5;
  }
  .mdf-order-md-6 {
    order: 6;
  }
  .mdf-order-md-7 {
    order: 7;
  }
  .mdf-order-md-8 {
    order: 8;
  }
  .mdf-order-md-9 {
    order: 9;
  }
  .mdf-order-md-10 {
    order: 10;
  }
  .mdf-order-md-11 {
    order: 11;
  }
  .mdf-order-md-12 {
    order: 12;
  }
  .mdf-offset-md-0 {
    margin-left: 0;
  }
  .mdf-offset-md-1 {
    margin-left: 8.333333%;
  }
  .mdf-offset-md-2 {
    margin-left: 16.666667%;
  }
  .mdf-offset-md-3 {
    margin-left: 25%;
  }
  .mdf-offset-md-4 {
    margin-left: 33.333333%;
  }
  .mdf-offset-md-5 {
    margin-left: 41.666667%;
  }
  .mdf-offset-md-6 {
    margin-left: 50%;
  }
  .mdf-offset-md-7 {
    margin-left: 58.333333%;
  }
  .mdf-offset-md-8 {
    margin-left: 66.666667%;
  }
  .mdf-offset-md-9 {
    margin-left: 75%;
  }
  .mdf-offset-md-10 {
    margin-left: 83.333333%;
  }
  .mdf-offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .mdf-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .mdf-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .mdf-col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mdf-col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mdf-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mdf-col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mdf-col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mdf-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdf-col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mdf-col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mdf-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mdf-col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mdf-col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mdf-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mdf-order-lg-first {
    order: -1;
  }
  .mdf-order-lg-last {
    order: 13;
  }
  .mdf-order-lg-0 {
    order: 0;
  }
  .mdf-order-lg-1 {
    order: 1;
  }
  .mdf-order-lg-2 {
    order: 2;
  }
  .mdf-order-lg-3 {
    order: 3;
  }
  .mdf-order-lg-4 {
    order: 4;
  }
  .mdf-order-lg-5 {
    order: 5;
  }
  .mdf-order-lg-6 {
    order: 6;
  }
  .mdf-order-lg-7 {
    order: 7;
  }
  .mdf-order-lg-8 {
    order: 8;
  }
  .mdf-order-lg-9 {
    order: 9;
  }
  .mdf-order-lg-10 {
    order: 10;
  }
  .mdf-order-lg-11 {
    order: 11;
  }
  .mdf-order-lg-12 {
    order: 12;
  }
  .mdf-offset-lg-0 {
    margin-left: 0;
  }
  .mdf-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .mdf-offset-lg-2 {
    margin-left: 16.666667%;
  }
  .mdf-offset-lg-3 {
    margin-left: 25%;
  }
  .mdf-offset-lg-4 {
    margin-left: 33.333333%;
  }
  .mdf-offset-lg-5 {
    margin-left: 41.666667%;
  }
  .mdf-offset-lg-6 {
    margin-left: 50%;
  }
  .mdf-offset-lg-7 {
    margin-left: 58.333333%;
  }
  .mdf-offset-lg-8 {
    margin-left: 66.666667%;
  }
  .mdf-offset-lg-9 {
    margin-left: 75%;
  }
  .mdf-offset-lg-10 {
    margin-left: 83.333333%;
  }
  .mdf-offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .mdf-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .mdf-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .mdf-col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mdf-col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mdf-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mdf-col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mdf-col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mdf-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdf-col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mdf-col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mdf-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mdf-col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mdf-col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mdf-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mdf-order-xl-first {
    order: -1;
  }
  .mdf-order-xl-last {
    order: 13;
  }
  .mdf-order-xl-0 {
    order: 0;
  }
  .mdf-order-xl-1 {
    order: 1;
  }
  .mdf-order-xl-2 {
    order: 2;
  }
  .mdf-order-xl-3 {
    order: 3;
  }
  .mdf-order-xl-4 {
    order: 4;
  }
  .mdf-order-xl-5 {
    order: 5;
  }
  .mdf-order-xl-6 {
    order: 6;
  }
  .mdf-order-xl-7 {
    order: 7;
  }
  .mdf-order-xl-8 {
    order: 8;
  }
  .mdf-order-xl-9 {
    order: 9;
  }
  .mdf-order-xl-10 {
    order: 10;
  }
  .mdf-order-xl-11 {
    order: 11;
  }
  .mdf-order-xl-12 {
    order: 12;
  }
  .mdf-offset-xl-0 {
    margin-left: 0;
  }
  .mdf-offset-xl-1 {
    margin-left: 8.333333%;
  }
  .mdf-offset-xl-2 {
    margin-left: 16.666667%;
  }
  .mdf-offset-xl-3 {
    margin-left: 25%;
  }
  .mdf-offset-xl-4 {
    margin-left: 33.333333%;
  }
  .mdf-offset-xl-5 {
    margin-left: 41.666667%;
  }
  .mdf-offset-xl-6 {
    margin-left: 50%;
  }
  .mdf-offset-xl-7 {
    margin-left: 58.333333%;
  }
  .mdf-offset-xl-8 {
    margin-left: 66.666667%;
  }
  .mdf-offset-xl-9 {
    margin-left: 75%;
  }
  .mdf-offset-xl-10 {
    margin-left: 83.333333%;
  }
  .mdf-offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.mdf-d-none {
  display: none !important;
}

.mdf-d-inline {
  display: inline !important;
}

.mdf-d-inline-block {
  display: inline-block !important;
}

.mdf-d-block {
  display: block !important;
}

.mdf-d-table {
  display: table !important;
}

.mdf-d-table-row {
  display: table-row !important;
}

.mdf-d-table-cell {
  display: table-cell !important;
}

.mdf-d-flex {
  display: flex !important;
}

.mdf-d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .mdf-d-sm-none {
    display: none !important;
  }
  .mdf-d-sm-inline {
    display: inline !important;
  }
  .mdf-d-sm-inline-block {
    display: inline-block !important;
  }
  .mdf-d-sm-block {
    display: block !important;
  }
  .mdf-d-sm-table {
    display: table !important;
  }
  .mdf-d-sm-table-row {
    display: table-row !important;
  }
  .mdf-d-sm-table-cell {
    display: table-cell !important;
  }
  .mdf-d-sm-flex {
    display: flex !important;
  }
  .mdf-d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .mdf-d-md-none {
    display: none !important;
  }
  .mdf-d-md-inline {
    display: inline !important;
  }
  .mdf-d-md-inline-block {
    display: inline-block !important;
  }
  .mdf-d-md-block {
    display: block !important;
  }
  .mdf-d-md-table {
    display: table !important;
  }
  .mdf-d-md-table-row {
    display: table-row !important;
  }
  .mdf-d-md-table-cell {
    display: table-cell !important;
  }
  .mdf-d-md-flex {
    display: flex !important;
  }
  .mdf-d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .mdf-d-lg-none {
    display: none !important;
  }
  .mdf-d-lg-inline {
    display: inline !important;
  }
  .mdf-d-lg-inline-block {
    display: inline-block !important;
  }
  .mdf-d-lg-block {
    display: block !important;
  }
  .mdf-d-lg-table {
    display: table !important;
  }
  .mdf-d-lg-table-row {
    display: table-row !important;
  }
  .mdf-d-lg-table-cell {
    display: table-cell !important;
  }
  .mdf-d-lg-flex {
    display: flex !important;
  }
  .mdf-d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .mdf-d-xl-none {
    display: none !important;
  }
  .mdf-d-xl-inline {
    display: inline !important;
  }
  .mdf-d-xl-inline-block {
    display: inline-block !important;
  }
  .mdf-d-xl-block {
    display: block !important;
  }
  .mdf-d-xl-table {
    display: table !important;
  }
  .mdf-d-xl-table-row {
    display: table-row !important;
  }
  .mdf-d-xl-table-cell {
    display: table-cell !important;
  }
  .mdf-d-xl-flex {
    display: flex !important;
  }
  .mdf-d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .mdf-d-print-none {
    display: none !important;
  }
  .mdf-d-print-inline {
    display: inline !important;
  }
  .mdf-d-print-inline-block {
    display: inline-block !important;
  }
  .mdf-d-print-block {
    display: block !important;
  }
  .mdf-d-print-table {
    display: table !important;
  }
  .mdf-d-print-table-row {
    display: table-row !important;
  }
  .mdf-d-print-table-cell {
    display: table-cell !important;
  }
  .mdf-d-print-flex {
    display: flex !important;
  }
  .mdf-d-print-inline-flex {
    display: inline-flex !important;
  }
}

.mdf-flex-row {
  flex-direction: row !important;
}

.mdf-flex-column {
  flex-direction: column !important;
}

.mdf-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.mdf-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.mdf-flex-wrap {
  flex-wrap: wrap !important;
}

.mdf-flex-nowrap {
  flex-wrap: nowrap !important;
}

.mdf-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.mdf-flex-fill {
  flex: 1 1 auto !important;
}

.mdf-flex-grow-0 {
  flex-grow: 0 !important;
}

.mdf-flex-grow-1 {
  flex-grow: 1 !important;
}

.mdf-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.mdf-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.mdf-justify-content-start {
  justify-content: flex-start !important;
}

.mdf-justify-content-end {
  justify-content: flex-end !important;
}

.mdf-justify-content-center {
  justify-content: center !important;
}

.mdf-justify-content-between {
  justify-content: space-between !important;
}

.mdf-justify-content-around {
  justify-content: space-around !important;
}

.mdf-align-items-start {
  align-items: flex-start !important;
}

.mdf-align-items-end {
  align-items: flex-end !important;
}

.mdf-align-items-center {
  align-items: center !important;
}

.mdf-align-items-baseline {
  align-items: baseline !important;
}

.mdf-align-items-stretch {
  align-items: stretch !important;
}

.mdf-align-content-start {
  align-content: flex-start !important;
}

.mdf-align-content-end {
  align-content: flex-end !important;
}

.mdf-align-content-center {
  align-content: center !important;
}

.mdf-align-content-between {
  align-content: space-between !important;
}

.mdf-align-content-around {
  align-content: space-around !important;
}

.mdf-align-content-stretch {
  align-content: stretch !important;
}

.mdf-align-self-auto {
  align-self: auto !important;
}

.mdf-align-self-start {
  align-self: flex-start !important;
}

.mdf-align-self-end {
  align-self: flex-end !important;
}

.mdf-align-self-center {
  align-self: center !important;
}

.mdf-align-self-baseline {
  align-self: baseline !important;
}

.mdf-align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .mdf-flex-sm-row {
    flex-direction: row !important;
  }
  .mdf-flex-sm-column {
    flex-direction: column !important;
  }
  .mdf-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .mdf-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .mdf-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .mdf-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .mdf-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .mdf-flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .mdf-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .mdf-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .mdf-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .mdf-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .mdf-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .mdf-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .mdf-justify-content-sm-center {
    justify-content: center !important;
  }
  .mdf-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .mdf-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .mdf-align-items-sm-start {
    align-items: flex-start !important;
  }
  .mdf-align-items-sm-end {
    align-items: flex-end !important;
  }
  .mdf-align-items-sm-center {
    align-items: center !important;
  }
  .mdf-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .mdf-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .mdf-align-content-sm-start {
    align-content: flex-start !important;
  }
  .mdf-align-content-sm-end {
    align-content: flex-end !important;
  }
  .mdf-align-content-sm-center {
    align-content: center !important;
  }
  .mdf-align-content-sm-between {
    align-content: space-between !important;
  }
  .mdf-align-content-sm-around {
    align-content: space-around !important;
  }
  .mdf-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .mdf-align-self-sm-auto {
    align-self: auto !important;
  }
  .mdf-align-self-sm-start {
    align-self: flex-start !important;
  }
  .mdf-align-self-sm-end {
    align-self: flex-end !important;
  }
  .mdf-align-self-sm-center {
    align-self: center !important;
  }
  .mdf-align-self-sm-baseline {
    align-self: baseline !important;
  }
  .mdf-align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .mdf-flex-md-row {
    flex-direction: row !important;
  }
  .mdf-flex-md-column {
    flex-direction: column !important;
  }
  .mdf-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .mdf-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .mdf-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .mdf-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .mdf-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .mdf-flex-md-fill {
    flex: 1 1 auto !important;
  }
  .mdf-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .mdf-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .mdf-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .mdf-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .mdf-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .mdf-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .mdf-justify-content-md-center {
    justify-content: center !important;
  }
  .mdf-justify-content-md-between {
    justify-content: space-between !important;
  }
  .mdf-justify-content-md-around {
    justify-content: space-around !important;
  }
  .mdf-align-items-md-start {
    align-items: flex-start !important;
  }
  .mdf-align-items-md-end {
    align-items: flex-end !important;
  }
  .mdf-align-items-md-center {
    align-items: center !important;
  }
  .mdf-align-items-md-baseline {
    align-items: baseline !important;
  }
  .mdf-align-items-md-stretch {
    align-items: stretch !important;
  }
  .mdf-align-content-md-start {
    align-content: flex-start !important;
  }
  .mdf-align-content-md-end {
    align-content: flex-end !important;
  }
  .mdf-align-content-md-center {
    align-content: center !important;
  }
  .mdf-align-content-md-between {
    align-content: space-between !important;
  }
  .mdf-align-content-md-around {
    align-content: space-around !important;
  }
  .mdf-align-content-md-stretch {
    align-content: stretch !important;
  }
  .mdf-align-self-md-auto {
    align-self: auto !important;
  }
  .mdf-align-self-md-start {
    align-self: flex-start !important;
  }
  .mdf-align-self-md-end {
    align-self: flex-end !important;
  }
  .mdf-align-self-md-center {
    align-self: center !important;
  }
  .mdf-align-self-md-baseline {
    align-self: baseline !important;
  }
  .mdf-align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .mdf-flex-lg-row {
    flex-direction: row !important;
  }
  .mdf-flex-lg-column {
    flex-direction: column !important;
  }
  .mdf-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .mdf-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .mdf-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .mdf-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .mdf-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .mdf-flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .mdf-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .mdf-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .mdf-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .mdf-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .mdf-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .mdf-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .mdf-justify-content-lg-center {
    justify-content: center !important;
  }
  .mdf-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .mdf-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .mdf-align-items-lg-start {
    align-items: flex-start !important;
  }
  .mdf-align-items-lg-end {
    align-items: flex-end !important;
  }
  .mdf-align-items-lg-center {
    align-items: center !important;
  }
  .mdf-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .mdf-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .mdf-align-content-lg-start {
    align-content: flex-start !important;
  }
  .mdf-align-content-lg-end {
    align-content: flex-end !important;
  }
  .mdf-align-content-lg-center {
    align-content: center !important;
  }
  .mdf-align-content-lg-between {
    align-content: space-between !important;
  }
  .mdf-align-content-lg-around {
    align-content: space-around !important;
  }
  .mdf-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .mdf-align-self-lg-auto {
    align-self: auto !important;
  }
  .mdf-align-self-lg-start {
    align-self: flex-start !important;
  }
  .mdf-align-self-lg-end {
    align-self: flex-end !important;
  }
  .mdf-align-self-lg-center {
    align-self: center !important;
  }
  .mdf-align-self-lg-baseline {
    align-self: baseline !important;
  }
  .mdf-align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .mdf-flex-xl-row {
    flex-direction: row !important;
  }
  .mdf-flex-xl-column {
    flex-direction: column !important;
  }
  .mdf-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .mdf-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .mdf-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .mdf-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .mdf-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .mdf-flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .mdf-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .mdf-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .mdf-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .mdf-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .mdf-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .mdf-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .mdf-justify-content-xl-center {
    justify-content: center !important;
  }
  .mdf-justify-content-xl-between {
    justify-content: space-between !important;
  }
  .mdf-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .mdf-align-items-xl-start {
    align-items: flex-start !important;
  }
  .mdf-align-items-xl-end {
    align-items: flex-end !important;
  }
  .mdf-align-items-xl-center {
    align-items: center !important;
  }
  .mdf-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .mdf-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .mdf-align-content-xl-start {
    align-content: flex-start !important;
  }
  .mdf-align-content-xl-end {
    align-content: flex-end !important;
  }
  .mdf-align-content-xl-center {
    align-content: center !important;
  }
  .mdf-align-content-xl-between {
    align-content: space-between !important;
  }
  .mdf-align-content-xl-around {
    align-content: space-around !important;
  }
  .mdf-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .mdf-align-self-xl-auto {
    align-self: auto !important;
  }
  .mdf-align-self-xl-start {
    align-self: flex-start !important;
  }
  .mdf-align-self-xl-end {
    align-self: flex-end !important;
  }
  .mdf-align-self-xl-center {
    align-self: center !important;
  }
  .mdf-align-self-xl-baseline {
    align-self: baseline !important;
  }
  .mdf-align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.mdf-w-25 {
  width: 25% !important;
}

.mdf-w-50 {
  width: 50% !important;
}

.mdf-w-75 {
  width: 75% !important;
}

.mdf-w-100 {
  width: 100% !important;
}

.mdf-w-auto {
  width: auto !important;
}

.mdf-h-25 {
  height: 25% !important;
}

.mdf-h-50 {
  height: 50% !important;
}

.mdf-h-75 {
  height: 75% !important;
}

.mdf-h-100 {
  height: 100% !important;
}

.mdf-h-auto {
  height: auto !important;
}

.mdf-mw-100 {
  max-width: 100% !important;
}

.mdf-mh-100 {
  max-height: 100% !important;
}

.mdf-min-vw-100 {
  min-width: 100vw !important;
}

.mdf-min-vh-100 {
  min-height: 100vh !important;
}

.mdf-vw-100 {
  width: 100vw !important;
}

.mdf-vh-100 {
  height: 100vh !important;
}

.mdf-m-0 {
  margin: 0 !important;
}

.mdf-mt-0,
.mdf-my-0 {
  margin-top: 0 !important;
}

.mdf-mr-0,
.mdf-mx-0 {
  margin-right: 0 !important;
}

.mdf-mb-0,
.mdf-my-0 {
  margin-bottom: 0 !important;
}

.mdf-ml-0,
.mdf-mx-0 {
  margin-left: 0 !important;
}

.mdf-m-1 {
  margin: 0.25rem !important;
}

.mdf-mt-1,
.mdf-my-1 {
  margin-top: 0.25rem !important;
}

.mdf-mr-1,
.mdf-mx-1 {
  margin-right: 0.25rem !important;
}

.mdf-mb-1,
.mdf-my-1 {
  margin-bottom: 0.25rem !important;
}

.mdf-ml-1,
.mdf-mx-1 {
  margin-left: 0.25rem !important;
}

.mdf-m-2 {
  margin: 0.5rem !important;
}

.mdf-mt-2,
.mdf-my-2 {
  margin-top: 0.5rem !important;
}

.mdf-mr-2,
.mdf-mx-2 {
  margin-right: 0.5rem !important;
}

.mdf-mb-2,
.mdf-my-2 {
  margin-bottom: 0.5rem !important;
}

.mdf-ml-2,
.mdf-mx-2 {
  margin-left: 0.5rem !important;
}

.mdf-m-3 {
  margin: 1rem !important;
}

.mdf-mt-3,
.mdf-my-3 {
  margin-top: 1rem !important;
}

.mdf-mr-3,
.mdf-mx-3 {
  margin-right: 1rem !important;
}

.mdf-mb-3,
.mdf-my-3 {
  margin-bottom: 1rem !important;
}

.mdf-ml-3,
.mdf-mx-3 {
  margin-left: 1rem !important;
}

.mdf-m-4 {
  margin: 1.5rem !important;
}

.mdf-mt-4,
.mdf-my-4 {
  margin-top: 1.5rem !important;
}

.mdf-mr-4,
.mdf-mx-4 {
  margin-right: 1.5rem !important;
}

.mdf-mb-4,
.mdf-my-4 {
  margin-bottom: 1.5rem !important;
}

.mdf-ml-4,
.mdf-mx-4 {
  margin-left: 1.5rem !important;
}

.mdf-m-5 {
  margin: 3rem !important;
}

.mdf-mt-5,
.mdf-my-5 {
  margin-top: 3rem !important;
}

.mdf-mr-5,
.mdf-mx-5 {
  margin-right: 3rem !important;
}

.mdf-mb-5,
.mdf-my-5 {
  margin-bottom: 3rem !important;
}

.mdf-ml-5,
.mdf-mx-5 {
  margin-left: 3rem !important;
}

.mdf-p-0 {
  padding: 0 !important;
}

.mdf-pt-0,
.mdf-py-0 {
  padding-top: 0 !important;
}

.mdf-pr-0,
.mdf-px-0 {
  padding-right: 0 !important;
}

.mdf-pb-0,
.mdf-py-0 {
  padding-bottom: 0 !important;
}

.mdf-pl-0,
.mdf-px-0 {
  padding-left: 0 !important;
}

.mdf-p-1 {
  padding: 0.25rem !important;
}

.mdf-pt-1,
.mdf-py-1 {
  padding-top: 0.25rem !important;
}

.mdf-pr-1,
.mdf-px-1 {
  padding-right: 0.25rem !important;
}

.mdf-pb-1,
.mdf-py-1 {
  padding-bottom: 0.25rem !important;
}

.mdf-pl-1,
.mdf-px-1 {
  padding-left: 0.25rem !important;
}

.mdf-p-2 {
  padding: 0.5rem !important;
}

.mdf-pt-2,
.mdf-py-2 {
  padding-top: 0.5rem !important;
}

.mdf-pr-2,
.mdf-px-2 {
  padding-right: 0.5rem !important;
}

.mdf-pb-2,
.mdf-py-2 {
  padding-bottom: 0.5rem !important;
}

.mdf-pl-2,
.mdf-px-2 {
  padding-left: 0.5rem !important;
}

.mdf-p-3 {
  padding: 1rem !important;
}

.mdf-pt-3,
.mdf-py-3 {
  padding-top: 1rem !important;
}

.mdf-pr-3,
.mdf-px-3 {
  padding-right: 1rem !important;
}

.mdf-pb-3,
.mdf-py-3 {
  padding-bottom: 1rem !important;
}

.mdf-pl-3,
.mdf-px-3 {
  padding-left: 1rem !important;
}

.mdf-p-4 {
  padding: 1.5rem !important;
}

.mdf-pt-4,
.mdf-py-4 {
  padding-top: 1.5rem !important;
}

.mdf-pr-4,
.mdf-px-4 {
  padding-right: 1.5rem !important;
}

.mdf-pb-4,
.mdf-py-4 {
  padding-bottom: 1.5rem !important;
}

.mdf-pl-4,
.mdf-px-4 {
  padding-left: 1.5rem !important;
}

.mdf-p-5 {
  padding: 3rem !important;
}

.mdf-pt-5,
.mdf-py-5 {
  padding-top: 3rem !important;
}

.mdf-pr-5,
.mdf-px-5 {
  padding-right: 3rem !important;
}

.mdf-pb-5,
.mdf-py-5 {
  padding-bottom: 3rem !important;
}

.mdf-pl-5,
.mdf-px-5 {
  padding-left: 3rem !important;
}

.mdf-m-n1 {
  margin: -0.25rem !important;
}

.mdf-mt-n1,
.mdf-my-n1 {
  margin-top: -0.25rem !important;
}

.mdf-mr-n1,
.mdf-mx-n1 {
  margin-right: -0.25rem !important;
}

.mdf-mb-n1,
.mdf-my-n1 {
  margin-bottom: -0.25rem !important;
}

.mdf-ml-n1,
.mdf-mx-n1 {
  margin-left: -0.25rem !important;
}

.mdf-m-n2 {
  margin: -0.5rem !important;
}

.mdf-mt-n2,
.mdf-my-n2 {
  margin-top: -0.5rem !important;
}

.mdf-mr-n2,
.mdf-mx-n2 {
  margin-right: -0.5rem !important;
}

.mdf-mb-n2,
.mdf-my-n2 {
  margin-bottom: -0.5rem !important;
}

.mdf-ml-n2,
.mdf-mx-n2 {
  margin-left: -0.5rem !important;
}

.mdf-m-n3 {
  margin: -1rem !important;
}

.mdf-mt-n3,
.mdf-my-n3 {
  margin-top: -1rem !important;
}

.mdf-mr-n3,
.mdf-mx-n3 {
  margin-right: -1rem !important;
}

.mdf-mb-n3,
.mdf-my-n3 {
  margin-bottom: -1rem !important;
}

.mdf-ml-n3,
.mdf-mx-n3 {
  margin-left: -1rem !important;
}

.mdf-m-n4 {
  margin: -1.5rem !important;
}

.mdf-mt-n4,
.mdf-my-n4 {
  margin-top: -1.5rem !important;
}

.mdf-mr-n4,
.mdf-mx-n4 {
  margin-right: -1.5rem !important;
}

.mdf-mb-n4,
.mdf-my-n4 {
  margin-bottom: -1.5rem !important;
}

.mdf-ml-n4,
.mdf-mx-n4 {
  margin-left: -1.5rem !important;
}

.mdf-m-n5 {
  margin: -3rem !important;
}

.mdf-mt-n5,
.mdf-my-n5 {
  margin-top: -3rem !important;
}

.mdf-mr-n5,
.mdf-mx-n5 {
  margin-right: -3rem !important;
}

.mdf-mb-n5,
.mdf-my-n5 {
  margin-bottom: -3rem !important;
}

.mdf-ml-n5,
.mdf-mx-n5 {
  margin-left: -3rem !important;
}

.mdf-m-auto {
  margin: auto !important;
}

.mdf-mt-auto,
.mdf-my-auto {
  margin-top: auto !important;
}

.mdf-mr-auto,
.mdf-mx-auto {
  margin-right: auto !important;
}

.mdf-mb-auto,
.mdf-my-auto {
  margin-bottom: auto !important;
}

.mdf-ml-auto,
.mdf-mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .mdf-m-sm-0 {
    margin: 0 !important;
  }
  .mdf-mt-sm-0,
  .mdf-my-sm-0 {
    margin-top: 0 !important;
  }
  .mdf-mr-sm-0,
  .mdf-mx-sm-0 {
    margin-right: 0 !important;
  }
  .mdf-mb-sm-0,
  .mdf-my-sm-0 {
    margin-bottom: 0 !important;
  }
  .mdf-ml-sm-0,
  .mdf-mx-sm-0 {
    margin-left: 0 !important;
  }
  .mdf-m-sm-1 {
    margin: 0.25rem !important;
  }
  .mdf-mt-sm-1,
  .mdf-my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mdf-mr-sm-1,
  .mdf-mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mdf-mb-sm-1,
  .mdf-my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mdf-ml-sm-1,
  .mdf-mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mdf-m-sm-2 {
    margin: 0.5rem !important;
  }
  .mdf-mt-sm-2,
  .mdf-my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mdf-mr-sm-2,
  .mdf-mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mdf-mb-sm-2,
  .mdf-my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mdf-ml-sm-2,
  .mdf-mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mdf-m-sm-3 {
    margin: 1rem !important;
  }
  .mdf-mt-sm-3,
  .mdf-my-sm-3 {
    margin-top: 1rem !important;
  }
  .mdf-mr-sm-3,
  .mdf-mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mdf-mb-sm-3,
  .mdf-my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mdf-ml-sm-3,
  .mdf-mx-sm-3 {
    margin-left: 1rem !important;
  }
  .mdf-m-sm-4 {
    margin: 1.5rem !important;
  }
  .mdf-mt-sm-4,
  .mdf-my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mdf-mr-sm-4,
  .mdf-mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mdf-mb-sm-4,
  .mdf-my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mdf-ml-sm-4,
  .mdf-mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mdf-m-sm-5 {
    margin: 3rem !important;
  }
  .mdf-mt-sm-5,
  .mdf-my-sm-5 {
    margin-top: 3rem !important;
  }
  .mdf-mr-sm-5,
  .mdf-mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mdf-mb-sm-5,
  .mdf-my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mdf-ml-sm-5,
  .mdf-mx-sm-5 {
    margin-left: 3rem !important;
  }
  .mdf-p-sm-0 {
    padding: 0 !important;
  }
  .mdf-pt-sm-0,
  .mdf-py-sm-0 {
    padding-top: 0 !important;
  }
  .mdf-pr-sm-0,
  .mdf-px-sm-0 {
    padding-right: 0 !important;
  }
  .mdf-pb-sm-0,
  .mdf-py-sm-0 {
    padding-bottom: 0 !important;
  }
  .mdf-pl-sm-0,
  .mdf-px-sm-0 {
    padding-left: 0 !important;
  }
  .mdf-p-sm-1 {
    padding: 0.25rem !important;
  }
  .mdf-pt-sm-1,
  .mdf-py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .mdf-pr-sm-1,
  .mdf-px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .mdf-pb-sm-1,
  .mdf-py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .mdf-pl-sm-1,
  .mdf-px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .mdf-p-sm-2 {
    padding: 0.5rem !important;
  }
  .mdf-pt-sm-2,
  .mdf-py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .mdf-pr-sm-2,
  .mdf-px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .mdf-pb-sm-2,
  .mdf-py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .mdf-pl-sm-2,
  .mdf-px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .mdf-p-sm-3 {
    padding: 1rem !important;
  }
  .mdf-pt-sm-3,
  .mdf-py-sm-3 {
    padding-top: 1rem !important;
  }
  .mdf-pr-sm-3,
  .mdf-px-sm-3 {
    padding-right: 1rem !important;
  }
  .mdf-pb-sm-3,
  .mdf-py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .mdf-pl-sm-3,
  .mdf-px-sm-3 {
    padding-left: 1rem !important;
  }
  .mdf-p-sm-4 {
    padding: 1.5rem !important;
  }
  .mdf-pt-sm-4,
  .mdf-py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .mdf-pr-sm-4,
  .mdf-px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .mdf-pb-sm-4,
  .mdf-py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .mdf-pl-sm-4,
  .mdf-px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .mdf-p-sm-5 {
    padding: 3rem !important;
  }
  .mdf-pt-sm-5,
  .mdf-py-sm-5 {
    padding-top: 3rem !important;
  }
  .mdf-pr-sm-5,
  .mdf-px-sm-5 {
    padding-right: 3rem !important;
  }
  .mdf-pb-sm-5,
  .mdf-py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .mdf-pl-sm-5,
  .mdf-px-sm-5 {
    padding-left: 3rem !important;
  }
  .mdf-m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mdf-mt-sm-n1,
  .mdf-my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mdf-mr-sm-n1,
  .mdf-mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mdf-mb-sm-n1,
  .mdf-my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mdf-ml-sm-n1,
  .mdf-mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .mdf-m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mdf-mt-sm-n2,
  .mdf-my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mdf-mr-sm-n2,
  .mdf-mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mdf-mb-sm-n2,
  .mdf-my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mdf-ml-sm-n2,
  .mdf-mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .mdf-m-sm-n3 {
    margin: -1rem !important;
  }
  .mdf-mt-sm-n3,
  .mdf-my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mdf-mr-sm-n3,
  .mdf-mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mdf-mb-sm-n3,
  .mdf-my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mdf-ml-sm-n3,
  .mdf-mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .mdf-m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mdf-mt-sm-n4,
  .mdf-my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mdf-mr-sm-n4,
  .mdf-mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mdf-mb-sm-n4,
  .mdf-my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mdf-ml-sm-n4,
  .mdf-mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .mdf-m-sm-n5 {
    margin: -3rem !important;
  }
  .mdf-mt-sm-n5,
  .mdf-my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mdf-mr-sm-n5,
  .mdf-mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mdf-mb-sm-n5,
  .mdf-my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mdf-ml-sm-n5,
  .mdf-mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .mdf-m-sm-auto {
    margin: auto !important;
  }
  .mdf-mt-sm-auto,
  .mdf-my-sm-auto {
    margin-top: auto !important;
  }
  .mdf-mr-sm-auto,
  .mdf-mx-sm-auto {
    margin-right: auto !important;
  }
  .mdf-mb-sm-auto,
  .mdf-my-sm-auto {
    margin-bottom: auto !important;
  }
  .mdf-ml-sm-auto,
  .mdf-mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .mdf-m-md-0 {
    margin: 0 !important;
  }
  .mdf-mt-md-0,
  .mdf-my-md-0 {
    margin-top: 0 !important;
  }
  .mdf-mr-md-0,
  .mdf-mx-md-0 {
    margin-right: 0 !important;
  }
  .mdf-mb-md-0,
  .mdf-my-md-0 {
    margin-bottom: 0 !important;
  }
  .mdf-ml-md-0,
  .mdf-mx-md-0 {
    margin-left: 0 !important;
  }
  .mdf-m-md-1 {
    margin: 0.25rem !important;
  }
  .mdf-mt-md-1,
  .mdf-my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mdf-mr-md-1,
  .mdf-mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mdf-mb-md-1,
  .mdf-my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mdf-ml-md-1,
  .mdf-mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .mdf-m-md-2 {
    margin: 0.5rem !important;
  }
  .mdf-mt-md-2,
  .mdf-my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mdf-mr-md-2,
  .mdf-mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mdf-mb-md-2,
  .mdf-my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mdf-ml-md-2,
  .mdf-mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .mdf-m-md-3 {
    margin: 1rem !important;
  }
  .mdf-mt-md-3,
  .mdf-my-md-3 {
    margin-top: 1rem !important;
  }
  .mdf-mr-md-3,
  .mdf-mx-md-3 {
    margin-right: 1rem !important;
  }
  .mdf-mb-md-3,
  .mdf-my-md-3 {
    margin-bottom: 1rem !important;
  }
  .mdf-ml-md-3,
  .mdf-mx-md-3 {
    margin-left: 1rem !important;
  }
  .mdf-m-md-4 {
    margin: 1.5rem !important;
  }
  .mdf-mt-md-4,
  .mdf-my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mdf-mr-md-4,
  .mdf-mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mdf-mb-md-4,
  .mdf-my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mdf-ml-md-4,
  .mdf-mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .mdf-m-md-5 {
    margin: 3rem !important;
  }
  .mdf-mt-md-5,
  .mdf-my-md-5 {
    margin-top: 3rem !important;
  }
  .mdf-mr-md-5,
  .mdf-mx-md-5 {
    margin-right: 3rem !important;
  }
  .mdf-mb-md-5,
  .mdf-my-md-5 {
    margin-bottom: 3rem !important;
  }
  .mdf-ml-md-5,
  .mdf-mx-md-5 {
    margin-left: 3rem !important;
  }
  .mdf-p-md-0 {
    padding: 0 !important;
  }
  .mdf-pt-md-0,
  .mdf-py-md-0 {
    padding-top: 0 !important;
  }
  .mdf-pr-md-0,
  .mdf-px-md-0 {
    padding-right: 0 !important;
  }
  .mdf-pb-md-0,
  .mdf-py-md-0 {
    padding-bottom: 0 !important;
  }
  .mdf-pl-md-0,
  .mdf-px-md-0 {
    padding-left: 0 !important;
  }
  .mdf-p-md-1 {
    padding: 0.25rem !important;
  }
  .mdf-pt-md-1,
  .mdf-py-md-1 {
    padding-top: 0.25rem !important;
  }
  .mdf-pr-md-1,
  .mdf-px-md-1 {
    padding-right: 0.25rem !important;
  }
  .mdf-pb-md-1,
  .mdf-py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .mdf-pl-md-1,
  .mdf-px-md-1 {
    padding-left: 0.25rem !important;
  }
  .mdf-p-md-2 {
    padding: 0.5rem !important;
  }
  .mdf-pt-md-2,
  .mdf-py-md-2 {
    padding-top: 0.5rem !important;
  }
  .mdf-pr-md-2,
  .mdf-px-md-2 {
    padding-right: 0.5rem !important;
  }
  .mdf-pb-md-2,
  .mdf-py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .mdf-pl-md-2,
  .mdf-px-md-2 {
    padding-left: 0.5rem !important;
  }
  .mdf-p-md-3 {
    padding: 1rem !important;
  }
  .mdf-pt-md-3,
  .mdf-py-md-3 {
    padding-top: 1rem !important;
  }
  .mdf-pr-md-3,
  .mdf-px-md-3 {
    padding-right: 1rem !important;
  }
  .mdf-pb-md-3,
  .mdf-py-md-3 {
    padding-bottom: 1rem !important;
  }
  .mdf-pl-md-3,
  .mdf-px-md-3 {
    padding-left: 1rem !important;
  }
  .mdf-p-md-4 {
    padding: 1.5rem !important;
  }
  .mdf-pt-md-4,
  .mdf-py-md-4 {
    padding-top: 1.5rem !important;
  }
  .mdf-pr-md-4,
  .mdf-px-md-4 {
    padding-right: 1.5rem !important;
  }
  .mdf-pb-md-4,
  .mdf-py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .mdf-pl-md-4,
  .mdf-px-md-4 {
    padding-left: 1.5rem !important;
  }
  .mdf-p-md-5 {
    padding: 3rem !important;
  }
  .mdf-pt-md-5,
  .mdf-py-md-5 {
    padding-top: 3rem !important;
  }
  .mdf-pr-md-5,
  .mdf-px-md-5 {
    padding-right: 3rem !important;
  }
  .mdf-pb-md-5,
  .mdf-py-md-5 {
    padding-bottom: 3rem !important;
  }
  .mdf-pl-md-5,
  .mdf-px-md-5 {
    padding-left: 3rem !important;
  }
  .mdf-m-md-n1 {
    margin: -0.25rem !important;
  }
  .mdf-mt-md-n1,
  .mdf-my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mdf-mr-md-n1,
  .mdf-mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mdf-mb-md-n1,
  .mdf-my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mdf-ml-md-n1,
  .mdf-mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .mdf-m-md-n2 {
    margin: -0.5rem !important;
  }
  .mdf-mt-md-n2,
  .mdf-my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mdf-mr-md-n2,
  .mdf-mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mdf-mb-md-n2,
  .mdf-my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mdf-ml-md-n2,
  .mdf-mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .mdf-m-md-n3 {
    margin: -1rem !important;
  }
  .mdf-mt-md-n3,
  .mdf-my-md-n3 {
    margin-top: -1rem !important;
  }
  .mdf-mr-md-n3,
  .mdf-mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mdf-mb-md-n3,
  .mdf-my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mdf-ml-md-n3,
  .mdf-mx-md-n3 {
    margin-left: -1rem !important;
  }
  .mdf-m-md-n4 {
    margin: -1.5rem !important;
  }
  .mdf-mt-md-n4,
  .mdf-my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mdf-mr-md-n4,
  .mdf-mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mdf-mb-md-n4,
  .mdf-my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mdf-ml-md-n4,
  .mdf-mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .mdf-m-md-n5 {
    margin: -3rem !important;
  }
  .mdf-mt-md-n5,
  .mdf-my-md-n5 {
    margin-top: -3rem !important;
  }
  .mdf-mr-md-n5,
  .mdf-mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mdf-mb-md-n5,
  .mdf-my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mdf-ml-md-n5,
  .mdf-mx-md-n5 {
    margin-left: -3rem !important;
  }
  .mdf-m-md-auto {
    margin: auto !important;
  }
  .mdf-mt-md-auto,
  .mdf-my-md-auto {
    margin-top: auto !important;
  }
  .mdf-mr-md-auto,
  .mdf-mx-md-auto {
    margin-right: auto !important;
  }
  .mdf-mb-md-auto,
  .mdf-my-md-auto {
    margin-bottom: auto !important;
  }
  .mdf-ml-md-auto,
  .mdf-mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .mdf-m-lg-0 {
    margin: 0 !important;
  }
  .mdf-mt-lg-0,
  .mdf-my-lg-0 {
    margin-top: 0 !important;
  }
  .mdf-mr-lg-0,
  .mdf-mx-lg-0 {
    margin-right: 0 !important;
  }
  .mdf-mb-lg-0,
  .mdf-my-lg-0 {
    margin-bottom: 0 !important;
  }
  .mdf-ml-lg-0,
  .mdf-mx-lg-0 {
    margin-left: 0 !important;
  }
  .mdf-m-lg-1 {
    margin: 0.25rem !important;
  }
  .mdf-mt-lg-1,
  .mdf-my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mdf-mr-lg-1,
  .mdf-mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mdf-mb-lg-1,
  .mdf-my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mdf-ml-lg-1,
  .mdf-mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mdf-m-lg-2 {
    margin: 0.5rem !important;
  }
  .mdf-mt-lg-2,
  .mdf-my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mdf-mr-lg-2,
  .mdf-mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mdf-mb-lg-2,
  .mdf-my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mdf-ml-lg-2,
  .mdf-mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mdf-m-lg-3 {
    margin: 1rem !important;
  }
  .mdf-mt-lg-3,
  .mdf-my-lg-3 {
    margin-top: 1rem !important;
  }
  .mdf-mr-lg-3,
  .mdf-mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mdf-mb-lg-3,
  .mdf-my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mdf-ml-lg-3,
  .mdf-mx-lg-3 {
    margin-left: 1rem !important;
  }
  .mdf-m-lg-4 {
    margin: 1.5rem !important;
  }
  .mdf-mt-lg-4,
  .mdf-my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mdf-mr-lg-4,
  .mdf-mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mdf-mb-lg-4,
  .mdf-my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mdf-ml-lg-4,
  .mdf-mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mdf-m-lg-5 {
    margin: 3rem !important;
  }
  .mdf-mt-lg-5,
  .mdf-my-lg-5 {
    margin-top: 3rem !important;
  }
  .mdf-mr-lg-5,
  .mdf-mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mdf-mb-lg-5,
  .mdf-my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mdf-ml-lg-5,
  .mdf-mx-lg-5 {
    margin-left: 3rem !important;
  }
  .mdf-p-lg-0 {
    padding: 0 !important;
  }
  .mdf-pt-lg-0,
  .mdf-py-lg-0 {
    padding-top: 0 !important;
  }
  .mdf-pr-lg-0,
  .mdf-px-lg-0 {
    padding-right: 0 !important;
  }
  .mdf-pb-lg-0,
  .mdf-py-lg-0 {
    padding-bottom: 0 !important;
  }
  .mdf-pl-lg-0,
  .mdf-px-lg-0 {
    padding-left: 0 !important;
  }
  .mdf-p-lg-1 {
    padding: 0.25rem !important;
  }
  .mdf-pt-lg-1,
  .mdf-py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .mdf-pr-lg-1,
  .mdf-px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .mdf-pb-lg-1,
  .mdf-py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .mdf-pl-lg-1,
  .mdf-px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .mdf-p-lg-2 {
    padding: 0.5rem !important;
  }
  .mdf-pt-lg-2,
  .mdf-py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .mdf-pr-lg-2,
  .mdf-px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .mdf-pb-lg-2,
  .mdf-py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .mdf-pl-lg-2,
  .mdf-px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .mdf-p-lg-3 {
    padding: 1rem !important;
  }
  .mdf-pt-lg-3,
  .mdf-py-lg-3 {
    padding-top: 1rem !important;
  }
  .mdf-pr-lg-3,
  .mdf-px-lg-3 {
    padding-right: 1rem !important;
  }
  .mdf-pb-lg-3,
  .mdf-py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .mdf-pl-lg-3,
  .mdf-px-lg-3 {
    padding-left: 1rem !important;
  }
  .mdf-p-lg-4 {
    padding: 1.5rem !important;
  }
  .mdf-pt-lg-4,
  .mdf-py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .mdf-pr-lg-4,
  .mdf-px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .mdf-pb-lg-4,
  .mdf-py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .mdf-pl-lg-4,
  .mdf-px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .mdf-p-lg-5 {
    padding: 3rem !important;
  }
  .mdf-pt-lg-5,
  .mdf-py-lg-5 {
    padding-top: 3rem !important;
  }
  .mdf-pr-lg-5,
  .mdf-px-lg-5 {
    padding-right: 3rem !important;
  }
  .mdf-pb-lg-5,
  .mdf-py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .mdf-pl-lg-5,
  .mdf-px-lg-5 {
    padding-left: 3rem !important;
  }
  .mdf-m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mdf-mt-lg-n1,
  .mdf-my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mdf-mr-lg-n1,
  .mdf-mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mdf-mb-lg-n1,
  .mdf-my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mdf-ml-lg-n1,
  .mdf-mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .mdf-m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mdf-mt-lg-n2,
  .mdf-my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mdf-mr-lg-n2,
  .mdf-mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mdf-mb-lg-n2,
  .mdf-my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mdf-ml-lg-n2,
  .mdf-mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .mdf-m-lg-n3 {
    margin: -1rem !important;
  }
  .mdf-mt-lg-n3,
  .mdf-my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mdf-mr-lg-n3,
  .mdf-mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mdf-mb-lg-n3,
  .mdf-my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mdf-ml-lg-n3,
  .mdf-mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .mdf-m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mdf-mt-lg-n4,
  .mdf-my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mdf-mr-lg-n4,
  .mdf-mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mdf-mb-lg-n4,
  .mdf-my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mdf-ml-lg-n4,
  .mdf-mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .mdf-m-lg-n5 {
    margin: -3rem !important;
  }
  .mdf-mt-lg-n5,
  .mdf-my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mdf-mr-lg-n5,
  .mdf-mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mdf-mb-lg-n5,
  .mdf-my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mdf-ml-lg-n5,
  .mdf-mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .mdf-m-lg-auto {
    margin: auto !important;
  }
  .mdf-mt-lg-auto,
  .mdf-my-lg-auto {
    margin-top: auto !important;
  }
  .mdf-mr-lg-auto,
  .mdf-mx-lg-auto {
    margin-right: auto !important;
  }
  .mdf-mb-lg-auto,
  .mdf-my-lg-auto {
    margin-bottom: auto !important;
  }
  .mdf-ml-lg-auto,
  .mdf-mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .mdf-m-xl-0 {
    margin: 0 !important;
  }
  .mdf-mt-xl-0,
  .mdf-my-xl-0 {
    margin-top: 0 !important;
  }
  .mdf-mr-xl-0,
  .mdf-mx-xl-0 {
    margin-right: 0 !important;
  }
  .mdf-mb-xl-0,
  .mdf-my-xl-0 {
    margin-bottom: 0 !important;
  }
  .mdf-ml-xl-0,
  .mdf-mx-xl-0 {
    margin-left: 0 !important;
  }
  .mdf-m-xl-1 {
    margin: 0.25rem !important;
  }
  .mdf-mt-xl-1,
  .mdf-my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mdf-mr-xl-1,
  .mdf-mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mdf-mb-xl-1,
  .mdf-my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mdf-ml-xl-1,
  .mdf-mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .mdf-m-xl-2 {
    margin: 0.5rem !important;
  }
  .mdf-mt-xl-2,
  .mdf-my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mdf-mr-xl-2,
  .mdf-mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mdf-mb-xl-2,
  .mdf-my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mdf-ml-xl-2,
  .mdf-mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .mdf-m-xl-3 {
    margin: 1rem !important;
  }
  .mdf-mt-xl-3,
  .mdf-my-xl-3 {
    margin-top: 1rem !important;
  }
  .mdf-mr-xl-3,
  .mdf-mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mdf-mb-xl-3,
  .mdf-my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mdf-ml-xl-3,
  .mdf-mx-xl-3 {
    margin-left: 1rem !important;
  }
  .mdf-m-xl-4 {
    margin: 1.5rem !important;
  }
  .mdf-mt-xl-4,
  .mdf-my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mdf-mr-xl-4,
  .mdf-mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mdf-mb-xl-4,
  .mdf-my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mdf-ml-xl-4,
  .mdf-mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .mdf-m-xl-5 {
    margin: 3rem !important;
  }
  .mdf-mt-xl-5,
  .mdf-my-xl-5 {
    margin-top: 3rem !important;
  }
  .mdf-mr-xl-5,
  .mdf-mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mdf-mb-xl-5,
  .mdf-my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mdf-ml-xl-5,
  .mdf-mx-xl-5 {
    margin-left: 3rem !important;
  }
  .mdf-p-xl-0 {
    padding: 0 !important;
  }
  .mdf-pt-xl-0,
  .mdf-py-xl-0 {
    padding-top: 0 !important;
  }
  .mdf-pr-xl-0,
  .mdf-px-xl-0 {
    padding-right: 0 !important;
  }
  .mdf-pb-xl-0,
  .mdf-py-xl-0 {
    padding-bottom: 0 !important;
  }
  .mdf-pl-xl-0,
  .mdf-px-xl-0 {
    padding-left: 0 !important;
  }
  .mdf-p-xl-1 {
    padding: 0.25rem !important;
  }
  .mdf-pt-xl-1,
  .mdf-py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .mdf-pr-xl-1,
  .mdf-px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .mdf-pb-xl-1,
  .mdf-py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .mdf-pl-xl-1,
  .mdf-px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .mdf-p-xl-2 {
    padding: 0.5rem !important;
  }
  .mdf-pt-xl-2,
  .mdf-py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .mdf-pr-xl-2,
  .mdf-px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .mdf-pb-xl-2,
  .mdf-py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .mdf-pl-xl-2,
  .mdf-px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .mdf-p-xl-3 {
    padding: 1rem !important;
  }
  .mdf-pt-xl-3,
  .mdf-py-xl-3 {
    padding-top: 1rem !important;
  }
  .mdf-pr-xl-3,
  .mdf-px-xl-3 {
    padding-right: 1rem !important;
  }
  .mdf-pb-xl-3,
  .mdf-py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .mdf-pl-xl-3,
  .mdf-px-xl-3 {
    padding-left: 1rem !important;
  }
  .mdf-p-xl-4 {
    padding: 1.5rem !important;
  }
  .mdf-pt-xl-4,
  .mdf-py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .mdf-pr-xl-4,
  .mdf-px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .mdf-pb-xl-4,
  .mdf-py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .mdf-pl-xl-4,
  .mdf-px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .mdf-p-xl-5 {
    padding: 3rem !important;
  }
  .mdf-pt-xl-5,
  .mdf-py-xl-5 {
    padding-top: 3rem !important;
  }
  .mdf-pr-xl-5,
  .mdf-px-xl-5 {
    padding-right: 3rem !important;
  }
  .mdf-pb-xl-5,
  .mdf-py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .mdf-pl-xl-5,
  .mdf-px-xl-5 {
    padding-left: 3rem !important;
  }
  .mdf-m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mdf-mt-xl-n1,
  .mdf-my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mdf-mr-xl-n1,
  .mdf-mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mdf-mb-xl-n1,
  .mdf-my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mdf-ml-xl-n1,
  .mdf-mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .mdf-m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mdf-mt-xl-n2,
  .mdf-my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mdf-mr-xl-n2,
  .mdf-mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mdf-mb-xl-n2,
  .mdf-my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mdf-ml-xl-n2,
  .mdf-mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .mdf-m-xl-n3 {
    margin: -1rem !important;
  }
  .mdf-mt-xl-n3,
  .mdf-my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mdf-mr-xl-n3,
  .mdf-mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mdf-mb-xl-n3,
  .mdf-my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mdf-ml-xl-n3,
  .mdf-mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .mdf-m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mdf-mt-xl-n4,
  .mdf-my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mdf-mr-xl-n4,
  .mdf-mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mdf-mb-xl-n4,
  .mdf-my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mdf-ml-xl-n4,
  .mdf-mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .mdf-m-xl-n5 {
    margin: -3rem !important;
  }
  .mdf-mt-xl-n5,
  .mdf-my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mdf-mr-xl-n5,
  .mdf-mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mdf-mb-xl-n5,
  .mdf-my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mdf-ml-xl-n5,
  .mdf-mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .mdf-m-xl-auto {
    margin: auto !important;
  }
  .mdf-mt-xl-auto,
  .mdf-my-xl-auto {
    margin-top: auto !important;
  }
  .mdf-mr-xl-auto,
  .mdf-mx-xl-auto {
    margin-right: auto !important;
  }
  .mdf-mb-xl-auto,
  .mdf-my-xl-auto {
    margin-bottom: auto !important;
  }
  .mdf-ml-xl-auto,
  .mdf-mx-xl-auto {
    margin-left: auto !important;
  }
}

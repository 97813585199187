@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

@mixin mixin-status-style($borderColor, $bgColor, $iconColor) {
  color: $iconColor;
  background-color: $bgColor;
  border: 1px solid $borderColor;
}

.mdf-status {
  &--info {
    @include mixin-status-style($accent-0-dark, $accent-0-dark, $accent-0-lightest);
  }

  &--warning {
    @include mixin-status-style($accent-5-dark, $accent-5-dark, $accent-5-lightest);
  }

  &--error {
    @include mixin-status-style($accent-6, $accent-6, $accent-6-lightest);
  }

  &--success {
    @include mixin-status-style($accent-1-dark, $accent-1-dark, $accent-1-lightest);
  }

  &--delegate {
    @include mixin-status-style($accent-0-dark, $white, $accent-0-dark);
  }

  &--default {
    @include mixin-status-style($accent-0-dark, $white, $white);
  }
}
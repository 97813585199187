@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.wfnx {
  .wfnx-big-card {
    .wfnx-big-body-card {
      background: $white;
      border-radius: 0 15px 15px 15px;
      width: 100%;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;
@use '@synerg/vdl-css-framework/scss/variables/screen-breakpoints' as *;

@mixin mixin-banner-style-default($iconColor, $circleColor, $bgColor, $headerColor, $contentColor) {
  color: $contentColor;
  background-color: $bgColor;
  font-weight: $font-weight-bold;

  .wfnx_bannercircle__icon {
    right: 0;
    top: -30px; // fix for IE alignment issue
    height: 160px;
    width: 160px;
    border-radius: 160px;
    display: flex;
    color: $bgColor;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: $circleColor;

    .fa {
      font-size: 70px;
    }

    > svg {
      color: $circleColor;
      fill: $iconColor;
    }
  }
}

.wfnx-banner {
  height: 100px;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .wfnx-banner-container {
    display: flex;
    top: 0; // Fix for IE alignment issue
    align-items: center;
    width: 100%;
    position: absolute;
    right: -30px;
    height: 100px;
  }

  &--success {
    @include mixin-banner-style-default($accent-1, $accent-1-dark, $accent-1, $accent-1-darker, $accent-1-darker);
  }

  &--warning {
    @include mixin-banner-style-default($accent-5, $accent-5-light, $accent-5, $accent-5-darker, $accent-5-darker);
  }

  &--alert {
    @include mixin-banner-style-default($accent-3-darker, $accent-3-light, $accent-3-darker, $accent-3-light, $accent-3-light);
  }

  &--info {
    @include mixin-banner-style-default($accent-0-dark, $accent-0, $accent-0-dark, $white, $white);
  }

  &--defaultIcon,
  &--default {
    @include mixin-banner-style-default($white, $neutral, $white, $accent-3-dark, $accent-3-dark);
  }

}

@media screen and (max-width: $screen-md) {
  .wfnx-banner {
    height: 145px;

    .wfnx-banner-container {
      height: 145px;
    }
  }
}

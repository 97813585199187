@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

$titlebarWidth: 350px;
$titlebarHeight: 40px;
$color: $white;

// Once we get the theme concept in we can add different colors accordingly
$titleBarColor: $accent-0-darker;
$font-size: $font-size-large;

.wfnx {
  .wfnx-titleBar {
    width: $titlebarWidth;
    height: $titlebarHeight;
    background-color: $titleBarColor;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 50px;

    .title {
      color: $color;
      font-size: $font-size;
      font-weight: 700;
    }
  }
}

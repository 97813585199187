.mdfDashboard {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.mdf .mdf-draggable .vdl-tile__content {
  min-height: 0;
}

.mdf .flex-start,
.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.mdf .flex-space-between,
.flex-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.mdf .bottom-padding,
.bottom-padding {
  padding-bottom: 5px;
}

.mdf .flex-1-col,
.flex-2-col {
  width: 100%;
}

.mdf .flex-2-cols,
.flex-2-cols {
  width: 49%;
}

.mdf .flex-3-cols,
.flex-3-cols {
  width: 32%;
}

.mdf .flex-4-cols,
.flex-4-cols {
  width: 24%;
}

.mdf .flex-5-cols,
.flex-5-cols {
  width: 19%;
}

.mdf .flex-6-cols,
.flex-6-cols {
  width: 16%;
}

.mdf .flex-7-cols,
.flex-7-cols {
  width: 14%;
}

.mdf .flex-8-cols,
.flex-8-cols {
  width: 12%;
}

.mdf .flex-9-cols,
.flex-9-cols {
  width: 10%;
}

.mdf .flex-10-cols,
.flex-10-cols {
  width: 9%;
}

.mdf .flex-11-cols,
.flex-11-cols {
  width: 8%;
}

.mdf .flex-12-cols,
.flex-12-cols {
  width: 7%;
}

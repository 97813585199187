@use 'sass:math';

.layoutBox {
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;

  &.layout-column {
    flex-direction: column;
  }

  // Fix same height to 100% only for horizontal cards
  &.horizontal-layout {
    .small-card {
      height: 100%;
    }
  }


  &.layout-row, .layoutItem, .layout-column {
    // offsetPadding styles are used for vertical card alignments
    &.offsetPadding-0 {
      padding: 0;
    }

    &.offsetPadding-5 {
      padding: math.div(5px, 2) 0;
    }

    &.offsetPadding-10 {
      padding: math.div(10px, 2) 0;
    }

    &.offsetPadding-15 {
      padding: math.div(15px, 2) 0;
    }

    &.offsetPadding-20 {
      padding: math.div(20px, 2) 0;
    }

    &.gutterWidth-0 {
      padding: 0 0;
    }

    &.gutterWidth-5 {
      padding: math.div(5px, 2);
    }

    &.gutterWidth-10 {
      padding: math.div(10px, 2);
    }

    &.gutterWidth-15 {
      padding: math.div(15px, 2);
    }

    &.gutterWidth-20 {
      padding: math.div(20px, 2);
    }
  }
}

.layoutBox2 {
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;

  &.layout-column {
    flex-direction: column;
  }

  // Fix same height to 100% only for horizontal cards
  &.horizontal-layout {
    .small-card2 {
      height: 100%;
    }
  }


  &.layout-row, .layoutItem, .layout-column {
    // offsetPadding styles are used for vertical card alignments
    &.offsetPadding-0 {
      padding: 0;
    }

    &.offsetPadding-5 {
      padding: 0.125rem 0;
    }

    &.offsetPadding-10 {
      padding: 0.25rem 0;
    }

    &.offsetPadding-15 {
      padding: 0.5rem 0;
    }

    &.offsetPadding-20 {
      padding: 0.75rem 0;
    }

    &.gutterWidth-0 {
      padding: 0 0;
    }

    &.gutterWidth-5 {
      padding: 0.125rem;
    }

    &.gutterWidth-10 {
      padding: 0.25rem;
    }

    &.gutterWidth-15 {
      padding: 0.5rem;
    }

    &.gutterWidth-20 {
      padding: 0.75rem;
    }
  }
}

@media screen and (max-width: 960px) {
  .mdf-css-layout.grid-cols-5,
  .mdf-css-layout.grid-cols-6,
  .mdf-css-layout.grid-cols-7,
  .mdf-css-layout.grid-cols-8,
  .mdf-css-layout.grid-cols-9,
  .mdf-css-layout.grid-cols-10,
  .mdf-css-layout.grid-cols-11,
  .mdf-css-layout.grid-cols-12 {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  .mdf-css-layout-item.col-span-5,
  .mdf-css-layout-item.col-span-6,
  .mdf-css-layout-item.col-span-7,
  .mdf-css-layout-item.col-span-8,
  .mdf-css-layout-item.col-span-9,
  .mdf-css-layout-item.col-span-10,
  .mdf-css-layout-item.col-span-11,
  .mdf-css-layout-item.col-span-12 {
    grid-column: span 4 / span 4;
  }
}

@media screen and (max-width: 768px) {
  .mdf-css-layout.grid-cols-4,
  .mdf-css-layout.grid-cols-5,
  .mdf-css-layout.grid-cols-6,
  .mdf-css-layout.grid-cols-7,
  .mdf-css-layout.grid-cols-8,
  .mdf-css-layout.grid-cols-9,
  .mdf-css-layout.grid-cols-10,
  .mdf-css-layout.grid-cols-11,
  .mdf-css-layout.grid-cols-12 {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  .mdf-css-layout-item.col-span-4,
  .mdf-css-layout-item.col-span-5,
  .mdf-css-layout-item.col-span-6,
  .mdf-css-layout-item.col-span-7,
  .mdf-css-layout-item.col-span-8,
  .mdf-css-layout-item.col-span-9,
  .mdf-css-layout-item.col-span-10,
  .mdf-css-layout-item.col-span-11,
  .mdf-css-layout-item.col-span-12 {
    grid-column: span 3 / span 3;
  }
}

@media screen and (max-width: 576px) {
  .mdf-css-layout.grid-cols-3,
  .mdf-css-layout.grid-cols-4,
  .mdf-css-layout.grid-cols-5,
  .mdf-css-layout.grid-cols-6,
  .mdf-css-layout.grid-cols-7,
  .mdf-css-layout.grid-cols-8,
  .mdf-css-layout.grid-cols-9,
  .mdf-css-layout.grid-cols-10,
  .mdf-css-layout.grid-cols-11,
  .mdf-css-layout.grid-cols-12 {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }

  .mdf-css-layout-item.col-span-3,
  .mdf-css-layout-item.col-span-4,
  .mdf-css-layout-item.col-span-5,
  .mdf-css-layout-item.col-span-6,
  .mdf-css-layout-item.col-span-7,
  .mdf-css-layout-item.col-span-8,
  .mdf-css-layout-item.col-span-9,
  .mdf-css-layout-item.col-span-10,
  .mdf-css-layout-item.col-span-11,
  .mdf-css-layout-item.col-span-12 {
    grid-column: span 2 / span 2;
  }
}

@media screen and (max-width: 360px) {
  .mdf-css-layout.grid-cols-2,
  .mdf-css-layout.grid-cols-3,
  .mdf-css-layout.grid-cols-4,
  .mdf-css-layout.grid-cols-5,
  .mdf-css-layout.grid-cols-6,
  .mdf-css-layout.grid-cols-7,
  .mdf-css-layout.grid-cols-8,
  .mdf-css-layout.grid-cols-9,
  .mdf-css-layout.grid-cols-10,
  .mdf-css-layout.grid-cols-11,
  .mdf-css-layout.grid-cols-12 {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }

  .mdf-css-layout-item.col-span-2,
  .mdf-css-layout-item.col-span-3,
  .mdf-css-layout-item.col-span-4,
  .mdf-css-layout-item.col-span-5,
  .mdf-css-layout-item.col-span-6,
  .mdf-css-layout-item.col-span-7,
  .mdf-css-layout-item.col-span-8,
  .mdf-css-layout-item.col-span-9,
  .mdf-css-layout-item.col-span-10,
  .mdf-css-layout-item.col-span-11,
  .mdf-css-layout-item.col-span-12 {
    grid-column: span 1 / span 1;
  }
}

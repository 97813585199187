@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-grid {
  /*
    &-infinite and child solves a bootstrap/vdl padding/margin issue created with the
    intersection of the React-virtualized grid component and our wrapper/customization
    of it. Now the positioning of the dynamic grid matches that of the static grid.
  */

  &-wrapper {
    display: table;
    min-width: 100%;
    // Added due to Chrome 86 changes that ignore min-width on a display: table element when inside a display: flex element.
    flex: 1 1 auto;
  }

  &-scroll {
    overflow: scroll;
  }

  &-flex {
    display: flex;
  }

  &-resizing {
    flex-grow: 0;
  }

  &-infinite {
    margin-left: -15px;
    margin-right: -15px;

    .mdf-grid-row {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-header {
    height: 40px;
    align-items: center;
    border-top: 1px solid $neutral-mid;
    border-bottom: 1px solid $neutral-mid;
    background-color: initial;
  }

  &-sticky-header {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  &-subheader {
    background-color: initial;
  }

  &-headerCell {
    font-weight: $font-weight-semibold;
    color: $neutral-darker;
    text-transform: var(--text-transform);
    font-size: var(--grid-header-font-size);

    &-title {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
      max-width: 90%;
    }

    // This removes the margins (particularly margin-bottom) from input fields div-based like checkboxes and radio buttons.
    // Have to use !important otherwise this has to be made too specific to catch all the use cases.
    .vdl-checkbox,
    .vdl-radio {
      margin: 0 !important;

      > label {
        margin: 0 !important;
        font-size: $font-size-small;
        font-weight: $font-weight-semibold;
        line-height: 16px;
        padding-left: 25px;

        &::before {
          height: 16px;
          width: 16px;
        }

        &::after {
          height: 6px;
          width: 10px;
          left: 3px;
          top: 4px;
        }
      }
    }

    &.sort-enabled {
      cursor: pointer;
      color: $link-color;
    }

    .sort-arrow {
      margin-left: .5rem;
      cursor: pointer;
      color: $link-color;
      vertical-align: middle;
      &.fa-sort-down{
        &::before {
          position: relative;
          top: -4px
        }
      }
    }
  }

  &-body {
    display: flex;
  }

  &-row {
    align-items: center;
    text-overflow: ellipsis;
    height: auto;
    min-height: 40px;
    border-bottom: 1px solid $neutral-mid;

    &-dragStart {
      opacity: 0.4
    }

    &-dragOver {
      border: 2px dashed
    }

    &:hover {
      background-color: $accent-0-lightest;
    }

    &-selected,
    &-selected .mdf-grid-cell {
      background-color: $accent-0-lighter;
    }

    &-hover,
    &-hover .mdf-grid-cell {
      background-color: $accent-0-lightest;
      & > div{
        background-color: $accent-0-lightest;
      }
    }
  }

  &-cell {
    justify-content: flex-start;
    word-break: break-word;

    // This removes the margins (particularly margin-bottom) from input fields div-based like selects, checkboxes and radio buttons.
    // Have to use !important otherwise this has to be repeated for every input field style class.
    > div {
      margin: 0 !important;

      > label {
        margin: 0 !important;
      }
    }
  }

  &-resizable-column {
    display: flex;
    justify-content: space-between;
  }

  &-resizable-cell:before {
    font-family: FontAwesome;
    content: "\007C\007C";
    cursor: col-resize;
    text-align: right;
    position: absolute;
    right: 0;
  }

  &-locked-column-min-width {
    min-width: 140px;
  }

  &-locked-columns {
    margin-right: 10px;
    flex-grow: 1
  }

  &-unlocked-columns {
    margin-left: 5px;
    overflow-x: scroll;
    z-index: 1;
    border-radius: 0.000001px;

    // commented out 4/7/19 - if this doesn't cause a problem, delete it by 5/7/19
    // &-infinite {
    //   position: relative;
    // }
  }

  &-noDataMessage {
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    color: $black;
    background-color: $accent-0-lighter;
    border-bottom: 1px solid $neutral-mid;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 10px;
  }

  &-scroll-pane {
    height: 400px;
    will-change: scroll-position;
  }

  &-unlocked-infinite-scroll {
    overflow-y: scroll;
  }

  &-unlocked-enable-scroll {
    overflow-y: scroll;
  }

  // commented out 4/7/19 - if this doesn't cause a problem, delete it by 5/7/19
  // &-locked-infinite-scroll {
  //   overflow: hidden;
  // }

  &-window-scroll-container .mdf-grid-scroll-pane {
    height: auto;
  }

  // commented out 4/7/19 - if this doesn't cause a problem, delete it by 5/7/19
  // &-set-overflow {
  //   // adding this overflow style back since it is required not to show the scrollbar for the inline Grid when windowScroll is enabled or enabledScroll is set to false.
  //   overflow-y: hidden;
  // }
}

body :not(.mdf-grid-window-scroll-container) .mdf-grid-locked-infinite-scroll {
  overflow: hidden;
}

body :not(.mdf-grid-window-scroll-container) .mdf-grid-locked-enable-scroll {
  overflow: hidden;
}

.mdf-grid-infinite-grid-container:not(.mdf-grid-window-scroll-container) .mdf-grid-header {
  position: sticky;
  top: 0;
  z-index: 2;
  // temporary fix for sticky header overlap on scroll
  background-color: $neutral-lightest;
}

.mdf-grid-enable-scroll-container:not(.mdf-grid-window-scroll-container) .mdf-grid-header {
  position: sticky;
  top: 0;
  z-index: 2;
  // temporary fix for sticky header overlap on scroll
  background-color: $neutral-lightest;
}

// we need the overflow to be hiddden when enablescroll is false(windowScroll is enabled)
.mdf-grid-window-scroll-container .mdf-grid-set-overflow {
  overflow-y: hidden;
}

.erd_scroll_detection_container {
  display: none !important;
}

// Removing this style to fix the grid alignment issues for grids inside slidein without locked columns.
// This flex style was added to fix column resizing issue which could not recreate after removing this.
// TODO: Remove this commented code once the developers confirms there are no side effects.
// .mdf .vdl-col-xs {
//   flex: 1 0 auto;
// }

// adding the flexbasis to auto to fix IE issue for infiniteGrid.
// From MDN:
// When a non-auto flex-basis is specified, Internet Explorer 10 and 11 always uses a content-box box model to calculate the size of a flex item, even if box-sizing: border-box is applied to the element.
// the workaround is to use auto with a width and height.
.mdf .mdf-grid-infinite-grid-container .mdf-grid-unlocked-columns .vdl-col-xs {
  // Fix misalignment issue in IE11. When grid has columns with long text, item should wrap to next line
  flex-wrap: wrap;
}

.mdf .mdf-grid-enable-scroll-container .mdf-grid-unlocked-columns .vdl-col-xs {
  // Fix misalignment issue in IE11. When grid has columns with long text, item should wrap to next line
  flex-wrap: wrap;
}

.mdf .vdl-row.mdf-grid-row, .mdf .vdl-row.mdf-grid-header {
  flex-wrap: nowrap;
}

// This is required for locked columns to be visible in IE when enableScroll is true and the InfiniteGrid is used.
.mdf .vdl-col-xs.mdf-grid-locked-infinite-scroll-IE, .mdf .vdl-col-xs.mdf-grid-unlocked-infinite-scroll-IE {
  flex-basis: auto;
}

.mdf .vdl-col-xs.mdf-grid-locked-enable-scroll-IE, .mdf .vdl-col-xs.mdf-grid-unlocked-enable-scroll-IE {
  flex-basis: auto;
}

// Resize columns
.mdf .mdf-grid-column-with-set-width {
  flex: 0 0 auto;
}

// Set custom minimum column width on grids with 1+ locked columns. Default minimum width is 140px.
.mdf .mdf-grid-column-min-width-smaller {
  min-width: 15px;
}

table.sdf-table.borders-rows.spacing-tight {
  &.compensation-table {
    background: transparent;

    thead {
      th {
        background: transparent;
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }

    tbody {
      td {
        background: transparent;
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }

    tfoot {
      td {
        background: transparent;
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  color: $accent-0-dark;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid $accent-0-dark;

  &--xxs {
    font-size: $font-size-medium;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }

  &--xs {
    font-size: $font-size-medium;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }

  &--sm {
    font-size: 21px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  &--md {
    font-size: 32px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }

  &--lg {
    font-size: 41px;
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
}
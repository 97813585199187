@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf {

  .mdf-notes {
    position: fixed;
    right: 80px;
    bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: $link-color;
    color: $white;
    padding: 5px;
    border-radius: 90px;
    cursor: pointer;

    i {
      font-size: $font-size-large;
    }
  }

  .mdf-notes-dialog {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 80px;
    bottom: 100px;
    width: 300px;
    height: 400px;
    border: 1px solid $neutral-mid;
    background-color: $white;

    .mdf-notes-dialog-header {
      display: flex;
      flex-direction: row;
      padding: 10px;
      border-bottom: 1px solid $neutral;

      div {
        flex-grow: 1;
        font-weight: $font-weight-bold;
        color: $link-color;
      }

      i {
        cursor: pointer;
      }
    }

    .mdf-notes-dialog-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: $neutral-light;
      padding: 5px;
      overflow-y: auto;

      .mdf-notes-item {
        border-radius: 4px;
        background-color: $white;
        padding: 5px;
        margin: 5px;

        div {
          font-size: $font-size-h6;
          padding-bottom: 5px;

          span {
            padding-right: 5px;
            font-weight: $font-weight-bold;
          }
        }
      }
    }

    .mdf-notes-dialog-footer {
      height: 85px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $neutral;
      padding: 5px;
    }
  }
}

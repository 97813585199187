@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-separator-horizontal-sm {
  display: flex;
  width: 100%;
  height: 28px;
  padding: 2px;

  div {
    flex-grow: 1;
    height: 13px;
    border-bottom: 1px solid $neutral;
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex-grow: 0;
    text-transform: var(--text-transform);
    width: 30px;
    height: 30px;
    border: 1px solid $neutral;
    border-radius: 25px;
    color: $neutral-dark;
    background-color: $neutral-light;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-semibold;
  }
}

.mdf-separator-horizontal-lg {
  display: flex;
  width: 100%;
  height: 50px;
  margin: 10px 0 0 0;
  padding: 2px;

  div {
    flex-grow: 1;
    height: 25px;
    border-bottom: 2px solid $neutral;
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex-grow: 0;
    text-transform: var(--text-transform);
    width: 60px;
    height: 60px;
    border: 2px solid $neutral;
    border-radius: 60px;
    color: $neutral-dark;
    background-color: $neutral-light;
    font-size: $font-size-larger;
    font-weight: $font-weight-bold;
  }
}


.mdf-separator-vertical-sm {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  padding: 2px;

  div {
    width: 50%;
    border-right: 1px solid $neutral;
    flex-grow: 1;
  }

  div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-grow: 0;
    text-transform: var(--text-transform);
    width: 30px;
    height: 30px;
    border: 1px solid $neutral;
    border-radius: 25px;
    color: $neutral-dark;
    background-color: $neutral-light;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-semibold;
  }
}

.mdf-separator-vertical-lg {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 120px;
  margin: 10px 0 0 0;
  padding: 2px;

  div {
    width: 50%;
    border-right: 2px solid $neutral;
    flex-grow: 1;
  }

  div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    align-self: center;
    text-transform: var(--text-transform);
    width: 60px;
    height: 60px;
    border: 2px solid $neutral;
    border-radius: 60px;
    color: $neutral-dark;
    background-color: $neutral-light;
    font-size: $font-size-larger;
    font-weight: $font-weight-bold;
  }
}

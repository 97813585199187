@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

// variables from wfn-spa
$mobile-breakpoint: 1px;
$mobile: "only screen and (max-width: 1px)";

.wfnmockshell-wrapper {
  // disabled overlay
  .wfnmockshell-disabled-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $neutral-dark;
    opacity: 0.5;
  }

  .wfnmockshell-search .wfnmockshell-disabled-overlay {
    width: calc(100% + 3px);
    height: calc(100% + 2px);
    margin-left: -6px;
    margin-top: -1px;
  }

  .wfnmockshell-toolbar .wfnmockshell-disabled-overlay {
    margin-left: -30px;
    z-index: 1;
  }

  .wfnmockshell-footer .wfnmockshell-disabled-overlay {
    margin-top: -10px;
    margin-left: -15px;
  }

  .vdl-checkbox {
    margin: 0;
  }

  // main section
  main {
    min-height: 500px;
    background-color: $neutral-dark;
  }

  @media only screen and (min-width: 1px), print {
    header {
      button {
        border: none;
        background: none;
        font-family: $font-family-base;
        font-size: inherit;
        cursor: pointer;
        border-radius: 0;
      }

      li {
        list-style: none;
        position: relative;
      }

      .wfnmockshell-toolbar {
        background: linear-gradient(to right, $white, rgba(255, 255, 255, 0.75));
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        position: relative;
      }

      .wfnmockshell-mega-menu {
        background: $accent-0-darker;
      }

      .wfn-masthead {
        display: flex;
        max-width: 1200px;
        justify-content: space-between;
        height: 45px;
        overflow: hidden;

        img {
          height: 45px;
        }

        .wfn-logo {
          display: inline-block;
          margin: 0;
          font-size: 40px;
          color: $accent-6;
          text-decoration: none;

          span {
            display: inline-block;
            text-indent: -9999px;
          }
        }
      }

      .wfnmockshell {
        max-width: 1200px;
        font-size: 14px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .wfnmockshell-top {
        display: flex;
        height: 40px;
        margin: 0;
        padding: 0;
        flex-grow: 0;
        white-space: nowrap;

        &-item {
          width: 110px;

          .vdl-checkbox {
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .wfnmockshell-top-label-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 5px;
          }

          &--button {
            padding: 0;
            line-height: 40px;
            text-transform: var(--text-transform);
            letter-spacing: 0.05em;
            color: $white;
            width: 100%;
            font-size: $font-size-small;
            font-weight: $font-weight-base;

            &:hover {
              cursor: pointer;
            }

            &.has-favorites {
              color: $accent-5;
            }
          }

          &--wrapper {
            z-index: 9001;
            background: transparent;
            display: none;
            position: relative;

            &-dart {
              position: absolute;
              border-top: 30px solid $white;
              border-bottom: 30px solid transparent;
              border-right: 55px solid transparent;
              border-left: 55px solid transparent;
              content: "";
              filter: drop-shadow(0 7px 4px rgba(0, 0, 0, 0.2));
            }

            &.wfnmockshell-expanded {
              width: auto;

              .wfnmockshell-top-item--wrapper-dart {
                z-index: 1;
              }

              .wfnmockshell-sub {
                margin-left: 0;
                padding-top: 10px;
                position: absolute;

                &.mdf-mock-shell-wfnmockshell-slide-down {
                  max-height: 1450px;
                }

                &-title-wrapper {
                  height: 75px;
                  padding: 0 0 5px 0;
                }

                &-container {
                  flex-direction: row;
                  flex-wrap: wrap;
                  max-height: 1500px;
                  width: auto;
                  padding-right: 25px;
                  padding-left: 5px;
                }

                &-items {
                  min-height: auto;
                  min-width: auto;
                  max-width: 214px;
                  margin: 20px 0 10px 20px;
                  padding: 0;
                  flex: 1;
                }

                &-item {
                  margin-bottom: 14px;

                  &-title-content {
                    display: flex;
                    border-bottom: 1px solid $black;
                    font-weight: $font-weight-bold;
                    height: 40px;
                    margin-bottom: 10px;
                  }
                }

                .wfnmockshell-sub-item-button-content-title {
                  height: auto;
                }

                .wfnmockshell-level3-item-item--button {
                  height: auto;
                  font-weight: $font-weight-semibold;
                  white-space: nowrap;
                  text-align: left;
                  line-height: 1.1;
                  flex: 1;
                  text-overflow: ellipsis;
                  overflow-x: hidden;
                  // Required to make text overflow work with flexbox
                  min-width: 0;
                }

                .wfnmockshell-features-selected-detail {
                  display: flex;

                  button {
                    text-transform: none;
                    font-size: $font-size-small;
                    margin: -4px 0 0 -4px;
                  }

                  .wfnmockshell-features-selected {
                    font-size: $font-size-small;
                    color: $neutral-darker;
                  }
                }
              }
            }
          }

          &.active-top {
            .wfnmockshell-top-item--button,
            .wfnmockshell-top-label-wrapper {
              background-color: $white;
              color: $black;
            }

            .wfnmockshell-top-item--button {
              filter: none;
            }

            .wfnmockshell-top-label-wrapper {
              filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
            }

            .wfnmockshell-top-item--wrapper {
              display: block;
            }
          }

          // adjustments for long mega menu text with checkbox
          &.large-top-item {
            width: 140px;

            .wfnmockshell-top-item--wrapper-dart {
              border-right: 70px solid transparent;
              border-left: 70px solid transparent;
            }

            .wfnmockshell-collapsed {
              .wfnmockshell-sub {
                margin-left: 70px;
              }
            }
          }

          &.x-large-top-item {
            width: 180px;

            .wfnmockshell-top-item--wrapper-dart {
              border-right: 90px solid transparent;
              border-left: 90px solid transparent;
            }

            .wfnmockshell-collapsed {
              .wfnmockshell-sub {
                margin-left: 90px;
              }
            }
          }

          // adjustments for checkbox
          &.has-checkbox {
            .wfnmockshell-top-label-wrapper {
              justify-content: center;

              .wfnmockshell-top-item--button {
                width: auto;
              }
            }
          }
        }
      }

      .wfnmockshell-sub {
        max-height: 0;
        overflow: hidden;
        margin-left: 55px;
        font-size: 16px;
        color: $black;
        border-radius: 0 0 20px 20px;
        background: $white;
        flex-direction: column;
        width: 300px;
        transition: width 0.25s ease-in-out, max-height 0.25s linear;
        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);

        &.wfnmockshell-sub-right-align {
          margin-left: -245px;
        }

        &.expand-nav.has-active-level3-item {
          width: 600px;
        }

        &-title {
          &-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: column;
            height: 60px;
            margin: 10px 25px 0;
            padding: 0 25px 5px 25px;
            border-bottom: 1px solid $black;

            .wfnmockshell-sub-item-title-buttons {
              button {
                padding: 0;
              }

              button:first-child {
                margin-left: 0;
              }
            }
          }

          &--title {
            color: $black;
            font-weight: $font-weight-semibold;
            font-size: $icon-size-large;
            text-transform: var(--text-transform);
          }

          &--icon {
            font-size: $font-size-h2;
          }
        }

        &-items {
          position: relative;
          padding: 20px 0 10px 25px;
          min-height: 160px;
          min-width: 300px;
        }

        &-item {
          &--button {
            display: inline-block;
            position: relative;
            height: 40px;
            width: 252px;
            padding: 0 0 0 25px;
            white-space: nowrap;
            text-align: left;
            font-size: $icon-size-large;
            font-weight: $font-weight-light;
            color: $black;

            &-content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 100%;

              &-text {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                min-width: 0;
                max-height: 100%;

                .wfnmockshell-sub-item-button-content-title {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  height: 100%;
                  width: 100%;
                }

                .wfnmockshell-features-selected {
                  font-size: $font-size-small;
                  color: $neutral-darker;
                }
              }
            }
          }

          &--button-dart {
            display: none;
            position: absolute;
            right: 0;
            height: 0;
            width: 0;
            border-left: 23px solid $accent-0-lighter;
            border-top: 20px solid transparent;
            border-right: 0;
            border-bottom: 20px solid transparent;
          }

          &.active-sub {
            .wfnmockshell-sub-item--button {
              background-color: $accent-0-lighter;
            }

            .wfnmockshell-sub-item--button-dart {
              display: inline-block;
            }
          }
        }

        &-expand {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;
          margin: 0 0 20px 25px;
          padding: 0 0 0 25px;
          color: $accent-0-dark;
          font-size: $font-size-small;
          font-weight: $font-weight-base;
          cursor: pointer;

          &--title {
            margin-left: 5px;
          }
        }

        &-collapse {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          color: $accent-0-dark;
          font-size: $font-size-small;
          font-weight: $font-weight-base;
          cursor: pointer;

          &--button {
            width: 215px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $accent-0-dark;
            border-radius: 20px 20px 0 0;

            &:hover {
              color: $white;
              background-color: $accent-0-dark;
            }
          }

          &--title {
            margin-left: 5px;
          }
        }

        &.mdf-mock-shell-wfnmockshell-slide-down {
          max-height: 1450px;
        }
      }

      .wfnmockshell-sub-container {
        display: flex;
      }

      .wfnmockshell-level3-item {
        display: none;
        height: auto;
        left: 300px;
        width: 300px;
        background: $white;

        &.active-level3-item {
          display: inline-block;
        }

        &-list {
          padding: 20px 20px 20px 25px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          flex-grow: 1;
        }

        &-detail {
          display: flex;
          align-items: flex-start;
          padding-bottom: 5px;

          &.single-line {
            align-items: center;

            .vdl-checkbox {
              padding-top: 0;
            }
          }

          .vdl-checkbox {
            padding-top: 5px;
          }

          &-text {
            min-width: 0;

            button {
              max-width: 100%;
            }
          }

          .wfnmockshell-features-selected-detail {
            display: flex;

            button {
              text-transform: none;
              font-size: $font-size-small;
              margin: -4px 0 0 -4px;
            }

            .wfnmockshell-features-selected {
              font-size: $font-size-small;
              color: $neutral-darker;
              min-width: 0;
              display: flex;

              &-divisor {
                margin-right: 5px;
              }

              &-label {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }

          &--button {
            white-space: nowrap;
            font-size: $font-size-base;
            font-weight: $font-weight-semibold;
            color: $link-color;
            padding: 0;
            justify-self: flex-start;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 0;
          }

          &--favorite,
          &--unfavorite {
            visibility: hidden;
            flex: 0 0 18px;
            justify-self: flex-end;
            color: $neutral;
            padding: 5px 0;
            line-height: 1.1;
          }

          &--favorite.is-favorite {
            visibility: visible;
            color: $accent-5;
          }

          &:hover {
            .wfnmockshell-level3-item-detail--button {
              color: $accent-0;
            }
          }
        }
      }
    }
  }

  // Expanded menu width responsiveness
  @media only screen and (max-width: 900px) {
    .wfnmockshell-top-item--wrapper.wfnmockshell-expanded {
      .wfnmockshell-sub-item--title {
        font-size: $icon-size-medium !important;
      }

      .wfnmockshell-level3-item-item--button {
        font-size: $font-size-small;
      }
    }
  }

  @media only screen and (min-width: 900px) {
    .wfnmockshell-top-item--wrapper.wfnmockshell-expanded {
      .wfnmockshell-sub-item--title {
        font-size: $font-size-medium;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .wfnmockshell-top-item--wrapper.wfnmockshell-expanded .wfnmockshell-sub,
    .wfnmockshell-top-item--wrapper.wfnmockshell-favorites .wfnmockshell-sub {
      width: 100vw;
    }
  }

  @media only screen and (min-width: 1200px) {
    .wfnmockshell-top-item--wrapper.wfnmockshell-expanded .wfnmockshell-sub,
    .wfnmockshell-top-item--wrapper.wfnmockshell-favorites .wfnmockshell-sub {
      width: 1200px;
    }
  }

  // searchbox styles from theme
  $wfnmockshell-height: 40px;

  .wfnmockshell-search {
    background-color: $white;
    border: 1px solid $neutral-light;
    color: $black;
    display: flex;
    flex: 0 1 250px;
    height: $wfnmockshell-height;
    justify-content: space-between;
    margin: 0;
    min-width: 0;
    padding: 0 5px;
    position: relative;
    white-space: nowrap;

    #searchBox {
      font-style: italic;
    }

    #searchClose {
      color: $neutral-dark;
      font-size: 24px;
      padding: 5px 0 0 0;
    }

    input {
      border: none;

      &::placeholder {
        color: $neutral-dark;
      }

      &:placeholder-shown {
        text-overflow: ellipsis;
      }
    }

    .vdl-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 900px) {
    .wfnmockshell-search {
      display: none;
    }
  }

  #searchBox {
    background-color: $white;
    border: 0;
    box-shadow: none;
    color: $neutral-darker;
    flex: 1 1 0;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    height: 100%;
    padding: 0;
    width: 100%;
  }

  #magnifyingGlass {
    color: $neutral-darker;
    font-size: 20px;
    width: 26px;
  }

  // popover content stying for toolbar, user menu, and searchbar
  &.wfnmockshell-popover {
    margin-left: 3px;

    &.mdf-popup-dialog,
    &.mdf-popup-dialog-left {
      .popup-dialog-content {
        .popup-dialog-header {
          display: flex;

          .popup-dialog-title {
            font-size: $font-size-medium;
          }
        }
      }
    }

    .wfnmockshell-popover-instructional-text {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: $font-size-base;
    }

    // hide inactive popovers
    &.popover-hidden {
      display: none !important;
    }

    .wfnmockshell-popover-nav {
      display: flex;
      justify-content: space-between;

      .nav-hidden {
        visibility: hidden;
      }
    }

    .wfnmockshell-popover-detail {
      &-intructions {
        font-weight: bold;
        margin-bottom: 15px;
      }

      &-item {
        &-nested {
          margin-left: 25px;

          &-level3 {
            margin-left: 50px;
          }
          &-level4 {
            margin-left: 25px;
          }
        }
      }
      &-item-nested-level4 {
        display: flex;
        align-items: center;
        padding: 5px 0;

        .vdl-checkbox {
          display: flex;

          label {
            margin-top: 0;
          }
        }

        .label-wrapper {
          align-self: flex-end;
          font-size: $font-size-base;
        }
      }
      &-item,
      &-item-nested {
        display: flex;
        align-items: center;
        padding: 5px 0;

        .vdl-checkbox {
          display: flex;

          label {
            margin-top: 0;
          }
        }

        .label-wrapper {
          align-self: flex-end;
          font-size: $font-size-base;
        }
      }
      &-item-nested-level3 {
        .level3-item {
          display: flex;
          align-items: center;
          padding: 5px 0;

          .vdl-checkbox {
            display: flex;

            label {
              margin-top: 0;
            }
          }

          .label-wrapper {
            align-self: flex-end;
            font-size: $font-size-base;
          }
        }
      }
    }
  }

  // iconbar
  .wfn-mock-shell-icon-bar {
    height: 60px;

    @media #{$mobile} {
      display: none;

      .wfn-mob-peek &,
      .wfn-mob-show & {
        display: block;
      }
    }

    &--icons {
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }

    &--icon {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 40px;
      align-items: center;
      justify-content: center;
    }

    i {
      cursor: pointer;
      font-size: $icon-size-large;
      color: $neutral-dark;

      &:hover {
        color: $accent-0-dark;
      }
    }

    &--icon-text {
      font-size: $icon-size-small;
      color: $neutral-dark;
      margin-top: 3px;
      text-transform: var(--text-transform);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &-item {
      &-outer {
        display: flex;
      }

      &-inner {
        display: flex;
        justify-self: center;
        align-items: center;
        padding-right: 25px;

        .vdl-checkbox {
          width: 20px;
          margin-right: 10px;
        }
      }

      &-logout {
        display: flex;
        justify-self: center;
        align-items: center;
        margin-right: 20px;
      }
    }

    .wfn-mock-shell-icon-bar--user {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;

      .vdl-checkbox {
        display: flex;
      }

      &--avatar {
        margin-right: 12px;

        &:hover,
        &:active {
          color: $white;
        }

        &--disabled {
          margin-right: 12px;
        }
      }

      &--name {
        color: $link-color;
        white-space: nowrap;
        max-width: 200px;
        text-overflow: ellipsis;
        text-transform: var(--text-transform);
        font-size: $icon-size-small;
        overflow: hidden;

        span {
          font-size: $icon-size-small;
          font-weight: $font-weight-base;
          color: $accent-0;
          padding-left: 10px;
        }
      }

      &--disabled {
        cursor: unset;
      }
    }
  }

  // footer
  .wfnmockshell-footer {
    background-color: $white;
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    border-top: 1px solid $neutral;
    margin: 0;
    position: relative;

    @media #{$mobile} {
      display: none;
    }

    display: flex;
    justify-content: space-between;

    a {
      color: $link-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .wfn-footer-nav {
      display: flex;
      color: $link-color;

      .wfn-footer-item {
        display: flex;
        align-items: center;
        margin: 0 10px;

        &:first-of-type {
          margin-left: 0;
        }

        .vdl-checkbox {
          display: flex;
        }
      }
    }

    &-copyright {
      display: inline-block;
      color: $neutral-dark;
    }

    .oo_icon_wfn_gif {
      vertical-align: middle;
      display: inline-block;
      position: static;
    }

    .revitNavBar {
      padding: 0;

      .revitNavItem {
        padding: 0;
        margin: 0;
        position: static;

        .dijitButtonText {
          text-transform: capitalize;
          font-size: $font-size-small;
          font-weight: $font-weight-light;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.mockShell-unified-shell {
  // main section
  main {
    min-height: 500px;
    background-color: $white;
  }

  @media only screen and (min-width: 1px), print {
    header {
      button {
        font-family: $font-family-base;
      }

      .wfn-masthead {
        .wfn-logo {
          margin-top: 10px;
          font-size: 20px;
          color: $white;
        }
      }

      .wfnmockshell-toolbar {
        background: $accent-0-darkest;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        position: relative;
      }

      .wfnmockshell-mega-menu {
        background: $white;
      }

      .wfnmockshell-top {
        &-item {
          &--button {
            padding: 0;
            line-height: 40px;
            text-transform: var(--text-transform);
            letter-spacing: 0.05em;
            color: $black;
            width: 100%;
            font-size: $font-size-small;
            font-weight: $font-weight-base;

            &:hover {
              cursor: pointer;
            }

            &.has-favorites {
              color: $accent-5;
            }
          }

          &--wrapper {
            &-dart {
              position: relative;
              border-top: 0px;
              border-bottom: 5px solid $accent-0-dark;
              border-right: 0px;
              border-left: 0px;
              content: "";
              filter: drop-shadow(0 7px 4px rgba(0, 0, 0, 0.2));
            }

            &.wfnmockshell-expanded {
              .wfnmockshell-sub {
                &-title-wrapper {
                  height: 50px;
                  margin-top: -20px;
                }

                &-item {
                  margin-bottom: 0px;

                  &-title-content {
                    border-bottom: none;
                    margin-bottom: 5px;
                  }
                }

                .wfnmockshell-features-selected-detail {
                  display: flex;

                  .wfnmockshell-button-edit-options {
                    text-transform: none;
                    font-size: $font-size-small;
                    margin: -4px 0 0 -4px;
                    color: $link-color;
                  }

                  .wfnmockshell-features-selected {
                    font-size: $font-size-small;
                    color: $neutral-darker;
                  }
                }
              }
            }
          }

          .wfnmockshell-top-label-wrapper {
            margin: 0 0.25rem 0 0.25rem;

            &:hover {
              cursor: pointer;
              background: var(--neutral-light);
              border-radius: 0.25rem;
            }
          }

          // adjustments for long mega menu text with checkbox
          &.large-top-item {
            .wfnmockshell-top-item--wrapper-dart {
              border-right: 0px;
              border-left: 0px;
            }
          }

          &.x-large-top-item {
            .wfnmockshell-top-item--wrapper-dart {
              border-right: 0px;
              border-left: 0px;
            }
          }

          &.active-top {
            .wfnmockshell-top-item--button,
            .wfnmockshell-top-label-wrapper {
              background-color: $neutral-light;
            }

            .wfnmockshell-top-label-wrapper {
              filter: none;
            }
          }
        }
      }

      .wfnmockshell-sub {
        &-title {
          &-wrapper {
            border-bottom: none;
          }
        }

        &-container {
          margin-top: -20px;
        }

        &-items {
          position: relative;
          padding: 20px 0 30px 25px;
          min-height: 160px;
          min-width: 300px;
        }

        &-item {
          &.active-sub {
            .wfnmockshell-sub-item--button {
              background-color: $accent-0-lighter;
            }

            .wfnmockshell-sub-item--button-dart {
              display: inline-block;
            }
          }
        }

        &.mdf-mock-shell-wfnmockshell-slide-down {
          max-height: 1450px;
        }
      }

      .wfnmockshell-sub-container {
        display: flex;
      }

      .wfnmockshell-level3-item {
        &-detail {
          display: flex;
          align-items: flex-start;
          padding: 0px 0px 5px 10px;

          &--button {
            display: block;
            max-width: 100%;
            color: $black;
            border-radius: 0;
            border: none;
            text-transform: none;
            white-space: nowrap;
            font-size: $font-size-base;
            font-weight: $font-weight-light;
            padding: 0;
            text-transform: none;
            justify-self: flex-start;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 0;
          }

          &:hover {
            border: none;
            box-shadow: none;
            background-color: $neutral-light;
            border-radius: 0;
            text-transform: none;

            .wfnmockshell-level3-item-detail--button {
              color: $black;
              background-color: $neutral-light;
              text-transform: none;
              border: none;
              box-shadow: none;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
  // popover content stying for toolbar, user menu, and searchbar
  &.wfnmockshell-popover {
    margin-left: 3px;

    &.mdf-popup-dialog,
    &.mdf-popup-dialog-left {
      .popup-dialog-content {
        .popup-dialog-header {
          display: flex;

          .popup-dialog-title {
            font-size: $font-size-medium;
          }
        }
      }
    }

    .wfnmockshell-popover-instructional-text {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: $font-size-base;
    }

    // hide inactive popovers
    &.popover-hidden {
      display: none !important;
    }

    .wfnmockshell-popover-nav {
      display: flex;
      justify-content: space-between;

      .nav-hidden {
        visibility: hidden;
      }
    }

    .wfnmockshell-popover-detail {
      display: block;

      &-intructions {
        font-weight: bold;
        margin-bottom: 15px;
      }

      &-item {
        &-nested {
          margin-left: 25px;
        }
      }
    }
  }
  // iconbar
  .wfn-mock-shell-icon-bar {
    height: 60px;

    @media #{$mobile} {
      display: none;

      .wfn-mob-peek &,
      .wfn-mob-show & {
        display: block;
      }
    }

    &--icons {
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }

    &--icon {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 40px;
      align-items: center;
      justify-content: center;
    }

    i {
      cursor: pointer;
      font-size: $icon-size-large;
      color: $white;

      &:hover {
        color: $accent-0-lighter;
      }
    }

    &--icon-text {
      font-size: $font-size-xsmall;
      color: $white;
      margin-top: 3px;
      text-transform: var(--text-transform);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &-item {
      &-outer {
        display: flex;
      }

      &-inner {
        display: flex;
        justify-self: center;
        align-items: center;
        padding-right: 25px;

        .vdl-checkbox {
          width: 20px;
          margin-right: 10px;
        }
      }

      &-logout {
        display: flex;
        justify-self: center;
        align-items: center;
        margin-right: 20px;
      }
    }

    .wfn-mock-shell-icon-bar--user {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;

      .vdl-checkbox {
        display: flex;
      }

      &--avatar {
        margin-right: 12px;
        color: $white;

        &:hover,
        &:active {
          color: $accent-0-lighter;
        }

        &--disabled {
          margin-right: 12px;
        }
      }

      &--name {
        color: $white;
        white-space: nowrap;
        max-width: 200px;
        text-overflow: ellipsis;
        text-transform: var(--text-transform);
        font-size: $icon-size-small;
        overflow: hidden;

        span {
          font-size: $icon-size-small;
          font-weight: $font-weight-base;
          color: $accent-0;
          padding-left: 10px;
        }
      }

      &--disabled {
        cursor: unset;
      }
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.locale-picker {
  min-height: 110px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .locale-picker__card-outer {
    display: inline-block;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    overflow: hidden;

    &:not([disabled]) {
      &:focus {
        & .selected {
          box-shadow: $white 0 0 0 2px inset;
        }
      }
    }

    .locale-picker__card-inner {
      height: 75px;
      width: 150px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      background-color: $white;
      border-radius: 8px;

      &:hover {
        &:not([disabled]) {
          box-shadow: $white 0 0 0 2px inset, $accent-0-dark 0 0 0 4px inset;
        }
      }

      .locale-picker__card-label {
        padding-top: 10px;
        padding-left: 10px;
        font-weight: 600;
        cursor: pointer;
        display: flex;

        .locale-picker__card-label-text {
          color: $link-color;
          margin-right: 10px;
        }
      }

      .locale-picker__card-description {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        font-size: 12px;
        color: $black;
        cursor: pointer;

        .locale-picker__card-description-icon {
          padding-right: 2px;
          color: $accent-1-dark;
        }

        .locale-picker__card-description-text {
          color: $black;
        }
      }

      &[disabled] {
        cursor: not-allowed;
        pointer-events: auto;
        opacity: 1;

        * {
          pointer-events: none;
        }

        .locale-picker__card-label .locale-picker__card-label-text {
          color: var(--button-disabled);
        }

        .locale-picker__card-description .locale-picker__card-description-text {
          color: var(--button-disabled);
        }

        .locale-picker__card-description .locale-picker__card-description-icon {
          color: var(--button-disabled);
        }
      }

      &.selected {
        background-color: $accent-0-dark;
        border: 2px solid $accent-0-dark;

        .locale-picker__card-label .locale-picker__card-label-text {
          color: $white;
        }

        .locale-picker__card-description .locale-picker__card-description-text {
          color: $white;
        }

        &[disabled] {
          color: var(--primary-disabled-text);
          background-color: var(--primary-disabled-background);
          border-color: var(--primary-disabled-background);

          .locale-picker__card-label .locale-picker__card-label-text {
            color: var(--primary-disabled-text);
          }

          .locale-picker__card-description .locale-picker__card-description-text {
            color: var(--primary-disabled-text);
          }

          .locale-picker__card-description .locale-picker__card-description-icon {
            color: var(--primary-disabled-text);
          }
        }
      }
    }
  }
}

.locale-picker__item {
  display: flex;
  align-items: center;

  .react-phone-number-input__icon {
    margin-right: 5px;
    margin-top: -2px;
    line-height: 1;
  }
}

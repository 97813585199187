@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-wizard-component {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 10px;

  &--container {
    display: flex;

    &--content {
      flex-direction: column;
      margin: 0;
      flex-grow: 1;
      overflow: auto;
    }
  }

  // padding top to accommodate collapse control/button
  &--navigation {
    background-color: $white;
    flex: 0 0 280px;
    padding: 30px 0;
    width: 280px;
  }

  &--parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    &:focus,
    &:hover {
      &:not(.disabled-status) {
        box-shadow: inset 2px 2px $input-border-focus, inset -2px -2px $input-border-focus;
        background-color: $outline-bg-color;
        color: var(--link-color);
      }
    }

    .mdf-wizard-title {
      max-width: 170px;
    }

    i {
      align-self: flex-end;
      padding: 10px;
      color: $accent-0-dark;
    }

    &.disabled-status {
      color: $neutral-mid;
      cursor: not-allowed;

      .mdf-wizard-status {
        border: 2px solid $neutral-mid;
      }

      >i {
        color: $neutral-mid;
        cursor: auto;
      }
    }
  }

  &--step {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px 15px;


    &:focus,
    &:hover {
      &:not(.disabled-status) {
        box-shadow: inset 2px 2px $input-border-focus, inset -2px -2px $input-border-focus;
        background-color: $outline-bg-color;
        color: var(--link-color);
      }
    }

    .mdf-wizard-status {
      margin: 0 20px 0 0;
    }

    .current-status {
      border: 2px solid $accent-0-dark;
    }

    &.disabled-status {
      color: $neutral-dark;
      cursor: not-allowed;

      .mdf-wizard-status {
        border: 2px solid $neutral-mid;
      }
    }

    &--child {
      padding-top: 18px;
      padding-bottom: 18px;
      padding-left: 12px;
      border-left: 2px dashed $neutral-light;
      margin-left: 24px;
      margin-top: -12px;
      margin-bottom: -12px;

      .mdf-wizard-title {
        max-width: 150px;
      }
    }

    &--line {
      border-left: 2px solid $neutral-light;
      height: 30px;
      margin-left: 24px;
      margin-top: -12px;
      margin-bottom: -12px;
    }

    .mdf-wizard-title {
      margin: 4px 0;
      width: 210px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-transform: var(--text-transform);
    }

    &--expand {
      display: block;
    }

    &--collapse {
      display: none;
    }
  }

  .active {
    font-weight: $font-weight-semibold;
    background-color: $accent-0-lighter;
  }

  &--title {
    display: flex;
    margin: 0 10px;
    border-bottom: 1px solid $neutral-mid;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
  }

  .mdf-wizard-component-save {
    .vdl-popup__arrow {
      left: 20px;
    }

    .vdl-popup {
      width: 120px
    }
  }

  .mdf-snackbar {
    // For snackbar to align well in unified shell
    left: auto;
    right: 0;
    margin: 0 auto;
  }
}

// Fixed width and left set to 0 for shell environment
#wfn_content .mdf-wizard-footer-container {
  .mdf-snackbar {
    width: 1200px;
    left: 0;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-dropdown-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;

  &.vdl-validation-error {
    .mdf-dropdown-menu {
      border: var(--input-border-invalid);
      color: $color-status-error;

      >input::placeholder {
        color: $color-status-error;
      }

      >.mdf-angle-down {
        color: $color-status-error;
      }
    }
  }

  .mdf-dropdown-menu {
    display: flex;
    width: 100%;
    height: var(--form-input-height);
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: var(--white);

    &.mdf-dropdown-menu-placeholder {
      color: $neutral-dark;
    }

    &.mdf-dropdown-menu-error {
      border: var(--input-border-invalid);
      color: $color-status-error;

      >input::placeholder {
        color: $color-status-error;
      }

      >.mdf-angle-down {
        color: $color-status-error;
      }
    }

    .mdf-dropdown-menu-value {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      padding-right: 5px;
    }

    input {
      border: none;
    }

    input::placeholder {
      font-style: italic;
      color: $neutral-dark;
    }

    .fa-search {
      margin-right: 5px;
    }

    .mdf-close-icon {
      color: $black;
      font-size: 9px;
      cursor: pointer;

      &.hide-icon {
        visibility: hidden;
      }
    }

    .mdf-close-icon-waypoint {
      color: $black;
      font-size: $font-size-small;
      cursor: pointer;

      &.hide-icon {
        visibility: hidden;
      }
    }

    .mdf-angle-down {
      color: $neutral-darkest;
      padding-left: 5px;
      font-size: $font-size-small;
      cursor: pointer;
    }

    .mdf-angle-down-waypoint {
      color: $neutral-darkest;
      padding-left: 4px;
      cursor: pointer;
    }

    &:hover {
      border-color: var(--dropdown-list-hover);
    }
  }

  .mdf-dropdown-menu.mdf-dropdown-menu-focused:not(.mdf-dropdown-menu-disabled) {
    border: 2px solid $input-border-focus;
    outline: 0;
    box-shadow: var(--dropdown-list-focus-box-shadow);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .mdf-dropdown-menu-disabled {
    border-color: var(--dropdown-list-disabled-border);
    background-color: var(--dropdown-list-disabled-background);
    cursor: not-allowed;

    .mdf-dropdown-menu-value {
      color: var(--dropdown-list-disabled-color);
      background-color: var(--dropdown-list-disabled-background);
      cursor: not-allowed;
    }

    .toggle-style {
      color: var(--input-color-disabled);
      cursor: not-allowed;
    }
  }
}

.mdf-dropdown-menu-items-container {
  .input-container {
    display: flex;

    .mdf-dropdown-close {
      flex: 1;
    }
  }

  .mdf-dropdown-menu-search-input {
    display: flex;
    height: 35px;
    border: 2px solid $input-border-focus;
    border-top: 0;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: $white;

    >input {
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      padding-right: 5px;
    }

    >.fa-search {
      margin-right: 5px;
    }

    >.mdf-angle-down {
      color: $neutral-darkest;
      padding-left: 5px;
      font-size: $font-size-small;
      cursor: pointer;
    }
  }

  .mdf-dropdown-menu-content {
    background-color: var(--white);
    border: 1px solid var(--neutral-mid);

    .mdf-dropdown-menu-items {
      max-height: 174px;
      overflow-y: scroll;

      .mdf-dropdown-menu-active-item:not(.mdf-dropdown-menu-selected) {
        background-color: $accent-0-lighter;
        border: 2px solid $accent-0-dark;
      }

      .mdf-dropdown-menu-no-items-found {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 29px;
        margin: auto;
      }

      .mdf-dropdown-menu-searching {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        height: 29px;
        font-style: italic;

        div {
          flex-grow: 1;
        }

        .fa-spinner {
          color: $link-color;
        }
      }

      .mdf-dropdown-menu-single-item {
        user-select: none;
        padding: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 29px;
        border: 2px solid transparent;

        &:hover {
          background-color: var(--list-option-hover-background);
        }

        .mdf-dropdown-menu-active-item {
          border-color: var(--list-option-focus-border);
        }

        .mdf-dropdown-menu-selected {
          border-color: var(--list-option-focus-border)
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        user-select: none;
      }

      .mdf-dropdown-menu-grouped {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        >.mdf-dropdown-menu-grouped-title {
          background-color: $neutral-light;
          font-weight: $font-weight-semibold;
          width: 100%;
          padding: 5px;
          font-size: $font-size-h6;
        }

        >.mdf-dropdown-menu-grouped-item {
          width: 100%;
          padding: 5px;
          font-size: $font-size-base;

          .mdf-dropdown-menu-grouped-item-value {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .mdf-dropdown-menu-grouped-item-subvalue {
            color: $neutral;
            font-size: $font-size-xsmall;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        >.mdf-dropdown-menu-grouped-item:hover:not(.mdf-dropdown-menu-selected) {
          background-color: $accent-0-lighter;
        }
      }

      .mdf-dropdown-menu-complex-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px;

        .mdf-dropdown-menu-complex-item-value {
          font-size: $font-size-base;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .mdf-dropdown-menu-complex-item-id {
          color: $neutral-darker;
          font-size: $font-size-xsmall;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .mdf-dropdown-menu-complex-item:hover:not(.mdf-dropdown-menu-selected) {
        background-color: $accent-0-lighter;
      }
    }

    .mdf-dropdown-menu-footer {
      text-align: center;
      border-top: 1px solid #cfcfcf;

      .link-button {
        font-size: $font-size-base;
      }
    }
  }
}

.vdl-overlay-container.mdf-dropdown-menu-popup {
  background-color: transparent;
  border: 0px;
  box-shadow: none;

  &.vdl-popup--dropup.vdl-popup__content {
    box-shadow: none;
  }

  &[data-popper-placement^='top'] {
    & .mdf-dropdown-menu-items-container {
      display: flex;
      flex-direction: column-reverse;

      & .mdf-dropdown-menu-search-input {
        border-top: 0;
        border: 2px solid transparent;
      }

      & .mdf-dropdown-menu-content {
        box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
      }
    }
  }

  &[data-popper-placement^='bottom'] {
    & .mdf-dropdown-menu-items-container {
      display: flex;
      flex-direction: column;

      & .mdf-dropdown-menu-search-input {
        border-bottom: 0;
      }

      & .mdf-dropdown-menu-content {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      }
    }
  }
}

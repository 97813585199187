@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.help-icon {
  margin-left: 10px;

  i {
    color: $link-color;
    cursor: pointer;
  }
}

.mdf-help-icon {
  .vdl-modal-dialog.md {
    .vdl-modal-content {
      width: 560px;
      height: 460px;
      background: $white;
    }
  }
}
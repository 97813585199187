@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf {
  .mdf-action-menu__button {
    text-transform: none;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;

    .fa-chevron-down {
      margin-left: 5px;
    }
  }

  .mdf-action-menu.vdl-action-menu {
    &__button.vdl-button {
      width: auto;
      height: auto;
      padding: 5px;

      &:active, &:focus, &:hover:not([disabled]), &.vdl-action-menu__button--open {
        border-radius: unset;
        box-shadow: inset 2px 2px $input-border-focus, inset -2px -2px $input-border-focus;
      }
    }
  }
}
.mdf .vdl-textbox,
.vdl-textbox {
  padding: 6px 10px;
}

.mdf .mdf-label {
  font-size: 0.875rem;
}

.mdf .mdf-label > label {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf {
  .vdl-pagination {
    user-select: none;

    .pagination__total-items-found-labels {
      border-right: 1.5px solid $neutral-mid;
    }

    .vdl-dropdown-list__input-container {
      width: 100px;
      margin: 0;
    }

    .pagination-input {
      width: 75px;
      padding: 0 10px;
      display: inline-block;
      font-weight: $font-weight-base;
      font-size: $font-size-base;
      box-shadow: none;
      border-radius: 0;
      outline: 0 none;
      appearance: none;
      background-color: $white;
      border: 1px solid $neutral-mid;
      height: 36px;
      box-sizing: border-box;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    i {
      border:2px solid transparent;

      &:focus {
        border:2px solid $neutral-darker;
      }

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          border:2px solid $neutral-darker;
        }
      }
    }
  }
}
@use '@synerg/vdl-css-framework/scss/config' as *;
@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

@import '@synerg/vdl-css-framework/scss/components/snackbar';

.mdf-snackbar {
  @extend .#{$vdl-prefix}snackbar__container;
  display: block;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: auto;
  right: 0;
  background: $white;
  height: 56px;
  box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.07);
  border-top: 1px solid $neutral-lighter;
  padding: 10px 15px;
  text-align: center;
  z-index: 2;
  transform: translate3d(0, 0, 0); // Smooths scrolling when there is a fixed snackbar in IOS.
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.wfnx {
  .wfnx-small-card-overlay {
    width: 260px;
    border-radius: 15px;
    display: none;

    .close-icon {
      position: absolute;
      top: -15px;
      right: -20px;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      color: $white;
      background-color: $black;
      text-align: center;
      font-size: $font-size-large;
      padding-top: 10px;
      cursor: pointer;
    }
  }

  .overlay-enter {
    position: absolute;
    top: -25px;
    bottom: 0;
    display: block;
  }

  .overlay-enter-active {
    top: -50px;
    bottom: 0;
  }

  .overlay-enter-done {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    background-color: red;
  }
}

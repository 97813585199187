@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

@use 'sass:math';

$step-indicator-diameter: 35px;

.mdf .step-navigation {
  .step-navigation__step {
    flex: 0 0 45px;

    .step-navigation__step-indicator {
      background: $white;
      line-height: 30px;
      width: $step-indicator-diameter;
      height: $step-indicator-diameter;
      border-radius: $step-indicator-diameter;
      border-width: 3px;

      .step-icon {
        line-height: 28px;
      }
    }

    &.active.backwards:not(.complete) .step-navigation__step-indicator {
      transition: color 400ms ease-in-out 400ms, border-color 400ms ease-in-out 400ms;
    }

    &.active.forwards:not(.complete) .step-navigation__step-indicator {
      transition: color 400ms ease-in-out, border-color 400ms ease-in-out;
    }

    .step-navigation__step-line {
      top: $step-indicator-diameter;
      margin-left: floor(math.div($step-indicator-diameter, 2));
    }

    .step-navigation__step-line.active {
      border-left-width: 3px;
      transform-origin: top;
      transform: scaleY(0);
    }

    &.complete.forwards .step-navigation__step-line.active {
      transition: transform 800ms ease-in-out;
      transform: scaleY(1);
    }

    &.active.backwards .step-navigation__step-line.active {
      transition: transform 800ms ease-in-out 400ms;
      transform: scaleY(0);
    }
  }
}

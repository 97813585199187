@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.wfnx {
  .wfnx-filter {
    display: flex;
    position: relative;

    .filter-content {
      width: 230px;
      min-height: 100px;
      color: $white;
      background-color: $neutral-dark;
      padding-bottom: 20px;
      box-shadow: inset 1px 1px 23px 8px rgba(0, 0, 0, 0.2);
      overflow-x: hidden;
    }

    .collapse-control-open {
      position: absolute;
      left: 230px;
      width: 40px;
      height: 40px;
      background-color: $accent-5;
      border-bottom-right-radius: 15px;
      text-align: center;
      padding-top: 10px;
      cursor: pointer;

      i {
        color: $white;
        font-size: $font-size-large;
      }
    }

    .collapse-control-closed {
      position: absolute;
      left: 0;
      width: 102px;
      height: 40px;
      background-color: $accent-5;
      border-bottom-right-radius: 15px;
      text-align: center;
      padding-top: 10px;
      cursor: pointer;
      text-transform: var(--text-transform);

      i {
        color: $white;
        font-size: $font-size-large;
        padding-right: 10px;
      }

      span {
        color: $white;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
      }
    }

    .applied-filters-container {
      display: flex;
      position: absolute;
      top: 0;
      left: 290px;
      width: auto;
      height: 40px;

      .applied-filter {
        height: 28px;
        color: $black;
        background-color: $neutral-light;
        border-radius: 50px;
        font-size: $font-size-base;
        font-weight: $font-weight-base;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 3px;
        margin-right: 5px;
        white-space: nowrap;

        i {
          position: relative;
          color: $neutral-darker;
          font-size: $font-size-large;
          padding-left: 5px;
          top: 1px;
          cursor: pointer;
        }
      }

      .remaining-filters {
        color: $accent-0-dark;
        font-size: $font-size-h6;
        font-weight: $font-weight-bold;
        text-transform: var(--text-transform);
        padding-top: 6px;
        padding-left: 5px;
        white-space: nowrap;
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      .title-container {
        font-size: $font-size-h1;
        font-weight: $font-weight-light;
        color: $white;
        padding-left: 20px;
        padding-top: 30px;
        text-transform: capitalize;
      }

      .button-container {
        padding-top: 30px;
        text-align: right;
        padding-right: 20px;
        flex-grow: 1;
      }
    }

    .separator {
      height: 2px;
      border-bottom: 1px solid $neutral;
      margin: 18px 20px;
    }

    .category-container {
      cursor: pointer;
      font-size: $font-size-large;
      padding-right: 10px;

      .icon {
        width: 20px;
        display: inline-block;
        padding-left: 30px;
        padding-right: 30px;
      }

      .name {
        display: inline-block;
        font-weight: $font-weight-semibold;
        text-transform: var(--text-transform);
      }
    }

    ul {
      list-style: none;

      li {
        width: 170px;
        margin-bottom: 14px;
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        cursor: pointer;

        .list-item-content {
          display: flex;

          i {
            width: 22px;
            font-size: $font-size-xlarge;
            padding-right: 30px;
          }

          .text {
            width: 140px;
            padding-top: 2px;
          }
        }
      }
    }

    li:first-child {
      padding-top: 10px;
    }
  }

  .wfnx-filter {
    // Called when collapsing
    &.filter-collapsed {
      display:none;
    }

    &.filter-expand {
      display:block;
    }
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf .dropTargetStyle, .uploadButtonStyle, #cancelUpload {
  &:focus {
    border: 2px solid $input-border-focus;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-border-focus;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}

.progressBar {
  .vdl-progress-bar__label {
    margin-top: 5px;
  }
}

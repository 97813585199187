@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

.mdf-segmented-wizard-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .mdf-segmented-wizard-header-item {
    flex: 1;
    font-size: var(--font-size-small);
    line-height: 1;
    padding: 5px;
    border: 1px solid transparent;
    text-align: center;
    text-transform: var(--text-transform);

    .mdf-segmented-wizard-header-item-bar {
      background-color: $neutral;
      height: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

    &.mdf-segmented-wizard-header-item-bar-click {
      cursor: pointer;
    }

    &.mdf-segmented-wizard-header-item-bar-disabled {
      cursor: not-allowed;
    }

    .mdf-segmented-wizard-header-item-text {
      color: $neutral-dark;
    }

    &.mdf-segmented-wizard-header-visited-item {
      .mdf-segmented-wizard-header-item-bar {
        background-color: $accent-0-dark;
      }

      .mdf-segmented-wizard-header-item-text {
        color: var(--clickable-label-color);
      }
    }

    &-active, &:hover, &:active {
      border: 1px solid $input-border-focus;
      outline: none;
      box-shadow: inset 0 0 0 2px $input-border-focus, 0 0 8px $input-border-focus;
    }
  }

  .mdf-segmented-wizard-header-current-item {
    font-size: var(--font-size-medium);
    font-weight: $font-weight-semibold;
  }
}

@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf-video {
  height: 100%;
  width: 100%;

  iframe {
    min-height: 320px;
    min-width: 560px;
  }
}

.mdf .mdf-video-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 320px;
  border-radius: 5px;
  border: 1px solid $neutral-dark;
  background-color: $neutral;
}
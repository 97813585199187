@use '@synerg/vdl-css-framework/scss/variables/colors' as *;
@use '@synerg/vdl-css-framework/scss/variables/fonts' as *;

@use 'sass:math';

$calendar-flex-item-width: math.div(100, 7);

.calendar {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  min-width: 240px;

  &-grid {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;

    &-select {
      width: 70px;
      flex: 0 33%;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &-item {
        text-align: center;
        border: 1px solid $accent-0-darker;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
        justify-content: center;
        color: $accent-0-darker;
        font-size: $font-size-medium;
        flex: 1;
        display: flex;
        align-items: center;

        &__selected {
          background-color: $accent-0-dark;
          color: $white;
        }

        &__disabled {
          color: $neutral;
          border-color: $neutral;
          cursor: auto;
        }
      }
    }

    &-item {
      flex: 1 13%;
      display: flex;
      cursor: pointer;
      align-items: center;
      flex-direction: column;
      font-size: $font-size-medium;
      font-weight: $font-weight-semibold;
      justify-content: center;
      margin: 1px;
      height: 32px;
      width: 32px;
      border-radius: 50%;

      .vdl-button--link {
        font-size: $font-size-medium;
        font-weight: $font-weight-semibold;
        color: var(--secondary-text-color);
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .vdl-button[disabled] {
        .vdl-button__container {
          color: $neutral-dark;
        }
      }

      &:hover {
        &:not([disabled]) {
          background-color: $accent-0-lightest;
          color: $input-border-focus;
        }
      }

      &-day {
        padding: 5px;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      &__today {
        background-color: $accent-0-darker;
        color: $white;
        box-shadow: var(--input-focus);

        &:hover {
          &:not([disabled]) {
            background-color: $input-border-focus;
            color: $white;
          }
        }
      }

      &__selected {
        background-color: $accent-0-darker;
        color: $white;
        box-shadow: var(--input-focus);

        &:hover {
          &:not([disabled]) {
            background-color: $input-border-focus;
            color: $white;
          }
        }
      }

      &__disabled {
        color: $neutral-dark;
        cursor: auto;
      }
    }

    &-item-range {
      flex: 1 13%;
      display: flex;
      cursor: pointer;
      align-items: center;
      flex-direction: column;
      font-size: $font-size-medium;
      font-weight: $font-weight-semibold;
      justify-content: center;
      height: 32px;
      width: 32px;

      .vdl-button--link {
        font-size: $font-size-medium;
        font-weight: $font-weight-semibold;
        color: var(--secondary-text-color);
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .vdl-button[disabled] {
        .vdl-button__container {
          color: $neutral-dark;
        }
      }

      &:hover {
        &:not([disabled]) {
          background-color: $accent-0-lightest;
          color: $input-border-focus;
        }
      }
    }
  }

  &-header {
    color: $primary-dark;
    display: flex;
    font-size: $font-size-large;
    font-weight: $font-weight-base;
    cursor: pointer;
    align-items: center;

    &-navbutton {
      .vdl-button--link {
        cursor: pointer;
        margin: 0;
        font-size: $icon-size-large;
        display: flex;
        align-content: center;
      }
    }

    &-currentDate {
      flex: 1;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.5rem 0.25rem 0.5rem 0.25rem;

      &-selector {
        &>svg {
          height: 1.25em;
        }
      }

      a {
        color: $primary-dark;
        text-transform: var(--button-text-transform);
      }
    }
  }

  &-weekdays {
    display: flex;
    margin: 10px 0;
    align-items: center;

    &-item {
      flex: 0 14.28571%;
      font-size: $font-size-small;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: var(--text-transform);
      font-weight: $font-weight-semibold;

      &__disabled {
        color: $neutral-light;
        border-color: $neutral;
        cursor: auto;
      }
    }
  }
}

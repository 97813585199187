@import '_wfnXTitleBar.scss';

.wfnx {
  .wfnx-icon-titleBar-wrapper {
    position: relative;

    .titleBar-circle {
      position: absolute;
      border: 2px solid $titleBarColor;
      z-index: 1;
      top: 0;

      // align the circle to the titlebar based on size
      &--xs {
        left: -15px;
      }

      &--sm {
        left: -25px;
      }

      &--md {
        left: -30px;
      }

      &--lg {
        left: -30px;
        top: -1px;
      }

      .titleBar-icon-color {
        color: $titleBarColor;
      }
    }
  }
}
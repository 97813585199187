@use '@synerg/vdl-css-framework/scss/variables/colors' as *;

.mdf .treeItem {
  .vdl-left-nav__item__expand-icon {
    color: $accent-0-dark;
    fill: $accent-0-dark;
    visibility: visible;
    cursor: pointer;

    &.vdl-left-nav__item__selected {
      color: $white;
      fill: $white;
    }
  }

  &.vdl-left-nav__item--selected {
    > label {
      &:hover {
        background-color: $accent-0-dark;
      }
    }
  }

  .vdl-left-nav__item__label:focus-visible {
    .vdl-left-nav__item__expand-icon {
      color: $white;
      fill: $white;
    }
  }

  .vdl-left-nav__item__items--expanded {
    border-left: 1px dotted;
    margin-left: 10px;
  }

  &--labelIcon {
    padding: 2px;
  }

  .vdl-left-nav--tree .vdl-left-nav__item__label {
    line-height: 1;
    margin: 2px;
  }

  .vdl-left-nav__item__label {
    cursor: pointer;
    line-height: 14px;

    &:hover {
      background-color: $accent-0-lightest;
    }
  }

  &.vdl-left-nav__item__selected {
    .vdl-left-nav__item__label {
      &:hover {
        background-color: $accent-0-dark;
      }
    }
  }

  .vdl-left-nav__item__horizontalLine {
    float: left;
    width: 20px;
    border-top: 1px dotted;
    margin-top: 10px;
  }

  .vdl-left-nav__item__items--disabled {
    background-color: $neutral-disabled;
    color: $neutral-dark;
    cursor: not-allowed;

    &[aria-selected=true] {
      background-color: $neutral-dark;
      color: $neutral-disabled;
    }
  }

  .disabledClass {
    .word {
      &:hover {
        background-color: $neutral-disabled;
      }
    }
  }

  .vdl-left-nav__item__label span {
    margin-right: 18px;
  }
}
